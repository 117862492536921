import styled from 'styled-components/macro';
import { FaToggleOn } from 'react-icons/fa';

export const Wrapper = styled.div`
    font-size: 15px;
    width: 60vw;
    background-color: #f1f3f8;
    padding: 20px;
`;

export const StatusAndCreationDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    margin-bottom: 10px;
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SubduedText = styled.span`
    color: #9da3b0;
`;

export const DataContainer = styled.div`
    background-color: #FFF;
    padding: 22px;
    border-radius: 5px;
    margin-bottom: 22px;
    box-shadow: rgb(25 32 56 / 4%) 0px 8px 24px 0px;
`;

export const EditLink = styled.button`
    font-size: 15px;
    color: #007bff;
    padding: 0;
    margin-left: 5px;

    &:hover {
        color: #0056b3;
        span {
            text-decoration: underline;
        }
    }
`;

export const Badge = styled.span`
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 8px 16px;
    border-radius: 50px;
`;

export const PushgroundPlusBadge = styled(Badge)`
    background: linear-gradient(89.35deg, #d6b459 0%, #ffefa7 100%);
    color: #323232;
`;

interface TargetingBadgeProps {
    isExcludeBadge: boolean;
}

export const TargetingBadge = styled(Badge)<TargetingBadgeProps>`
    color: ${({ isExcludeBadge }) => (isExcludeBadge ? '#e45656' : '#4a76ff')};
    background-color: ${({ isExcludeBadge }) => (isExcludeBadge ? '#fcebeb' : '#edf1ff')};
`;

export const ChartNoDataOrLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 213px;
`;

export const CustomColumnsAndPaginationContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: end;
    margin: 20px 0 15px;
`;

export const CurrentToggle = styled(FaToggleOn)`
    cursor: pointer;
`;

export const CreativityWrapper = styled.div`
    position: relative;
    top: 10px;
    width: 374px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;
