import styled, { css } from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Dialog, Switch } from '@mui/material';
import { SearchByNameOrIdInput } from '../../../campaigns/components/styles/Filters';

export const FormSectionCard = styled.div`
    padding: 1rem 2rem 1.5rem;
    background-color: #FFF;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgb(10 0 116 / 10%) 0px 10px 15px 0px;
    position: relative;

    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
        width: calc(100% - 385px);
    }

    a {
        text-decoration: none;
    }

    a:visited {
      color: #007bff;
    }

    a:hover {
        color: #0056b3;
        text-decoration: underline;
    }

    /* &&& #react-select-3-1-listbox {
        overflow: visible;
        max-height: 10px;
    } */
`;

export const FormSectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FormSectionTitle = styled.h2`
    font-size: 22.4px;
    font-weight: 600;
`;

export const SectionTitleTooltipBttn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    color: #0066ff;
    border-radius: 50%;
    && {
        box-shadow: #000 0px 0px 8px -5px;
    }
    transition: box-shadow 0.4s ease;

    &:hover {
        box-shadow: #000 0px 0px 8px -2px;
    }
`;

export const Divider = styled.div`
    border: 0px;
    border-top: 1px solid #cccecf;
    margin: 12px 0 23px;
`;

export const Text = styled.p`
    font-size: 15px;
`;

export const SmallText = styled.p`
    font-size: 12px;
    color: #6c757d;
`;

export const TakeATourBttn = styled.button`
    font-size: 0.9rem;
    font-weight: 600;
    min-width: 1.6rem;
    min-height: 1.6rem;
    color: #FFF;
    background-color: #3374f6;
    padding: 0px 1rem;
    border-radius: 2rem;
    margin-right: 10px;
    box-shadow: #000 0px 0px 8px -5px;
    transition: all 0.4s ease 0s;

    &:hover {
        box-shadow: rgb(0 0 0) 0px 0px 8px -2px;
    }
`;

export const Macro = styled.button<{isDisabled: boolean; isSelected: boolean;}>`
    margin: 0.25rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid rgb(222, 226, 230);
    color: rgb(52, 58, 64);
    background-color: #F0F0F0;

    ${({ isDisabled }) => isDisabled && css`
        color: #707070;
        background-color: #fafafa;
        cursor: not-allowed;
    `}

    ${({ isSelected }) => isSelected && css`
        border-color: #343a40;
    `}
`;

export const FrequencyRadioBttnsContainer = styled.div`
    padding: 5px 0;
`;

export const Frequency = styled.span`
    font-size: 15px;
    padding: 0 10px 0 2px;
    text-transform: capitalize;
`;

export const StyledLabel = styled.label`
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding-bottom: 3px;
    cursor: pointer;
`;

export const UserFreshnessBttn = styled.button`
    font-size: 15px;
    font-weight: 600;
    color: #3b88fd;
    margin: 25px 0.3rem 0 0;
`;

export const UserFreshnessOptionsContainer = styled.div`
    display: grid;
    gap: 0.6rem;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, auto);
    border: 1px solid #dee2e6;
    border-radius: 12px;
    padding: 20px;
    margin: 18px 0;
`;

export const ErrorMessage = styled.div<{show: boolean}>`
    font-size: 15px;
    height: ${({ show }) => (show ? 33 : 0)}px;
    color: #721c24;
    background-color: #f8d7da;
    padding: ${({ show }) => (show ? '5px 10px' : '0 10px')};
    border-radius: 8px;
    margin-bottom: ${({ show }) => (show ? 10 : 0)}px;
    transition: height .5s ease-in-out, padding .5s ease-in-out, margin .5s ease-in-out;
    overflow: hidden;
`;

export const ExtraInfo = styled.div`
    font-size: 0.85rem;
    line-height: 1.5;
    color: #212529;
    background-color: #f1f4f7;
    padding: 0.2rem 0.3rem;
    border-left: 5px solid #3374f6;
    margin: 0.7rem 0 0;
`;

export const ListTitle = styled.h2`
    margin: 10px 0 15px;
`;

export const ListSectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px;

    ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
        flex-wrap: nowrap;
        gap: 40px
    }
`;

export const TextareaContainer = styled.div`
    width: 100%;
`;

export const ListTextarea = styled.textarea`
    min-height: 80px;
    width: 100%;
    color: #495057;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    margin-top: 5px;
    overflow: auto;
    resize: vertical;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    line-height: inherit;

    &:focus {
        border-color: #80bdff !important;
    }
`;

export const AlternateBttn = styled.button<{selected: boolean; position?: string}>`
    font-size: 15px;
    border: 1px solid #000;
    border-radius: 8px;
    ${({ position }) => (position === 'left' ? css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    ` : css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `)};
    height: 2.4rem;
    padding: 0px .75rem;
    transition: opacity 0.4s ease 0s;
    opacity: ${({ selected }) => (selected ? 1 : 0.3)};
    `;

export const DistributionBttn = styled(AlternateBttn)`
padding: 0px 1rem;
    width: 130px;
    margin-top: 5px;
`;

interface StyledInputProps {
    isError?: boolean
    isDisabled?: boolean;
    isFullWidth?: boolean;
}

export const StyledInput = styled(SearchByNameOrIdInput)<StyledInputProps>`
    ${({ isError }) => isError && css`
        border-color: #F00;
    `};

    ${({ isDisabled }) => isDisabled && css`
        color: #495057;
        background-color: #e9ecef;
    `};

    ${({ isFullWidth }) => isFullWidth && css`
        width: 100%;
    `};
`;

export const MinBidBttn = styled.button<{active: boolean}>`
    font-size: 0.8em;
    font-weight: 500;
    color: white;
    background-color: ${({ active }) => (active ? '#17a2b8' : 'grey')} ;
    padding: 2px 4px;
    border-radius: 3px;
    margin: 5px 0 8px;
    cursor: ${({ active }) => !active && 'default'} ;
`;

export const BidBudgetElement = styled.div`
    margin-bottom: 20px;
`;

export const CPCRatesDialogTrigger = styled.span`
    color: #007bff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const AdFormatOptionWrapper = styled.div<{isSelected: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 270px;
    border: 1px solid #dee2e6;
    border-color: ${({ isSelected }) => isSelected && '#3b88fd'};
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: #f4f6f9;
    }

    .ad-format-option-container {
        padding: 20px 29px;

        .button-and-explanation {
            display: flex;
            flex-direction: ${({ isSelected }) => isSelected && 'column'};
            align-items: ${({ isSelected }) => !isSelected && 'center'};
            gap: ${({ isSelected }) => (isSelected ? 20 : 70)}px;

            p {
                padding-left: ${({ isSelected }) => (isSelected && 28)}px;
            }
        }
    }

    .pushground-plus-info-bttn {
        color: #007bff;
        text-decoration: underline;
        padding: 0;
    }

    label, input {
        font-size: 16.5px;
        font-weight: 500;
        min-width: fit-content;
        cursor: pointer;
    }

    input {
        margin-right: 15px;
    }

    img:not(.info-logo) {
        min-width: 176px;
        align-self: stretch;
        background-color: #f1f3f8;
        padding: 20px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const NewRuleButton = styled.button.attrs({
  type: 'button',
})<{color?: string; bgColor?: string;}>`
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 0 8px -5px #000;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    line-height: 1;
    padding: 5px 10px;
  
    color: ${(props) => props.color || '#fff'};
    background-color: ${(props) => props.bgColor || 'rgb(51, 116, 246)'};
    transition: .4s;
  
    &:hover {
      box-shadow: 0 0 10px -2px #000;
    }
  
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  `;

export const OverspendingInfoContainer = styled.div`
    font-size: 13.5px;
    line-height: 1.3;
    display: flex;
    align-items: flex-start;
    background-color: #dfefff;
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 15px;

    .info-logo {
        width: 13px;
        margin-right: 0.5rem;
    }
`;

export const PushgroundPlusBanner = styled.div<{ isDisabled: boolean; }>`
    display: flex;
    align-items: center;
    color: #FFF;
    background: linear-gradient(89.35deg, rgb(0, 0, 0) 0%, rgb(59, 59, 59) 100%);
    padding: 5px 0;
    margin: 15px 0 10px;
    border-radius: 5px;
    opacity: ${({ isDisabled }) => isDisabled && 0.38} ;

    .toggle-container {
        flex: 0.5;
    }

    .premium-traffic-text {
        font-weight: 600;
        flex: 1;
    }

    .plus-sign {
        font-size: 20px;
        color: #f5bd4e;
        line-height: 20px;
        vertical-align: text-bottom;
    }

    .guidelines-container {
        align-self: stretch;
        display: flex;
        align-items: center;
        font-size: 13px;
        text-decoration: underline;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 12px 1.5rem;
        border: 1px solid #000;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin: -5px 0;
    }

    div {
        padding: 0 1rem;
    }
`;

export const PushgroundPlusDialogBttn = styled.button<{isDisabled: boolean}>`
    align-self: end;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #3374F6;
    padding: 6px 20px;
    border-radius: 10px;
    margin: -7px 30px 15px 0;
    transition: .4s;

    ${({ isDisabled }) => isDisabled && css`
        opacity: 0.55;
        cursor: not-allowed;
    `}
`;

export const StyledPushgroundPlusDialog = styled(Dialog)`
    .dialog-title {
        color: #f5bd4e;
    }

    .dialog-subtitle {
        font-weight: 600;
        margin-bottom: 8px;
    }

    li:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export const PushgroundPlusToggle = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#b1b3b4',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ecbe62',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FFF',
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#FFF',
  },
}));

export const CampaignSummaryContainer = styled(FormSectionCard)`
    padding: 0;
    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
        position: fixed;
        top: 90px;
        right: 25px;
        bottom: 25px;
        width: 360px;
        padding: 0px;
        display: flex;
        flex-direction: column;
    }
`;

export const CampaignSummaryTitle = styled.div`
    font-size: 1.4rem;
    color: #343a40;
    font-weight: 600;
    padding: 1rem 2rem;
`;

export const CampaignNameSummary = styled(Text)`
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: -10px;
`;

export const CampaignSummaryData = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 1.5rem 2rem 0.5rem;
`;

export const PublishCampaignBttnContainer = styled.div`
    padding: 1rem 2rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px;
`;

export const PublishCampaignBttn = styled.button`
    font-weight: 600;
    color: #FFF;
    background-color: rgb(51, 116, 246);
    padding: 0.4rem 1.25rem;
    border-radius: 10px;
    margin-top: 10px;
    transition: all 0.4s ease 0s;
`;

export const SummarySubtitle = styled(Text)`
    font-weight: 600;
    margin-bottom: 10px;

    &:not(:first-child) {
        margin-top: 15px;
    }
`;

export const Table = styled.table`
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-bottom: 20px;

  tr {
    &:nth-child(even) {
      background-color: #e5e5e5;
    }
  }

  th, td {
    cursor: pointer;
    font-size: 12px;
    padding: 8px 5px;

    &:not(:first-of-type) {
      vertical-align: center;
      text-align: center;
      width: 4%;
    }
  }
`;

export const ActionsWrapper = styled.div`
  //text-align: center;
  margin-top: .9rem;
`;

export const Indexes = styled.ul`
display: flex;
list-style: none;
margin: 0 0 -1px;
padding: 0;
position: relative;
z-index: 1;
overflow-x: auto;
`;

export const Index = styled.li<{selected: boolean; error: boolean; runningStatus: string}>`
  font-size: .8em;
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-bottom: none;
  cursor: pointer;

  &:hover {
    border-color: #DEE2E6;
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    border-radius: 4px;
    padding: 0 5px;
    //font-size: .9em;
  }

  ${(props) => props.selected && css`
    background-color: #fff;
    border-color: #DEE2E6;
    cursor: default;
  `};
  ${(props) => props.error && css`
    color: red;
  `};
  ${(props) => props.runningStatus === 'running' && css`
    span {
      background-color: #1bb157;
      color: #fff;
    }
  `};
  ${(props) => props.runningStatus === 'rejected' && css`
    span {
      background-color: #DC3545;
      color: #fff;
    }
  `};
  ${(props) => props.runningStatus === 'moderation' && css`
    span {
      border: 1px solid #aaaaaa;
      box-sizing: border-box;
    }
  `};
  ${(props) => props.runningStatus === 'paused' && css`
    span {
      background-color: #ebebeb;
      border: 1px solid #aaaaaa;
      box-sizing: border-box;
      color: #888888;
    }
  `};
`;

export const Action = styled.button.attrs({
  type: 'button',
})`
    font-size: 15px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: .3rem;
  font-weight: 600;
  color: #3B88FD;
`;

export const StyledSelect = styled(Select)<{isBlueMultivalue?: boolean; isExclude?: boolean}>`
    flex: 1;

    .css-13cymwt-control, .css-t3ipsp-control, .css-16xfy0z-control {
        border-radius: 10px;
        cursor: text;
    }

    .css-1hb7zxy-IndicatorsContainer {
        cursor: pointer;
    }

    .css-1p3m7a8-multiValue {
        background-color: ${({ isBlueMultivalue, isExclude }) => (isBlueMultivalue ? (isExclude ? 'rgb(237, 48, 48)' : '#3b88fd') : '#556270')} ;
        transition: all 0.15s linear 0s;
        border-radius: 8px;
        color: #FFF;

        .css-wsp0cs-MultiValueGeneric {
            color: #FFF;
        }

        .css-12a83d4-MultiValueRemove {
            cursor: pointer;
        }
    }
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
    .css-13cymwt-control, .css-t3ipsp-control, .css-16xfy0z-control {
        border-radius: 10px;
        cursor: text;
    }

    .css-1p3m7a8-multiValue {
        background-color: rgb(85, 98, 112);
        transition: all 0.15s linear 0s;
        border-radius: 8px;
        color: #FFF;

        .css-wsp0cs-MultiValueGeneric {
            color: #FFF;
        }

        .css-12a83d4-MultiValueRemove {
            cursor: pointer;
        }
    }
`;

export const ActiveHoursContainer = styled.div`
    border: 1px solid #dee2e6;
    border-radius: 12px;
    padding: 20px;
    margin: 20px 0px;
`;

export const Suggestion = styled.div`
    padding: 1.5rem 1rem;
    background-color: rgb(255, 245, 213);
    display: inline-flex;
    align-items: stretch;
    margin: 1rem 0;
    font-size: 13.5px;

    .title {
        color: rgb(255, 139, 0);
    }
`;

export const Separator = styled.div`
    background-color: rgb(255, 255, 255);
    width: 3px;
    margin: -0.5rem 1rem;
`;

export const AdvancedTargetingBttn = styled.button`
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
    height: 2.4rem;
    padding: 0px 0.75rem;
    margin: 1.75rem 0 .75rem;
`;

export const SummaryInfo = styled.div`
    background: rgb(229, 240, 255);
    color: rgb(0, 102, 255);
    border-radius: 10px;
    margin-right: 0.3rem;
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    width: fit-content;

    &.capitalize {
        text-transform: capitalize;
    }

    &.choose-targeting {
        background: rgb(242, 242, 242);
        color: rgb(128, 128, 128);
    }

    &.pushground-plus {
        background: linear-gradient(89.35deg, rgb(0, 0, 0) 0%, rgb(59, 59, 59) 100%);
        color: rgb(255, 255, 255);
    }

    &.exclude {
        background: rgb(255, 229, 229);
        color: rgb(255, 0, 0);
    }
`;

export const AdvancedTargetingWrapper = styled.div<{height: string}>`
    height: ${({ height }) => height};
`;

export const Bar = styled.div<{width: number}>`
  position: relative;
  width: 100%;
  height: 13px;
  background-color: #F2F2F2;
  border: 1px solid #CCC;
  border-radius: 6px;
  overflow: hidden;
  margin: 20px 0 5px;

  div {
    transition: .4s;
    background: #20BA88;
    background: linear-gradient(90deg, #119986 0%, #38EF7D 100%);
    width: ${(props) => props.width}%;
    height: 100%;
  }
`;

export const HelpTooltip = styled(ReactTooltip).attrs((props) => ({
  effect: 'solid',
  delayHide: 300,
  delayShow: 300,
  delayUpdate: 500,
  place: props.place || 'bottom',
  backgroundColor: '#333',
  textColor: '#fff',
}))`
    &.type-custom {
        font-size: .8rem;
        max-width: 300px;
        line-height: 1.4;
        text-align: center;
        padding: 1rem;
        border-radius: 8px;
  
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
`;
