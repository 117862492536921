// eslint-disable-next-line max-len
const getBidByFormatsCountries = (formatsCountryRates: any, formats: any[], countries: any[], isBrandsafe = false) => {
  const bids = countries.reduce((prevValues: any, country: any) => {
    // eslint-disable-next-line no-shadow
    const { minBid, recBid, maxBid } = formats.reduce((prevValues: any, format: any) => {
      const theBids = isBrandsafe ? formatsCountryRates.brandsafe : formatsCountryRates.default;

      const formatCountryMinBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.min_bid || 0;
      const formatCountryRecBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.custom_bid || theBids?.[isBrandsafe ? 'push' : format]?.[country]?.calculated_bid || 0;
      const formatCountryMaxBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.max_bid || 0;

      return {
        minBid: Math.max(prevValues.minBid, formatCountryMinBid),
        recBid: Math.max(prevValues.recBid, formatCountryRecBid),
        maxBid: Math.max(prevValues.maxBid, formatCountryMaxBid),
      };
    }, { minBid: 0, recBid: 0, maxBid: 0 });

    return {
      minBid: Math.max(prevValues.minBid, minBid),
      recBid: Math.max(prevValues.recBid, recBid),
      maxBid: Math.max(prevValues.maxBid, maxBid),
    };
  }, { minBid: 0, recBid: 0, maxBid: 0 });

  return bids;
};

export default getBidByFormatsCountries;
