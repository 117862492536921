/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
// import { Button } from '../../../app/create/createStyledCommon'

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: 1px solid #000;
    border-radius: 8px;
    height: 2.4rem;
    padding: 0 .75rem;
  `;

export const IconButton = styled(Button)`
  border: none;
  height: auto;
  padding: 5px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000')};
  transition: .4s;

  &:hover {
    color: ${(props) => (props.bgColor ? props.bgColor : '#000')};
    background-color: ${(props) => (props.color ? props.color : '#fff')};
  }
`;

export const DropZone = styled.div`
  flex: 1;
  border-radius: 4px;
  border: 1px dashed ${(props) => (props.error ? 'red' : '#DEE2E6')};
  position: relative;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) => !props.disabled && css`
    ${(props) => props.isDragging && css`
      background-color: #eef2f5;
    `};
    &:hover {
      background-color: #eef2f5;
    }
  `};
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -5;
    text-indent: -999px;
  }
  > * {
    margin: 10px 0;
  }
`;

export const Title = styled.h3`
  font-size: 1em;
  text-align: center;
`;

export const Description = styled.p`
  font-size: .8em;
  text-align: center;
  color: #707070;
`;

export const ImageWrapper = styled.div`
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ImageActions = styled.div`
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: flex;
  > * {
    margin-left: 5px;
  }
`;
