import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
  }
  
  body, input, button {
    font-family: Poppins, sans-serif;
  }

  body {
    color: #212529;
  }
  
  input, textarea {
    outline: none;
  }

  input[type="checkbox"], button {
    cursor: pointer;
  }
  
  button {
    background-color: transparent;
    border: none;

    :focus {
      outline: none;
      box-shadow: none;
    }
  }

  .MuiList-root {
    max-height: 230px;
  }
  
  .react-datepicker-popper {
    && {
      z-index: 3;
      
      .react-datepicker {
          font-family: Poppins, sans-serif;
          font-size: .8rem;
        }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyles;
