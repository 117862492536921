/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { FieldWrapper, Label, StyledCheckbox } from './styles/Checkbox.styled.js';

function Checkbox({
  options, selectedOptions,
  minToSelect = 0, onCheck,
}) {
  const [selectables, setSelectables] = useState([]);
  const [values, setValues] = useState([]);

  const handleChange = (item) => {
    const newValues = values.includes(item) ? values.filter((v) => v !== item) : [...values, item];
    if (newValues.length >= minToSelect) {
      setValues(newValues);
      if (onCheck) onCheck(newValues);
    }
  };

  useEffect(() => {
    const mapable = Object.keys(options).map((key) => ({ value: key, label: options[key] }));
    setSelectables(mapable);
    setValues(selectedOptions || []);
  }, [options, selectedOptions]);

  return (
    <FieldWrapper>
      {
          selectables.map((item) => (
            <StyledCheckbox key={item.value}>
              <input
                type="checkbox"
                id={`checkbox-${item.value}`}
                name={item.value}
                value={item.value}
                checked={values.includes(item.value)}
                onChange={() => handleChange(item.value)}
              />
              <Label htmlFor={`checkbox-${item.value}`}>
                {item.label}
              </Label>
            </StyledCheckbox>
          ))
        }
    </FieldWrapper>
  );
}

export default Checkbox;
