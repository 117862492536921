import React, {
  useState, useEffect, useLayoutEffect,
} from 'react';
import moment from 'moment';
import { IconButton } from '@mui/material';
import {
  FaPen, FaToggleOn, FaToggleOff, FaEye,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import {
  useChangeCreativityStateMutation, useLazyGetCreativitiesQuery, useLazyGetSupplyFeedBidsQuery,
  useLazyGetSupplyFeedBlacklistQuery, useSupplyFeedBlacklistMutation, type SupplyFeeds,
} from '../redux/campaignsAPI';
import { CenteredFlexContainer, FlexContainer } from '../../../common/components/styles/Flex';
import { CreativityWrapper, CurrentToggle, CustomColumnsAndPaginationContainer } from './styles/DataDrawer';
import {
  HeaderCell, HeaderCellArrow, StyledTable, Tooltip,
} from './styles/Table';
import {
  CreativityImg, CreativityLogo, CreativityLogoAndTextContainer, CreativityTextContainer,
  CreativityTitle,
} from '../../moderation/components/styles/ModerationCard.styled';
import CustomColumnsPopper from './CustomColumnsPopper';
import TablePagination from './TablePagination';
import formatNumber from '../../../common/utils/formatNumber';
import getViewContent from '../utils/getViewContent';
import { userFreshnessOptions } from '../data/userFreshnessOptions';
import type { CampaignsStats } from '../redux/campaignsSlice';

interface DataDrawerTableProps {
    campaignStats: CampaignsStats;
    currDimension: string;
    isFetchingStats: boolean;
    campaignBid: number;
    campaignId: number;
}

interface GetColumnHeadersParams {
    currDimension: string;
    areThereCreativities: boolean;
}

const getColumnHeaders = ({ currDimension, areThereCreativities }: GetColumnHeadersParams) => ([
  ...(['supplyFeed', 'source', ...(areThereCreativities ? ['creativity'] : [])].includes(currDimension) ? [{ header: 'On/Off', canReorder: false }] : []),
  { header: currDimension, canReorder: false },
  ...(currDimension === 'supplyFeed' ? [{ header: 'Bid', canReorder: false }] : []),
  { header: 'Conversions', canReorder: true, statName: 'conversions' },
  { header: 'Spent', canReorder: true, statName: 'cost' },
  { header: 'Clicks', canReorder: true, statName: 'clicks' },
  ...(currDimension === 'supplyFeed' ? [{ header: 'Opportunities', canReorder: true, statName: 'bids' }] : []),
  { header: 'Deliveries', canReorder: true, statName: 'deliveries' },
  { header: 'Click Rate', canReorder: true, statName: 'clickRate' },
  { header: 'Win Rate', canReorder: true, statName: 'winRate' },
  { header: 'Conversion Rate', canReorder: true, statName: 'conversionRate' },
  { header: 'View Content', canReorder: true, statName: 'viewContent' },
  { header: 'CPA', canReorder: true, statName: 'cpa' },
]

);

function DataDrawerTable({
  campaignStats, currDimension, isFetchingStats, campaignBid, campaignId,
}: DataDrawerTableProps) {
  const [getSupplyFeedBids, { data: supplyFeedBids }] = useLazyGetSupplyFeedBidsQuery();
  const [getCreativities, { data: creativities }] = useLazyGetCreativitiesQuery();
  const [
    getSupplyFeedBlacklist, { data: supplyFeedBlacklist },
  ] = useLazyGetSupplyFeedBlacklistQuery();

  useEffect(() => {
    getSupplyFeedBids({ campaignId });
    getSupplyFeedBlacklist({ campaignId });
    getCreativities({ campaignId });
  }, []);

  const [changeSupplyFeedBlacklistState] = useSupplyFeedBlacklistMutation();
  const [changeCreativityState] = useChangeCreativityStateMutation();

  const columnHeaders = getColumnHeaders({
    currDimension, areThereCreativities: !!creativities?.length,
  });

  const [currOrderingColumn, setCurrOrderingColumn] = useState({ headerCellName: 'spent', statName: 'cost', higherFirst: true });

  const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
  const hideableColumns = ['Bid', 'Conversions', 'Spent', 'Clicks', 'Opportunities', 'Deliveries', 'Click Rate', 'Win Rate', 'Conversion Rate', 'View Content', 'CPA'];

  const [firstColumnWidth, setFirstColumnWidth] = useState<number>();

  const [numberOfRows, setNumberOfRows] = useState(25);

  const numberOfPages = Number(formatNumber({
    number: campaignStats?.leafs ? Math.ceil(campaignStats.leafs.length / numberOfRows) : 1,
    numberOfDecimals: 0,
  })) || 1;

  function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  const getSortedCampaigns = () => (campaignStats?.leafs?.slice().sort((a, b) => {
    const value1 = a.metrics[currOrderingColumn.statName];
    const value2 = b.metrics[currOrderingColumn.statName];

    if (currOrderingColumn.higherFirst) {
      return (value1 || 0) >= (value2 || 0) ? -1 : 1;
    }

    return (value2 || 0) >= (value1 || 0) ? -1 : 1;
  }));

  const [currPageRows, setCurrPageRows] = useState<any>(null);

  useEffect(() => () => {
    setCurrPageRows(null);
  }, []);

  useEffect(() => {
    const sortedCampaignsList = getSortedCampaigns();

    if (sortedCampaignsList && typeof campaignStats === 'object' && campaignStats) {
      setCurrPageRows(Array.from(chunks(sortedCampaignsList ?? [], numberOfRows)));
    }
  }, [campaignStats, numberOfRows,
    currOrderingColumn.statName, currOrderingColumn.higherFirst]);

  useLayoutEffect(() => {
    setFirstColumnWidth(document.getElementsByClassName('first-column')[0]?.clientWidth);
  }, [currPageRows, isFetchingStats]);

  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    if (!!numberOfPages && currPage > numberOfPages!) {
      setCurrPage(numberOfPages!);
    }
  }, [numberOfPages]);

  return (
    <>
      <CustomColumnsAndPaginationContainer>
        <CustomColumnsPopper
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          hideableColumns={hideableColumns}
        />
        <TablePagination
          numberOfPages={numberOfPages}
          currPage={currPage}
          setCurrPage={setCurrPage}
          numberOfRows={numberOfRows}
          setNumberOfRows={setNumberOfRows}
        />
      </CustomColumnsAndPaginationContainer>
      <StyledTable
        isThereContent={!!campaignStats && !isFetchingStats}
        isDataDrawerTable
        firstColumnWidth={firstColumnWidth}
      >
        <thead>
          <tr>
            {columnHeaders.map(({ header, canReorder, statName }) => (
              <HeaderCell
                className={`${canReorder && 'reorder-column'}`}
                onClick={() => {
                  if (canReorder) {
                    setCurrOrderingColumn({
                      headerCellName: header.toLowerCase(),
                      higherFirst: currOrderingColumn.headerCellName === header.toLowerCase()
                        ? !currOrderingColumn.higherFirst : true,
                      statName: statName!,
                    });
                  }
                }}
                isHidden={hiddenColumns.includes(header)}
                key={header}
              >
                <FlexContainer>
                  {header}
                  {' '}
                  {header.toLowerCase() === currOrderingColumn.headerCellName && (
                    <HeaderCellArrow $rotationDegrees={currOrderingColumn.higherFirst ? 180 : 0} />
                  )}
                </FlexContainer>
              </HeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {isFetchingStats || !currPageRows || !campaignStats
            ? <tr />
            : (
              <>
                {[...(currPageRows[currPage - 1] || [])].map(({ metrics, dimensions }, index) => {
                  const formattedDimensions: {[key: string]: string | undefined | boolean} = {
                    day: moment(new Date(dimensions.day)).format('MMM D'),
                    source: `${dimensions.supplyFeed}-${dimensions.source}`,
                    userAge: userFreshnessOptions[dimensions.userAge],
                    creativity: !!creativities?.length && creativities?.find(
                      ({ id }: { id: number }) => (id === +dimensions.creativity),
                    )?.title,
                  };

                  const findSupplyFeed = ({ supplyFeedId, source }: SupplyFeeds) => (
                    supplyFeedId === +dimensions.supplyFeed && !source
                  );

                  const supplyFeedBid = supplyFeedBids?.find(findSupplyFeed)?.bid;

                  const currCreativity = creativities?.find(
                    ({ id }: { id: number }) => (id === +dimensions.creativity),
                  );

                  const isToggleOff: {[key: string]: object | undefined | boolean } = {
                    supplyFeed: supplyFeedBlacklist?.find(findSupplyFeed),
                    source: supplyFeedBlacklist?.find(({ source }) => source === dimensions.source),
                    creativity: !currCreativity?.active,
                  };

                  return (
                    <tr key={index}>
                      <td className="first-column">
                        {['supplyFeed', 'source', ...(creativities?.length ? ['creativity'] : [])].includes(currDimension) ? (
                          <CenteredFlexContainer>
                            <CurrentToggle
                              as={isToggleOff[currDimension] ? FaToggleOff : FaToggleOn}
                              color={isToggleOff[currDimension] ? '#D9DDE5' : '#56E497'}
                              size={20}
                              onClick={() => {
                                if (['supplyFeed', 'source'].includes(currDimension)) {
                                  changeSupplyFeedBlacklistState({
                                    isRemove: isToggleOff[currDimension],
                                    body: {
                                      campaignId,
                                      supplyFeedBlacklists: [{
                                        supplyFeedId: dimensions.supplyFeed,
                                        source: dimensions.source || null,
                                      }],
                                    },
                                  });
                                } else if (currDimension === 'creativity') {
                                  changeCreativityState({
                                    creativityId: dimensions.creativity,
                                    body: { active: isToggleOff[currDimension] },
                                  });
                                }
                              }}
                            />
                          </CenteredFlexContainer>
                        ) : formattedDimensions[currDimension] || dimensions[currDimension]}
                      </td>
                      {['supplyFeed', 'source', ...(creativities?.length ? ['creativity'] : [])].includes(currDimension) && (
                        <td data-tip data-for={currDimension === 'creativity' && formattedDimensions[currDimension] ? `own-creativity-cell-${currCreativity?.id}` : ''}>
                          {currDimension === 'creativity' && formattedDimensions[currDimension] && <FaEye style={{ position: 'relative', top: 2, marginRight: 3 }} />}
                          {formattedDimensions[currDimension] || dimensions[currDimension]}
                        </td>
                      )}
                      {currDimension === 'supplyFeed' && (
                        <td className={`bid-cell ${hiddenColumns.includes('Bid') && 'hidden-cell'}`}>
                          {supplyFeedBid || campaignBid}
                          <span>
                            <IconButton>
                              <FaPen size={13} />
                            </IconButton>
                          </span>
                        </td>
                      )}
                      <td className={`${hiddenColumns.includes('Conversions') && 'hidden-cell'}`}>
                        {formatNumber({ number: metrics.conversions }) || 0}
                      </td>
                      <td className={`${hiddenColumns.includes('Spent') && 'hidden-cell'}`}>
                        {`$${formatNumber({ number: metrics.cost, numberOfDecimals: 2 }) || 0}`}
                      </td>
                      <td className={`${hiddenColumns.includes('Clicks') && 'hidden-cell'}`}>
                        {formatNumber({ number: metrics.clicks }) || 0}
                      </td>
                      {currDimension === 'supplyFeed' && <td className={`${hiddenColumns.includes('Opportunities') && 'hidden-cell'}`}>{formatNumber({ number: metrics.bids }) || 0}</td>}
                      <td className={`${hiddenColumns.includes('Deliveries') && 'hidden-cell'}`}>
                        {formatNumber({ number: metrics.deliveries }) || 0}
                      </td>
                      <td className={`${hiddenColumns.includes('Click Rate') && 'hidden-cell'}`}>
                        {`${formatNumber({ number: metrics.clickRate, numberOfDecimals: 2 }) || 0}%`}
                      </td>
                      <td className={`${hiddenColumns.includes('Win Rate') && 'hidden-cell'}`}>
                        {`${formatNumber({ number: metrics.winRate, numberOfDecimals: 2 }) || 0}%`}
                      </td>
                      <td className={`${hiddenColumns.includes('Conversion Rate') && 'hidden-cell'}`}>
                        {`${formatNumber({ number: metrics.conversionRate, numberOfDecimals: 2 }) || 0}%`}
                      </td>
                      <td className={`${hiddenColumns.includes('View Content') && 'hidden-cell'}`}>{getViewContent({ viewContent: metrics.viewContent, clicks: metrics.clicks })}</td>
                      <td className={`${hiddenColumns.includes('CPA') && 'hidden-cell'}`}>
                        {`$${formatNumber({ number: metrics.cpa, numberOfDecimals: 2 }) || 0}`}
                        <ReactTooltip id={`own-creativity-cell-${currCreativity?.id}`} effect="solid" place="bottom" backgroundColor="white">
                          <CreativityWrapper>
                            <CreativityLogoAndTextContainer>
                              {!!currCreativity?.icon && <CreativityLogo src={`https://app.pushground.com/${currCreativity.icon}`} /> }
                              <CreativityTextContainer>
                                <CreativityTitle>{currCreativity?.title}</CreativityTitle>
                                <p>{currCreativity?.description}</p>
                              </CreativityTextContainer>
                            </CreativityLogoAndTextContainer>
                            {!!currCreativity?.image && (
                            <CreativityImg src={`https://app.pushground.com/${currCreativity.image}`} objectFit="contain" />
                            )}
                          </CreativityWrapper>
                        </ReactTooltip>
                      </td>
                    </tr>
                  );
                })}
                {campaignStats?.leafs && !campaignStats.leafs.length && (
                  <tr className="no-data">
                    <td colSpan={columnHeaders.length}>
                      No data to display
                    </td>
                  </tr>
                )}
              </>
            )}
        </tbody>
        <tfoot>
          <tr>
            {['supplyFeed', 'source', 'creativity'].includes(currDimension) && <td>-</td>}
            <td>{`${(!isFetchingStats && campaignStats?.leafs.length) || 0} stat${campaignStats?.leafs.length === 1 ? '' : 's'}`}</td>
            {currDimension === 'supplyFeed' && (<td className={`${hiddenColumns.includes('Bid') && 'hidden-cell'}`}>-</td>)}
            <td className={`${hiddenColumns.includes('Conversions') && 'hidden-cell'}`}>
              {formatNumber({ number: campaignStats?.total.metrics.conversions }) || 0}
            </td>
            <td className={`${hiddenColumns.includes('Spent') && 'hidden-cell'}`}>
              {`$${formatNumber({ number: campaignStats?.total.metrics.cost, numberOfDecimals: 2 }) || 0}`}
            </td>
            <td className={`${hiddenColumns.includes('Clicks') && 'hidden-cell'}`}>
              {formatNumber({ number: campaignStats?.total.metrics.clicks }) || 0}
            </td>
            {currDimension === 'supplyFeed' && <td className={`${hiddenColumns.includes('Opportunities') && 'hidden-cell'}`}>{campaignStats?.total.metrics.bids || 0}</td>}
            <td className={`${hiddenColumns.includes('Deliveries') && 'hidden-cell'}`}>
              {campaignStats?.total.metrics.deliveries || 0}
            </td>
            <td className={`${hiddenColumns.includes('Click Rate') && 'hidden-cell'}`}>
              {`${formatNumber({ number: campaignStats?.total.metrics.clickRate, numberOfDecimals: 2 }) || 0}%`}
            </td>
            <td className={`${hiddenColumns.includes('Win Rate') && 'hidden-cell'}`}>
              {`${formatNumber({ number: campaignStats?.total.metrics.winRate, numberOfDecimals: 2 }) || 0}%`}
            </td>
            <td className={`${hiddenColumns.includes('Conversion Rate') && 'hidden-cell'}`}>
              {`${formatNumber({ number: campaignStats?.total.metrics.conversionRate, numberOfDecimals: 2 }) || 0}%`}
            </td>
            <td className={`${hiddenColumns.includes('View Content') && 'hidden-cell'}`}>
              {getViewContent({
                viewContent: campaignStats?.total.metrics.viewContent,
                clicks: campaignStats?.total.metrics.clicks,
              })}
            </td>
            <td className={`${hiddenColumns.includes('CPA') && 'hidden-cell'}`}>
              {`$${formatNumber({ number: campaignStats?.total.metrics.cpa, numberOfDecimals: 2 }) || 0}`}
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
}

export default DataDrawerTable;
