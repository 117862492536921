/* eslint-disable no-redeclare */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { updateCreativitiesList } from '../redux/createCampaignSlice';
import {
  Indexes, Wrapper, Index, Action,
} from './styles/creativitiesStyled.js';
import CreativityEditor from './creativityEditor.jsx';
import Error from './FieldError.jsx';
import { convertImgToBase64 } from './imageHelpers.js';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

function Creativities() {
  const {
    creativitiesList,
    configuration,
    creativitiesAllowed,
    creativitiesErrors,
  } = useAppSelector(({
    createCampaignSlice: { creativities, createConfig, validationErrors },
  }) => ({
    creativitiesList: creativities.list && creativities.list.map(
      (c) => ({ ...c, status: creativities.status[c.id] || null }),
    ),
    configuration: createConfig,
    creativitiesAllowed: createConfig.creativities === undefined || createConfig.creativities,
    creativitiesErrors: validationErrors?.creativities,
  }));
  const dispatch = useAppDispatch();

  const [creationIndex, setCreationIndex] = useState(2);
  const [selectedCreativityId, setSelectedCreativityId] = useState(null);
  const [creativityError, setCreativityError] = useState();

  const deleteCreativity = (id) => {
    setCreativityError(null);
    const newList = creativitiesList.filter((c) => c.id !== id);
    if (newList && newList.length > 0) {
      setSelectedCreativityId(newList.slice(-1)[0].id);
      dispatch(updateCreativitiesList(newList));
    } else {
      setCreativityError("At least one creativity has to be 'running', please set another creativity as 'running' before removing this one.");
    }
  };

  const getBase64Img = (img) => new Promise((resolve) => {
    convertImgToBase64(img, (newImg) => resolve(newImg));
  });

  const checkCreativityIsFinished = ({ title, description, icon }) => {
    setCreativityError(null);
    if (title && description && ((configuration?.creativities
        && !configuration.creativities.icon) || icon)) {
      return true;
    }
    setCreativityError('Please finish the creativity to continue.');
    return false;
  };

  const createCreativity = async () => {
    setCreativityError(null);
    const selectedCreativity = creativitiesList && creativitiesList?.filter(
      (c) => c.id === selectedCreativityId,
    )[0];
    let {
      title, description, icon, image, iconFileName, imageFileName,
    } = selectedCreativity || {};
    if (!selectedCreativity || checkCreativityIsFinished(selectedCreativity)) {
      if (icon && !icon.startsWith('data:image')) {
        icon = await getBase64Img(icon);
      }
      if (image && !image.startsWith('data:image')) {
        image = await getBase64Img(image);
      }
      const newCreativity = {
        id: creationIndex,
        title,
        description,
        icon,
        image,
        iconFileName,
        imageFileName,
        __isNew__: true,
      };
      dispatch(updateCreativitiesList([...creativitiesList, newCreativity]));
      setCreationIndex(creationIndex + 1);
      setSelectedCreativityId(creationIndex);
    }
  };

  const selectCreativity = (id) => {
    const selectedCreativity = creativitiesList && creativitiesList.filter(
      (c) => c.id === selectedCreativityId,
    )[0];
    if (checkCreativityIsFinished(selectedCreativity)) {
      setSelectedCreativityId(id);
    }
  };

  useEffect(() => {
    if (!creativitiesList) {
      createCreativity();
    } else if (Array.isArray(creativitiesList) && creativitiesList.length) {
      setSelectedCreativityId(creativitiesList[0].id);
    }
  }, []);

  return (
    <>
      <Element name="creativities">
        <Indexes>
          {
                  creativitiesList && creativitiesList.map(({ id, status, __isNew__ }, idx) => (
                    <Index
                      key={id}
                      error={creativitiesErrors?.creativities?.[id]}
                      selected={selectedCreativityId === id}
                      runningStatus={status?.split(' ')[0]}
                      onClick={() => selectCreativity(id)}
                    >
                      {__isNew__ ? `Version ${idx + 1}` : (
                        <>
                          {id}
                          <span>{status?.split('_')[0]}</span>
                        </>
                      )}
                    </Index>
                  ))
                }
          {
                  creativitiesList?.length < 10
                  && (
                  <Index>
                    <Action onClick={createCreativity}>
                      + Add Version
                    </Action>
                  </Index>
                  )
                }
        </Indexes>
      </Element>
      <Wrapper>
        <Error
          error={creativitiesErrors?.creativities?.all?.error || creativityError}
          style={{ marginBottom: 10 }}
        />
        {
                creativitiesList && creativitiesList.map((creativity) => (
                  <CreativityEditor
                    iconAllowed={configuration?.creativities?.icon !== false}
                    imageAllowed={configuration?.creativities?.image !== false}
                    key={creativity.id}
                    creativity={creativity}
                    creativityErrors={creativitiesErrors?.creativities?.[creativity.id]}
                    isSelected={creativity.id === selectedCreativityId}
                    deleteCreativity={() => deleteCreativity(creativity.id)}
                  />
                ))
              }
      </Wrapper>
    </>
  );
}

export default Creativities;
