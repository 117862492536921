import styled from 'styled-components/macro';
import { filterStyles } from '../../../../common/components/styles/Select';

export const FiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
`;

export const StyledInput = styled.input`
    ${filterStyles}
    padding: 0 15px;
    border: 1px solid #c2c2c2;
    
    &:hover {
        border: 1px solid #000;
    }
    
    &:focus {
        padding: 0 14px;
        border: 2px solid #3873cb;
    }
`;
