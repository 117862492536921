/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as closeIcon } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseBtn = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  outline: none !important;
  border: none;
  box-shadow: none;
  text-decoration: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
`;

const Box = styled.div`
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
`;

function Overlay({
  isOpen, onClose, children, ...rest
}) {
  if (!isOpen) return null;
  return (
    <Wrapper {...rest}>
      <Box>
        {
          onClose && (
          <CloseBtn onClick={onClose}>
            <FontAwesomeIcon color="black" icon={closeIcon} />
          </CloseBtn>
          )
        }
        {children}
      </Box>
    </Wrapper>
  );
}

Overlay.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object,
};

export default Overlay;
