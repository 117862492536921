import { IconButton } from '@mui/material';
import styled from 'styled-components/macro';
import { StyledSelect } from '../../../../common/components/styles/Select';

export const PaginationInputContainer = styled.div`
    color: rgb(144, 146, 150);
    font-size: 15px;
    white-space: nowrap;
`;

export const PaginationInput = styled.input`
    width: 35px;
    height: 35px;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    border: 1px solid rgb(217, 221, 229);
    border-radius: 5px;
`;

export const NumberOfRowsSelect = styled(StyledSelect)`
    && {
        font-size: 15px;
        width: 67px;
        height: 35px;
        margin-left: 5px;
    }
`;

export const StyledIconButton = styled(IconButton)`
    && {
        display: flex;
        align-items: center;
        height: 26px;
    }
`;
