import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';

interface StyledUserMenuProps {
    isOpen: boolean;
}

export const StyledUserMenu = styled.div<StyledUserMenuProps>`
    position: fixed;
    top: 64px;
    right: ${({ isOpen }) => (isOpen ? 0 : '-300px')};
    color: #aaa;
    background-color: #464646;
    width: 290px;
    padding: 16px;
    z-index: 11;
    transition: all 0.4s ease 0s;
`;

export const RankWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    background-color: #535353;
    padding: 28px 10px 15px;
    border-radius: 3px;
    margin-bottom: 15px;

    .current-rank-container, .days-left-container {
        cursor: pointer;
    }

    .days-left-container {
        font-size: 15px;
    }
`;

export const CurrentRankLogo = styled.img`
    display: block;
    margin: 0 auto;
    width: 58px;
`;

export const CurrentRank = styled.span`
    display: block;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 12px 0 6px;
`;

export const Tooltip = styled(ReactTooltip).attrs({
  effect: 'solid',
  backgroundColor: '#333',
  delayHide: 300,
  delayShow: 300,
})`
    text-align: center;
    line-height: 1.4;
    max-width: 200px;

    a {
      color: #FFF;
    }
  `;

interface RankProgressBarProps {
    nextMonthRankColor: string;
    progressBarWidth: number;
}

export const RankProgressBarContainer = styled.div<RankProgressBarProps>`
    position: relative;
    width: calc(100% - 64px);
    height: 8px;
    background-color: #fff;
    margin: 19px 35px 10px;

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, .4);
        cursor: pointer;
        
        &:nth-child(1) {
          left: -5px;
        }
      
        &:nth-child(2) {
          right: -5px;
        }
    }

    .progress-bar {
        width: ${({ progressBarWidth }) => progressBarWidth}%;
        height: 100%;
        background: ${({ nextMonthRankColor }) => nextMonthRankColor};
    }
`;

export const ProgressBarTooltipHelper = styled.div`
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 100%;
    cursor: pointer;
`;

export const NextMonthRankSpendingRange = styled.div`
    display: flex;
    justify-content: space-between;
    
    span {
        font-size: 15px;
        font-weight: 500;
        width: 165px;
        text-align: center;
    }
`;

export const RanksArticleLink = styled.a`
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
`;
