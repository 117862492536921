const createImage = (url) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
  image.src = url;
});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
const getCroppedImg = async (imageSrc, pixelCrop, imageLimitation) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const {
    ratio, minWidth = 0, minHeight = 0, maxHeight, maxWidth,
  } = imageLimitation;

  if (image.width > maxWidth) {
    canvas.width = maxWidth;
    canvas.height = maxWidth / ratio;
  } else if (image.height > maxHeight) {
    canvas.height = maxHeight;
    canvas.width = maxHeight * ratio;
  } else if (image.width < minWidth) {
    canvas.width = minWidth;
    canvas.height = minWidth / ratio;
  } else if (image.height < minHeight) {
    canvas.height = minHeight;
    canvas.width = minHeight * ratio;
  } else {
    canvas.width = image.width;
    canvas.height = image.width / ratio;
  }

  // console.log(imageLimitation)
  // console.log(canvas.height, canvas.width)

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  // As Base64 string
  return canvas.toDataURL(image.type);

  // As a blob
  // return new Promise(resolve => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
};

export default getCroppedImg;
