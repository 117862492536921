import { SectionWrapper } from '../../common/components/styles/SectionWrapper';
import { Banner, NoPaymentsYet, StyledTable } from './components/styles/Balance.styled';

const areTherePayments = true;
const data = [
  { date: '2023-07-05 10:23:04', amount: '$101,252.91', concept: 'paid_invoice_2pbmszve3hlptjdrahdw9jxa15dyacym' },
  { date: '2023-07-04 10:53:25', amount: '$500.00', concept: 'compensation_refund_by_Irina' },
  { date: '2023-06-12 08:48:13', amount: '$67,787.89', concept: 'paid_invoice_nz5yopsd44aswga7xwc5954gcqlcwjw3' },
  { date: '2023-05-09 16:01:04', amount: '$60,717.86', concept: 'paid_invoice_hd4ijvvziyal32d3d7ox2eku8ehidg1q' },
];

function Balance() {
  return (
    <SectionWrapper>
      <h1>Balance History</h1>
      <Banner>
        Showing all balance movements made to the platform, including Bonifications,
        Referrers and Refunds. For a new Top Up&nbsp;
        <a href="/topup" className="top-up-link">click here</a>
      </Banner>
      {!areTherePayments
        ? <NoPaymentsYet>No payments registered in this account</NoPaymentsYet>
        : (
          <StyledTable width="100%">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Concept</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ date, amount, concept }, index) => (
                <tr key={index}>
                  <td width="15%">{date}</td>
                  <td width="10%">{amount}</td>
                  <td width="75%">{concept}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
    </SectionWrapper>
  );
}

export default Balance;
