/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, {
  Fragment, useEffect, useLayoutEffect, useState,
} from 'react';
import { FaQuestion, FaInfoCircle } from 'react-icons/fa';
import { DialogContent, IconButton } from '@mui/material';
import range from 'lodash.range';
import { SectionWrapper } from '../../common/components/styles/SectionWrapper';
import { FlexContainer, WrappedFlexContainer } from '../../common/components/styles/Flex';
import { StyledCheckbox } from '../campaigns/components/styles/Table';
import {
  FormSectionCard, FormSectionTitleContainer, FormSectionTitle, SectionTitleTooltipBttn, Divider,
  HelpTooltip, Text, SmallText, FrequencyRadioBttnsContainer, Frequency, StyledLabel,
  UserFreshnessOptionsContainer, UserFreshnessBttn, ErrorMessage, ExtraInfo, ListTextarea,
  ListTitle, ListSectionContainer, TextareaContainer, DistributionBttn, StyledInput, MinBidBttn,
  BidBudgetElement, CPCRatesDialogTrigger, AdFormatOptionWrapper, OverspendingInfoContainer,
  PushgroundPlusBanner, PushgroundPlusDialogBttn, StyledPushgroundPlusDialog, PushgroundPlusToggle,
  Macro, TakeATourBttn, CampaignSummaryContainer, CampaignSummaryTitle, CampaignNameSummary,
  PublishCampaignBttn, PublishCampaignBttnContainer, CampaignSummaryData, SummarySubtitle,
  StyledSelect, StyledCreatableSelect,
  ActiveHoursContainer,
  Suggestion,
  Separator,
  AlternateBttn,
  AdvancedTargetingBttn,
  AdvancedTargetingWrapper,
  Table,
  ActionsWrapper,
  Action,
  SummaryInfo,
  Bar,
} from './components/styles/CreateCampaing';
import { StyledCloseIcon } from '../moderation/components/styles/RejectDialog.styled';
import CPCRatesDialog from './components/CPCRatesDialog';
import { userFreshnessOptions, userFreshnessSummary } from '../campaigns/data/userFreshnessOptions';
import macrosInitialState from './data/macros';
import validUrlRegex from './data/validUrlRegex';
import { useAppSelector } from '../../app/hooks';
import { useLazyGetFormatsCountryRatesQuery } from '../campaigns/redux/campaignsAPI';
import {
  useLazyGetCampaignDataQuery,
  useLazyGetCitiesQuery, useLazyGetFormDataQuery, useLazyGetRegionsQuery,
  useLazyGetTargetingReachQuery,
  useLazyGetTemplatesQuery,
  usePostCampaignMutation,
  usePutCampaignMutation,
} from './redux/createCampaignAPI';
import AutomaticRules from './components/AutomaticRules';
import Creativities from './components/Creativities.jsx';

interface SectionTitleHelpTooltipProps {
  tooltipText: string;
  url?: string;
}

function SectionTitleHelpTooltip({ tooltipText, url }: SectionTitleHelpTooltipProps) {
  return (
    <>
      <SectionTitleTooltipBttn data-tip={tooltipText} data-for={url || tooltipText} onClick={() => url && window.open(url, '_blank')}>
        <FaQuestion />
      </SectionTitleTooltipBttn>
      <HelpTooltip id={url || tooltipText}>
        {tooltipText}
        {url && (
          <>
            &nbsp;
            <a href={url} target="_blank" rel="noreferrer">Learn more</a>
            .
          </>
        )}
      </HelpTooltip>
    </>
  );
}

SectionTitleHelpTooltip.defaultProps = {
  url: null,
};

const formatFormData = (arr: Array<string | any>) => (
  arr.map((value) => {
    if (typeof value === 'string') {
      return { value, label: value };
    }

    return { ...value, value: value.id, label: value.text || value.label };
  })
);

function CreateCampaign() {
  const [getFormData] = useLazyGetFormDataQuery();
  const [getTemplates, { data: templates }] = useLazyGetTemplatesQuery();

  useLayoutEffect(() => {
    getFormData();
    getTemplates(null);
  }, []);

  const formData = useAppSelector(({ createCampaignSlice: { formData } }) => formData);
  const campaignData = useAppSelector(({ createCampaignSlice: { campaignData } }) => campaignData);
  const isEdit = window.location.href.includes('/edit');
  const editCampaign = isEdit ? campaignData : null;

  const [getCampaignData] = useLazyGetCampaignDataQuery();
  useLayoutEffect(() => {
    if (isEdit && !campaignData) {
      console.log('get data?');
      getCampaignData({ campaignId: +window.location.pathname.split('/')[3] });
    }
  }, []);

  const [getCities, { data: cities, isFetching, isLoading }] = useLazyGetCitiesQuery();

  const [campaignName, setCampaignName] = useState('');
  const [showCampaignNameError, setShowCampaignNameError] = useState({
    isEmpty: false, isTooLong: false,
  });

  const [trackingUrl, setTrackingUrl] = useState('');
  const [isTrackingUrlError, setIsTrackingUrlError] = useState({
    isEmpty: false, isInvalidUrl: false,
  });

  const [macros, setMacros] = useState(macrosInitialState);
  const [trackROI, setTrackROI] = useState(false);

  const [category, setCategory] = useState({ value: 'Other', label: 'Other' });

  useLayoutEffect(() => {
    if (editCampaign) {
      // setCategory();
    }
  }, []);

  const [selectedTrafficType, setSelectedTrafficType] = useState<{[key: string]: boolean}>({
    mainstream: true, adult: false,
  });
  const [showTrafficError, setShowTrafficError] = useState(false);

  const handleTrafficChange = (trafficTypeToChange: string) => {
    const newSelection = {
      ...selectedTrafficType, [trafficTypeToChange]: !selectedTrafficType[trafficTypeToChange],
    };

    if (Object.values(newSelection).every((trafficType) => !trafficType)) {
      setShowTrafficError(true);
    } else {
      setSelectedTrafficType(newSelection);

      if (showTrafficError) {
        setShowTrafficError(false);
      }
    }
  };

  const [adFormat, setAdFormat] = useState('classic');
  const [isPushgroundPlusActive, setIsPushgroundPlusActive] = useState(false);
  const [openPushgroundPlusDialog, setOpenPushgroundPlusDialog] = useState(false);

  const getOverspendingBanner = (adFormatType: string) => (
    <OverspendingInfoContainer>
      <img src="/assets/info.svg" alt="Info logo" className="info-logo" />
      <div>
        Before running&nbsp;
        {adFormatType}
        &nbsp;Push, please keep in mind that due to the nature of push
        notifications (and the CPC cost structure) there&apos;s a&nbsp;
        <strong>possibility of overspending your budget</strong>
        .
        <br />
        &nbsp;It works best with&nbsp;
        <strong>
          mobile Apps, casino, antivirus, branding and dating mainstream offers
        </strong>
        .
        <br />
        <br />
        For more information&nbsp;
        <a href="http://help.pushground.com/en/articles/3293731-overspending-your-budget" target="_blank" rel="noreferrer">click here</a>
        .
      </div>
    </OverspendingInfoContainer>
  );
  const [label, setLabel] = useState<Array<{ value: string; label: string; }>>([]);

  const { userRank } = useAppSelector(({ loginSlice: { user } }) => ({ userRank: user?.rank?.current || 'Bronze' }));
  const [country, setCountry] = useState<Array<{ value: string; label: string; id: string }>>([]);

  const [isCountryError, setIsCountryError] = useState({
    isEmpty: false, isUserNotGoldAndMultiSelect: false,
  });

  const [getRegions, {
    data: regions, isFetching: fetchingRegions, isLoading: loadingRegions,
  }] = useLazyGetRegionsQuery();

  useLayoutEffect(() => {
    if (country.length === 1 && country?.find(({ text }: any) => ['United States', 'India'].includes(text))) {
      getRegions(country[0].id);
    }
  }, [country.length]);

  const [carrier, setCarrier] = useState<Array<{ value: string; label: string; }>>([]);

  const [device, setDevice] = useState<Array<{ value: string; label: string; }>>([]);

  const [networkType, setNetworkType] = useState<Array<{ value: string; label: string; }>>([]);

  const [OS, setOS] = useState<Array<{ value: string; label: string; }>>([]);

  const [browser, setBrowser] = useState<Array<{ value: string; label: string; }>>([]);
  const [includebrowsers, setIncludebrowsers] = useState(true);

  const [showAdvancedTargeting, setShowAdvancedTargeting] = useState(false);

  const [region, setRegion] = useState<Array<{ value: string; label: string; }>>([]);
  const [includeRegions, setIncludeRegions] = useState(true);

  const [city, setCity] = useState<Array<{ value: string; label: string; }>>([]);
  const [includeCities, setIncludeCities] = useState(true);

  const [language, setLanguage] = useState<Array<{ value: string; label: string; }>>([]);
  const [includeLanguages, setIncludeLanguages] = useState(true);

  const [androidVersion, setAndroidVersion] = useState<Array<{ value: string; label: string; }>>(
    [],
  );
  const [includeAndroidVersions, setIncludeAndroidVersions] = useState(true);

  const [windowsVersion, setWindowsVersion] = useState<Array<{ value: string; label: string; }>>(
    [],
  );
  const [includeWindowsVersions, setIncludeWindowsVersions] = useState(true);

  const [deviceName, setDeviceName] = useState<Array<{ value: string; label: string; }>>([]);
  const [includeDeviceNames, setIncludeDeviceNames] = useState(true);

  const [timeZone, setTimeZone] = useState<{ value: string; label: string; }>({ value: 'UTC', label: 'UTC' });

  const [selectedUserFreshness, setSelectedUserFreshness] = useState([1, 2, 3, 4, 5, 6]);
  const [selectedFrequency, setSelectedFrequency] = useState<{[key: string]: boolean}>({
    '1:24h': false, '2:24h': false, '3:24h': true, none: false,
  });
  const [listTextareas, setListTextareas] = useState({
    supplyWhite: '', siteWhite: '', supplyBlack: '', siteBlack: '', targetingWhite: '',
  });
  const [bidBudget, setBidBudget] = useState({
    bid: '0.01', isBidError: { numberOfDecimals: false, underMin: false }, budget: '50', isBudgetError: false,
  });
  const [budgetDistribution, setBudgetDistribution] = useState('accelerated');
  const [openCPCRatesDialog, setOpenCPCRatesDialog] = useState(false);
  const [CPCRatesDialogData, setCPCRatesDialogData] = useState({ title: 'Push Notification CPC Rates', tableContent: [{ country: 'AF', recBid: '$0.0920' }, { country: 'AG', recBid: '$0.0036' }] });

  const [isAutoOptimized, setIsAutoOptimized] = useState(true);

  const handleAutoOptimizedCbox = () => setIsAutoOptimized((prevState) => !prevState);

  const [selectedPublishStatus, setSelectedPublishStatus] = useState('active');

  const daysInWeek = range(1, 8);
  const daysInWeekString = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const hoursInDay = range(0, 24);
  const onlyNight = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
  const onlyDay = hoursInDay.filter((h: any) => !onlyNight.includes(h));

  const initialActiveHoursInWeek = range(1, 8).reduce((acc, curr) => (
    { ...acc, [curr]: range(0, 24) }), {});

  const [activeHoursInWeek, setActiveHoursInWeek] = useState<any>(initialActiveHoursInWeek);
  console.log(editCampaign);
  useLayoutEffect(() => {
    if (campaignData) {
      setCampaignName(editCampaign?.name);
      setTrackingUrl(editCampaign?.trackingUrl);
      setTrackROI(editCampaign?.enableRoiTrack);
      setCategory({ value: editCampaign?.vertical, label: editCampaign?.vertical });
      setSelectedTrafficType({
        mainstream: editCampaign.trafficType.includes('mainstream'), adult: editCampaign.trafficType.includes('adult'),
      });
      setAdFormat(editCampaign?.adFormat === 'push' ? 'classic' : 'inPage');
      setIsPushgroundPlusActive(editCampaign?.pushgroundPlus);
      setIsPushgroundPlusActive(editCampaign?.pushgroundPlus);
      setTimeZone({ value: editCampaign?.timezone, label: editCampaign?.timezone });
      setCountry(editCampaign?.countries.map((countryId: string) => {
        const countryContent = formData?.countries?.find((country: any) => country.id === countryId);
        return countryContent ? { label: countryContent?.text, value: countryContent?.id, ...countryContent } : {};
      }));
      setSelectedUserFreshness(editCampaign?.userFreshness);
      setSelectedFrequency({
        '1:24h': editCampaign?.impressionsFrequency === 1, '2:24h': editCampaign?.impressionsFrequency === 2, '3:24h': editCampaign?.impressionsFrequency === 3, none: editCampaign?.impressionsFrequency === 0,
      });
      setListTextareas({
        supplyWhite: editCampaign?.supplyIdWL, siteWhite: editCampaign?.siteIdWL, supplyBlack: editCampaign?.supplyIdBL, siteBlack: editCampaign?.siteIdBL, targetingWhite: editCampaign?.ipWL || '',
      });
      setBidBudget((prevState) => ({ ...prevState, bid: editCampaign?.bid, budget: editCampaign?.dailyBudget }));
      setBudgetDistribution(editCampaign?.smoothBudgetDistribution ? 'smooth' : 'accelerated');
      setIsAutoOptimized(editCampaign?.autoOptimizeByCreativity);
    }
  }, [campaignData]);

  const toggleAll = (state: any) => {
    const newMap: any = { ...activeHoursInWeek };
    daysInWeek.forEach((day) => {
      newMap[day] = state ? hoursInDay : [];
    });
    setActiveHoursInWeek(newMap);
  };

  const toggleWorkDays = () => {
    const newMap = { ...activeHoursInWeek };
    daysInWeek.forEach((day) => {
      newMap[day] = (day === 6 || day === 7) ? [] : hoursInDay;
    });
    setActiveHoursInWeek(newMap);
  };

  const toggleWeekend = () => {
    const newMap = { ...activeHoursInWeek };
    daysInWeek.forEach((day) => {
      newMap[day] = (day !== 6 && day !== 7) ? [] : hoursInDay;
    });
    setActiveHoursInWeek(newMap);
  };

  const toggleDayTime = () => {
    const newMap = { ...activeHoursInWeek };
    daysInWeek.forEach((day) => {
      newMap[day] = onlyDay;
    });
    setActiveHoursInWeek(newMap);
  };

  const toggleNightTime = () => {
    const newMap = { ...activeHoursInWeek };
    daysInWeek.forEach((day) => {
      newMap[day] = onlyNight;
    });
    setActiveHoursInWeek(newMap);
  };

  const updateHour = (hour: any) => {
    const newMap: any = { ...activeHoursInWeek };
    const empty = daysInWeek.some((d) => newMap[d].includes(hour));
    daysInWeek.forEach((day) => {
      newMap[day] = empty ? newMap[day].filter((h: any) => h !== hour) : [...newMap[day], hour];
    });
    setActiveHoursInWeek(newMap);
  };

  const updateDay = (day: any) => {
    const newMap: any = { ...activeHoursInWeek };
    const empty = newMap[day].some((h: any) => !h);
    newMap[day] = empty ? [] : hoursInDay;
    setActiveHoursInWeek(newMap);
  };

  const updateMap = (day: any, hour: any) => {
    const newArray = activeHoursInWeek[day].includes(hour)
      ? activeHoursInWeek[day].filter((h: any) => h !== hour) : [...activeHoursInWeek[day], hour];
    const newMap = { ...activeHoursInWeek, [day]: newArray };
    setActiveHoursInWeek(newMap);
  };

  const [
    getFormatsCountryRates, { data: formatsCountryRates },
  ] = useLazyGetFormatsCountryRatesQuery();

  useLayoutEffect(() => {
    getFormatsCountryRates();
  }, []);

  const OSOptions = formData?.deviceOsVersionsTuples
    ? formatFormData(
      formData.deviceOsVersionsTuples.reduce((acc: any, curr: any) => (
        [...acc, ...(device.find(
          ({ value }) => value === curr.id,
        ) ? curr.childs.filter(({ id }: any) => !acc.find(
            ({ id: accId }: any) => id === accId,
          )) : [])]
      ), []),
    ) : [] as any;

  useEffect(() => {
    setOS(OS.filter(({ id: osId }: any) => OSOptions.find(
      ({ id: osOptionsId }: any) => osOptionsId === osId,
    )));
  }, [device.length]);

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const summaryTargeting = [[country], [carrier], [networkType], [device], [OS],
    [browser, includebrowsers], [region, includeRegions], [city, includeCities],
    [language, includeLanguages], [androidVersion, includeAndroidVersions],
    [windowsVersion, includeWindowsVersions],
    [deviceName, includeDeviceNames]].map((targeting: any) => targeting[0].flatMap(
    ({ label: targetingLabel }: any) => (
      targetingLabel && <SummaryInfo className={targeting[1] === false ? 'exclude' : ''}>{targetingLabel}</SummaryInfo>
    ),
  ));

  const getUserFreshnessSummary = () => {
    const userfreshnessSummary: any = [];
    let currInfo = '';

    for (let i = 0; i < userFreshnessOptions.length; i++) {
      if (i === 0 && selectedUserFreshness.includes(0)) {
        userfreshnessSummary.push(
          <SummaryInfo>
            {userFreshnessSummary[0].value}
          </SummaryInfo>,
        );
      } else if (selectedUserFreshness.includes(i)) {
        if ((!selectedUserFreshness.includes(i - 1) || i - 1 === 0)
          && !selectedUserFreshness.includes(i + 1)) {
          userfreshnessSummary.push(
            <SummaryInfo key={i}>{userFreshnessOptions[i]}</SummaryInfo>,
          );
        } else if ((!selectedUserFreshness.includes(i - 1) || i - 1 === 0)
        && selectedUserFreshness.includes(i + 1)) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          currInfo += userFreshnessSummary[i]?.from;
        } else if (selectedUserFreshness.includes(i - 1)
        && (!selectedUserFreshness.includes(i + 1) || i + 1 === 0)) {
          currInfo += ` to ${userFreshnessSummary[i]?.to}`;
          userfreshnessSummary.push(<SummaryInfo key={i}>{currInfo}</SummaryInfo>);
          currInfo = '';
        }
      }
    }

    return userfreshnessSummary;
  };

  const [getTargetingReach, { data: targetingReach }] = useLazyGetTargetingReachQuery();

  useEffect(() => {
    getTargetingReach({
      categories: Object.entries(selectedTrafficType).map(([name, active]) => active && name)
        .filter(Boolean),
      formats: [adFormat === 'classic' ? 'push' : 'web_push'],
      countries: (country as any).map(({ id }: { id: string }) => id),
      carriers: (carrier as any).map(({ id }: { id: string }) => id),
      networkTypes: (networkType as any).map(({ id }: { id: string }) => id),
      deviceTypes: (device as any).map(({ id }: { id: string }) => id),
      oses: (OS as any).map(({ id }: { id: string }) => id),
      userAgeGroups: selectedUserFreshness.map((number) => number.toString()),
    });
  }, [adFormat, selectedUserFreshness, OS, device, networkType,
    carrier, country, selectedTrafficType]);

  const [postCampaign] = usePostCampaignMutation();
  const [updateCampaign] = usePutCampaignMutation();

  const getFrequencyCode = (frequency: string) => {
    const frequencies: {[key: string]: number} = {
      None: 0,
      '1:24h': 1,
      '2:24h': 2,
      '3:24h': 3,
    };
    return frequencies[frequency];
  };

  const {
    creativitiesList,
    creativitiesErrors,
  } = useAppSelector(({
    createCampaignSlice: { creativities, validationErrors },
  }) => ({
    creativitiesList: creativities.list,
    creativitiesErrors: validationErrors?.creativities,
  }));

  const publishCampaign = () => {
    console.log(' whaaaaaat');
    const formdata = new FormData();
    const body = {
      name: campaignName,
      trackingUrl,
      enableRoiTrack: trackROI,
      labels: label.map(({ value }) => value),
      vertical: category.value,
      trafficType: Object.entries(selectedTrafficType).filter(([key, value]) => value && key[0]).map((value) => value[0]),
      adFormat: adFormat === 'classic' ? 'push' : 'web_push',
      pushgroundPlus: isPushgroundPlusActive,
      countries: country.map(({ value }) => value),
      carriers: carrier.map(({ value }) => value),
      networkTypes: networkType.map(({ value }) => value),
      devices: device.map(({ value }) => value),
      oses: OS.map(({ value }) => value),
      includeBrowsers: includebrowsers ? browser.map(({ value }) => value) : [],
      excludeBrowsers: !includebrowsers ? browser.map(({ value }) => value) : [],
      includeRegions: includeRegions ? region.map(({ value }) => value) : [],
      excludeRegions: !includeRegions ? region.map(({ value }) => value) : [],
      includeCities: includeCities ? city.map(({ value }) => value) : [],
      excludeCity: !includeCities ? city.map(({ value }) => value) : [],
      includeLanguages: includeLanguages ? language.map(({ value }) => value) : [],
      excludeLanguages: !includeLanguages ? language.map(({ value }) => value) : [],
      includeAndroidVersions: includeAndroidVersions ? androidVersion.map(({ value }) => value) : [],
      excludeAndroidVersions: !includeAndroidVersions ? androidVersion.map(({ value }) => value) : [],
      includeWindowsVersions: includeWindowsVersions ? windowsVersion.map(({ value }) => value) : [],
      excludeWindowsVersions: !includeWindowsVersions ? windowsVersion.map(({ value }) => value) : [],
      includeDeviceNames: includeDeviceNames ? deviceName.map(({ value }) => value) : [],
      excludeDeviceNames: !includeDeviceNames ? deviceName.map(({ value }) => value) : [],
      timezone: timeZone.value,
      ipWL: listTextareas.targetingWhite,
      activeWeekHours: activeHoursInWeek,
      userFreshness: selectedUserFreshness,
      impressionsFrequency: getFrequencyCode(Object.keys(selectedFrequency).filter((key) => selectedFrequency[key])[0]),
      supplyIdWL: listTextareas.supplyWhite,
      siteIdWL: listTextareas.siteWhite,
      supplyIdBL: listTextareas.supplyBlack,
      siteIdBL: listTextareas.siteBlack,
      bid: bidBudget.bid,
      dailyBudget: bidBudget.budget,
      autoOptimizeByCreativity: isAutoOptimized,
      active: selectedPublishStatus === 'active',
      rules: [],
      smoothBudgetDistribution: budgetDistribution === 'smooth',
      creativities: creativitiesList.map((creativity: any) => {
        const {
          title, description, iconFileName, imageFileName,
        } = creativity;
        return {
          title, description, icon: iconFileName, image: imageFileName, active: true,
        };
      }),
    };
    formdata.append('body', JSON.stringify(body));

    creativitiesList.forEach((creativity: any) => {
      formdata.append(creativity.iconFileName, creativity.icon);
      if (creativity.image) {
        formdata.append(creativity.imageFileName, creativity.image);
      }
    });
    console.log('entro??', isEdit);
    if (isEdit) {
      const campaignId = window.location.pathname.split('/')[3];
      console.log(formdata);
      updateCampaign({ formdata, campaignId });
    } else {
      postCampaign(formdata);
    }
  };

  return (
    <SectionWrapper backgroundColor="#F2F3F8">
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Basic Info & Tracking
          </FormSectionTitle>
          <FlexContainer>
            <TakeATourBttn onClick={() => window.open('/campaigns/create?product_tour_id=215705', '_self')}>Take a Tour</TakeATourBttn>
            <SectionTitleHelpTooltip tooltipText="Here is where you name and label your campaign, set up the tracking URL (where your ad will send people after they click) and S2S postback (used to track conversions)." url="http://help.pushground.com/en/articles/4099609-basic-info-tracking" />
          </FlexContainer>
        </FormSectionTitleContainer>
        <Divider />
        <Text style={{ marginBottom: 5 }}>Campaign Name*:</Text>
        <ErrorMessage show={Object.values(showCampaignNameError).some(Boolean)}>
          {showCampaignNameError.isEmpty && '\'Name\' is not allowed to be empty'}
          {showCampaignNameError.isTooLong && '\'Name\' length must be less than or equal to 255 characters long'}
        </ErrorMessage>
        <StyledInput
          value={campaignName}
          onChange={(event) => {
            setCampaignName(event.target.value);
            setShowCampaignNameError({
              isEmpty: !event.target.value,
              isTooLong: event.target.value.length > 255,
            });
          }}
          onBlur={(event) => setShowCampaignNameError((prevValue) => ({
            ...prevValue,
            isEmpty: !event.target.value,
          }))}
          isFullWidth
          isError={Object.values(showCampaignNameError).some(Boolean)}
        />
        <Text style={{ margin: '15px 0 5px' }}>Tracking Url*:</Text>
        <ErrorMessage show={Object.values(isTrackingUrlError).some(Boolean)}>
          {isTrackingUrlError.isEmpty && '\'Tracking Url\' is required. '}
          {isTrackingUrlError.isInvalidUrl && 'Please use a valid URL here'}
        </ErrorMessage>
        <StyledInput
          value={trackingUrl}
          onChange={(event) => {
            setTrackingUrl(event.target.value);
            setIsTrackingUrlError((prevValue) => ({
              isInvalidUrl: prevValue.isInvalidUrl && !validUrlRegex.test(macros.reduce((acc, curr) => acc.replace(`{${curr}}`, ''), event.target.value)),
              isEmpty: !event.target.value,
            }));
          }}
          onBlur={(event) => {
            setIsTrackingUrlError((prevValue) => ({
              isEmpty: !event.target.value,
              isInvalidUrl: event.target.value
                ? !validUrlRegex.test(macros.reduce((acc, curr) => acc.replace(`{${curr}}`, ''), event.target.value)) : prevValue.isInvalidUrl,
            }));
          }}
          isFullWidth
          isError={Object.values(isTrackingUrlError).some(Boolean)}
        />
        <ExtraInfo style={{ marginBottom: 5 }}>
          {'When using postback URL for conversion tracking, add the {click_id} macro to the offer URL. '}
          <a href="http://help.pushground.com/en/articles/3943451-what-are-macros" target="_blank" rel="noreferrer">Learn more.</a>
        </ExtraInfo>
        <WrappedFlexContainer>
          {macros.map((macro) => (
            <Macro
              onClick={() => {
                const isDisabled = !trackingUrl || trackingUrl.includes(`{${macro}}`);
                if (isDisabled) {
                  return;
                }

                setTrackingUrl((prevValue: any) => `${prevValue}{${macro}}`);
              }}
              isDisabled={!trackingUrl || trackingUrl.includes(`{${macro}}`)}
              isSelected={trackingUrl.includes(`{${macro}}`)}
              key={macro}
            >
              {`{${macro}}`}
            </Macro>
          ))}
        </WrappedFlexContainer>
        <Text style={{ margin: '17px 0 5px' }}>Conversion Tracking Postback:</Text>
        <StyledInput value="https://lupush.com/conversion?click_id={your_extenal_id_paramater}&amount={payout}" disabled isDisabled isFullWidth />
        <StyledLabel style={{ margin: '5px 0 -10px' }}>
          <StyledCheckbox
            checked={trackROI}
            onChange={() => setTrackROI((prevValue: any) => !prevValue)}
          />
          Enable the platform to track the ROI
        </StyledLabel>
        <ExtraInfo>
          For more information about postback integration read&nbsp;
          <a href="https://intercom.help/pushground/en/articles/2710130-s2s-postback-configuration" target="_blank" rel="noreferrer">the documentation.</a>
        </ExtraInfo>
        <Text style={{ margin: '15px 0 5px' }}>Labels:</Text>
        <StyledCreatableSelect
          value={label}
          onChange={(event) => setLabel(event! as Array<{ value: string; label: string; }>)}
          isSearchable
          isMulti
          options={formData?.labels ? formatFormData(formData.labels) : [] as any}
          placeholder="Select or type..."
        />
        <SmallText>Organize your campaigns with personalized labels.</SmallText>
        <Text style={{ margin: '15px 0 5px' }}>Category:</Text>
        <StyledSelect
          value={category}
          onChange={(event) => setCategory(event! as { value: string; label: string; })}
          isSearchable
          options={formData?.verticals ? formatFormData(formData.verticals) : [] as any}
        />
        <ExtraInfo>{'Category \'Dating Adult\' will only run with Adult traffic.'}</ExtraInfo>
      </FormSectionCard>
      <FormSectionCard id="traffic-type">
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Traffic Type
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Select the type of traffic that you want to like to send to your add.  The majority of our traffic is mainstream but with adult there are less creative restrictions." />
        </FormSectionTitleContainer>
        <Divider />
        <ErrorMessage show={showTrafficError}>
          At least one must be active, select another one before disabling it.
        </ErrorMessage>
        <FlexContainer style={{ gap: 10 }}>
          <StyledLabel>
            <StyledCheckbox checked={selectedTrafficType.mainstream} onChange={() => handleTrafficChange('mainstream')} />
            Mainstream
          </StyledLabel>
          <StyledLabel>
            <StyledCheckbox
              checked={selectedTrafficType.adult}
              onChange={() => {
                handleTrafficChange('adult');
                setIsPushgroundPlusActive(false);
              }}
            />
            Adult
          </StyledLabel>
        </FlexContainer>
        <ExtraInfo>Pushground+ will only run with Mainstream traffic.</ExtraInfo>
      </FormSectionCard>
      <FormSectionCard id="ad-format">
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Ad Format
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Select the ad format for your campaign. Its recommended that you start with push ads and always create new campaigns when targeting different ad types." url="http://help.pushground.com/en/articles/3630158-ad-format-types" />
        </FormSectionTitleContainer>
        <Divider />
        <AdFormatOptionWrapper onClick={() => setAdFormat('classic')} isSelected={adFormat === 'classic'}>
          <div className="ad-format-option-container">
            <div className="button-and-explanation">
              <label htmlFor="classic">
                <input
                  type="radio"
                  id="classic"
                  value="classic"
                  checked={adFormat === 'classic'}
                  readOnly
                />
                Classic Push
              </label>
              <Text>
                Push Notifications that pops up on your device through the browser.
              </Text>
            </div>
            {adFormat === 'classic' && (
              <>
                <PushgroundPlusBanner isDisabled={selectedTrafficType.adult}>
                  <div className="toggle-container">
                    <PushgroundPlusToggle
                      checked={isPushgroundPlusActive}
                      onClick={() => (
                        !selectedTrafficType.adult
                    && setIsPushgroundPlusActive((prevState) => !prevState)
                      )}
                    />
                    Activate
                  </div>
                  <div className="premium-traffic-text">
                    PUSHGROUND
                    <span className="plus-sign">+</span>
                &nbsp;PREMIUM TRAFFIC
                  </div>
                  <div className="guidelines-container">Guidelines here!</div>
                </PushgroundPlusBanner>
                <Text>
                  What is Pushground+?.&nbsp;
                  <button
                    type="button"
                    className="pushground-plus-info-bttn"
                    onClick={() => setOpenPushgroundPlusDialog(true)}
                  >
                    <Text>Click here</Text>
                  </button>
                </Text>
                {getOverspendingBanner('Classic')}
                <StyledPushgroundPlusDialog
                  open={openPushgroundPlusDialog}
                  onClose={() => setOpenPushgroundPlusDialog(false)}
                >
                  <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text className="dialog-title">What is Pushground +?</Text>
                      <IconButton onClick={() => setOpenPushgroundPlusDialog(false)}>
                        <StyledCloseIcon />
                      </IconButton>
                    </div>
                    <Text className="dialog-subtitle">* Only available for mainstream traffic</Text>
                    <ul>
                      <li>
                        <Text>
                          Run your ads in brand-exclusive subscribers across our premium publishers.
                        </Text>
                      </li>
                      <li>
                        <Text>
                          Performance is usually higher but requires a higher CPC.
                          Check CPC rates&nbsp;
                          <a href="https://app.pushground.com/utils/cpcrates" target="_blank" rel="noreferrer">here</a>
                          .
                        </Text>
                      </li>
                      <li>
                        <Text>
                          Pushground+ Publishers have a much more strict moderation,
                          read the guidelines&nbsp;
                          <a href="http://help.pushground.com/en/articles/6318624-restrictions-for-pushground-campaigns" target="_blank" rel="noreferrer">here</a>
                          .
                        </Text>
                      </li>
                      <li>
                        <Text>
                          Pushground+ Publishers are not the best suited for marketers with
                          extensive scale goals.
                        </Text>
                      </li>
                    </ul>
                  </DialogContent>
                  <Divider />
                  <PushgroundPlusDialogBttn
                    onClick={() => {
                      if (selectedTrafficType.adult) {
                        return;
                      }

                      setOpenPushgroundPlusDialog(false);
                      setTimeout(() => setIsPushgroundPlusActive((prevState) => !prevState), 200);
                    }}
                    isDisabled={selectedTrafficType.adult}
                  >
                    {isPushgroundPlusActive ? 'Deactivate' : 'Activate'}
                  </PushgroundPlusDialogBttn>
                </StyledPushgroundPlusDialog>
              </>
            )}
          </div>
          <img src="/assets/ad-format-imgs/classic-push.svg" alt="Classic Push" />
        </AdFormatOptionWrapper>
        <AdFormatOptionWrapper onClick={() => setAdFormat('inPage')} isSelected={adFormat === 'inPage'}>
          <div className="ad-format-option-container">
            <div className="button-and-explanation">
              <label htmlFor="inPage">
                <input
                  type="radio"
                  id="inPage"
                  value="inPage"
                  checked={adFormat === 'inPage'}
                  readOnly
                />
                In-Page Push
              </label>
              <Text>
                Similar to Classic Push Notifications but is sent while a user is visiting a
                website. IOS available.
              </Text>
            </div>
            {adFormat === 'inPage' && getOverspendingBanner('In-Page')}
          </div>
          <img src="/assets/ad-format-imgs/inpage-push.svg" alt="In-Page Push" />
        </AdFormatOptionWrapper>
      </FormSectionCard>
      <FormSectionCard id="targeting">
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Targeting
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Here is where you can narrow down your audience. Make sure to double-check that everything here is correct." url="http://help.pushground.com/en/articles/4100992-targeting" />
        </FormSectionTitleContainer>
        <Divider />
        <Text style={{ marginBottom: 5 }}>Countries*:</Text>
        <ErrorMessage show={Object.values(isCountryError).some(Boolean)}>
          {isCountryError.isEmpty && '\'Country\' is required. '}
          {isCountryError.isUserNotGoldAndMultiSelect && (
            <>
              Only those with an&nbsp;
              <a href="http://help.pushground.com/en/articles/5190616-account-ranks" target="_blank" rel="noreferrer">account rank</a>
              &nbsp;of gold or above can set multiple countries per campaign.
            </>
          )}
        </ErrorMessage>
        <StyledSelect
          value={country}
          onChange={(event) => {
            setCountry(event! as Array<{ value: string; label: string; id: string; }>);
            setIsCountryError({ isEmpty: !(event as Array<{ value: string; label: string; }>).length, isUserNotGoldAndMultiSelect: userRank !== 'gold' && (event as Array<{ value: string; label: string; }>).length > 1 });

            if (!(event as Array<{ value: string; label: string; }>).length) {
              setCarrier([]);
              setRegion([]);
              setCity([]);
              setLanguage([]);
            }
          }}
          onBlur={() => setIsCountryError({ isEmpty: !country.length, isUserNotGoldAndMultiSelect: userRank !== 'gold' && country.length > 1 })}
          isSearchable
          options={formData?.countries ? formatFormData(formData.countries) : [] as any}
          isMulti
          isClearable
          placeholder="Select Countries"
        />
        <Text style={{ margin: '15px 0 5px' }}>Carrier:</Text>
        <StyledSelect
          value={carrier}
          onChange={(event) => {
            setCarrier(event! as Array<{ value: string; label: string; }>);
          }}
          isSearchable
          options={formData?.carriers
            ? formatFormData(
              formData.carriers.filter(({ parentId }: { parentId: string }) => country.find(
                ({ id }) => id === parentId,
              )),
            )
            : [] as any}
          isMulti
          isBlueMultivalue
          isClearable
          placeholder="--- All Carriers by Default ---"
          isDisabled={!country?.length}
        />
        <Text style={{ margin: '15px 0 5px' }}>Network Type:</Text>
        <StyledSelect
          value={networkType}
          onChange={(event) => setNetworkType(event! as Array<{ value: string; label: string; }>)}
          isSearchable
          options={formData?.networkTypes ? formatFormData(formData.networkTypes) : [] as any}
          isMulti
          isBlueMultivalue
          isClearable
          placeholder="--- All Networks by Default ---"
        />
        <Text style={{ margin: '15px 0 5px' }}>Device:</Text>
        <StyledSelect
          value={device}
          onChange={(event) => {
            setDevice(event! as Array<{ value: string; label: string; }>);

            if (!(event as Array<{ value: string; label: string; }>).length) {
              setOS([]);
            }
          }}
          isSearchable
          options={formData?.deviceOsVersionsTuples
            ? formatFormData(formData.deviceOsVersionsTuples) : [] as any}
          isMulti
          isBlueMultivalue
          isClearable
          placeholder="--- All Devices by Default ---"
        />
        <Suggestion>
          <img src="/assets/suggestion.svg" alt="Suggestion icon" />
          <Separator />
          <div>
            <span className="title"><strong>Suggestion: </strong></span>
            <strong>Split test campaigns when targeting different devices.&nbsp;</strong>
            Performance and CPA can be very different
          </div>
        </Suggestion>
        <Text style={{ marginBottom: 5 }}>OS:</Text>
        <StyledSelect
          value={OS}
          onChange={(event) => setOS(event! as Array<{ value: string; label: string; }>)}
          isSearchable
          options={OSOptions}
          isMulti
          isBlueMultivalue
          isClearable
          placeholder="--- All OS by Default ---"
          isDisabled={!device?.length}
        />
        <Text style={{ margin: '15px 0 5px' }}>Browsers:</Text>
        <FlexContainer style={{ gap: 15 }}>
          <div>
            <AlternateBttn selected={includebrowsers} onClick={() => setIncludebrowsers(true)} position="left">Include</AlternateBttn>
            <AlternateBttn selected={!includebrowsers} onClick={() => setIncludebrowsers(false)}>
              Exclude
            </AlternateBttn>
          </div>
          <StyledSelect
            value={browser}
            onChange={(event) => setBrowser(event! as Array<{ value: string; label: string; }>)}
            isSearchable
            options={formData?.browsers ? formatFormData(formData.browsers) : [] as any}
            isMulti
            isBlueMultivalue
            isExclude={!includebrowsers}
            isClearable
            placeholder="--- All Browsers by Default ---"
          />
        </FlexContainer>
        <AdvancedTargetingBttn onClick={() => setShowAdvancedTargeting((prevValue) => !prevValue)}>
          <Text>
            {showAdvancedTargeting ? 'Hide Advanced Targeting' : 'Show Advanced Targeting'}
          </Text>
        </AdvancedTargetingBttn>
        {

showAdvancedTargeting && (
<AdvancedTargetingWrapper height={showAdvancedTargeting ? 'fit-content' : '0px'}>
  <FormSectionTitleContainer>
    <FormSectionTitle>
      Advanced Targeting
    </FormSectionTitle>
  </FormSectionTitleContainer>
  <Divider />
  {country.length < 2 && (
  <>
    <Text style={{ margin: '15px 0 5px' }}>Regions:</Text>
    <FlexContainer style={{ gap: 15 }}>
      <div>
        <AlternateBttn selected={includeRegions} onClick={() => setIncludeRegions(true)} position="left">Include</AlternateBttn>
        <AlternateBttn selected={!includeRegions} onClick={() => setIncludeRegions(false)}>
          Exclude
        </AlternateBttn>
      </div>
      <StyledSelect
        value={region}
        onChange={(event) => setRegion(event! as Array<{ value: string; label: string; }>)}
        isSearchable
        options={regions && !(fetchingRegions || loadingRegions)
          ? formatFormData(regions) : [] as any}
        isMulti
        isBlueMultivalue
        isExclude={!includeRegions}
        isClearable
        isLoading={fetchingRegions || loadingRegions}
        placeholder="--- US or India --- All by Default ---"
        isDisabled={!country?.some(({ value }) => ['US', 'IN'].includes(value))}
      />
    </FlexContainer>
    <Text style={{ margin: '15px 0 5px' }}>Cities:</Text>
    <FlexContainer style={{ gap: 15 }}>
      <div>
        <AlternateBttn selected={includeCities} onClick={() => setIncludeCities(true)} position="left">Include</AlternateBttn>
        <AlternateBttn selected={!includeCities} onClick={() => setIncludeCities(false)}>
          Exclude
        </AlternateBttn>
      </div>
      <StyledSelect
        value={city}
        onChange={(event) => setCity(event! as Array<{ value: string; label: string; }>)}
        isSearchable
        options={cities && !(isFetching || isLoading) ? formatFormData(cities) : [] as any}
        isLoading={isFetching || isLoading}
        onFocus={() => {
          if (!city?.length) {
            getCities(country[0].id);
          }
        }}
        isMulti
        isBlueMultivalue
        isExclude={!includeCities}
        isClearable
        placeholder="--- All Cities by Default ---"
        isDisabled={!country?.length}
      />
    </FlexContainer>
  </>
  )}
  <Text style={{ margin: '15px 0 5px' }}>Languages:</Text>
  <FlexContainer style={{ gap: 15 }}>
    <div>
      <AlternateBttn selected={includeLanguages} onClick={() => setIncludeLanguages(true)} position="left">Include</AlternateBttn>
      <AlternateBttn
        selected={!includeLanguages}
        onClick={() => setIncludeLanguages(false)}
      >
        Exclude
      </AlternateBttn>
    </div>
    <StyledSelect
      value={language}
      onChange={(event) => setLanguage(event! as Array<{ value: string; label: string; }>)}
      isSearchable
      options={formData?.languages ? formatFormData(formData.languages) : [] as any}
      isMulti
      isBlueMultivalue
      isExclude={!includeLanguages}
      isClearable
      placeholder="--- All Languages by Default ---"
      isDisabled={!country?.length}
    />
  </FlexContainer>
  <Text style={{ margin: '15px 0 5px' }}>Android Versions:</Text>
  <FlexContainer style={{ gap: 15 }}>
    <div>
      <AlternateBttn selected={includeAndroidVersions} onClick={() => setIncludeAndroidVersions(true)} position="left">Include</AlternateBttn>
      <AlternateBttn
        selected={!includeAndroidVersions}
        onClick={() => setIncludeAndroidVersions(false)}
      >
        Exclude
      </AlternateBttn>
    </div>
    <StyledSelect
      value={androidVersion}
      onChange={(event) => setAndroidVersion(
                event! as Array<{ value: string; label: string; }>,
      )}
      isSearchable
      options={formData?.deviceOsVersionsTuples ? formatFormData(formData?.deviceOsVersionsTuples?.[2]?.childs.find(({ id }: any) => id === 'Android')?.childs || []) : [] as any}
      isMulti
      isBlueMultivalue
      isExclude={!includeAndroidVersions}
      isClearable
      placeholder="--- All Android Versions by Default ---"
      isDisabled={!OS?.some(({ value }) => value === 'Android')}
    />
  </FlexContainer>
  <Text style={{ margin: '15px 0 5px' }}>Windows Versions:</Text>
  <FlexContainer style={{ gap: 15 }}>
    <div>
      <AlternateBttn selected={includeWindowsVersions} onClick={() => setIncludeWindowsVersions(true)} position="left">Include</AlternateBttn>
      <AlternateBttn
        selected={!includeWindowsVersions}
        onClick={() => setIncludeWindowsVersions(false)}
      >
        Exclude
      </AlternateBttn>
    </div>
    <StyledSelect
      value={windowsVersion}
      onChange={(event) => setWindowsVersion(
        event! as Array<{ value: string; label: string; }>,
      )}
      isSearchable
      options={formData?.deviceOsVersionsTuples ? formatFormData(formData?.deviceOsVersionsTuples?.[0]?.childs.find(({ id }: any) => id === 'Windows')?.childs || []) : [] as any}
      isMulti
      isBlueMultivalue
      isExclude={!includeWindowsVersions}
      isClearable
      placeholder="--- All Windows Versions by Default ---"
      isDisabled={!OS?.some(({ value }) => value.includes('Windows'))}
    />
  </FlexContainer>
  <Text style={{ margin: '15px 0 5px' }}>Device Names:</Text>
  <FlexContainer style={{ gap: 15 }}>
    <div>
      <AlternateBttn selected={includeDeviceNames} onClick={() => setIncludeDeviceNames(true)} position="left">Include</AlternateBttn>
      <AlternateBttn
        selected={!includeDeviceNames}
        onClick={() => setIncludeDeviceNames(false)}
      >
        Exclude
      </AlternateBttn>
    </div>
    <StyledSelect
      value={deviceName}
      onChange={(event) => setDeviceName(
                event! as Array<{ value: string; label: string; }>,
      )}
      isSearchable
      options={formData?.deviceNames ? formatFormData(formData.deviceNames) : [] as any}
      isMulti
      isBlueMultivalue
      isExclude={!includeDeviceNames}
      isClearable
      placeholder="--- All Devices by Default ---"
      isDisabled={!OS?.some(({ value }) => value === 'Android')}
    />
  </FlexContainer>
  <ActiveHoursContainer>
    <Text>Active Hours in a Week</Text>
    <SmallText>
      {'By default all hours are active. Unselect the hours that you don\'t want to send push notifications.'}
    </SmallText>
    <ActionsWrapper>
      <Action onClick={() => toggleAll(true)}>All</Action>
      <Action onClick={() => toggleAll(false)}>None</Action>
      <Action onClick={toggleWorkDays}>Work days</Action>
      <Action onClick={toggleWeekend}>Weekend</Action>
      <Action onClick={toggleDayTime}>Day time</Action>
      <Action onClick={toggleNightTime}>Night time</Action>
    </ActionsWrapper>
    <Table cellSpacing="0">
      <thead>
        <tr>
          <th
            style={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={() => toggleAll(
              !daysInWeek.some((d) => activeHoursInWeek[d].some((h: any) => h)),
            )}
          >
            <span>Day</span>
            <span>\</span>
            <span>Hour</span>
          </th>
          {
              hoursInDay.map((hour) => <th key={hour} onClick={() => updateHour(hour)}>{hour}</th>)
            }
        </tr>
      </thead>
      <tbody>
        {
            daysInWeek.map((day, i) => (
              <tr key={day}>
                <td onClick={() => updateDay(day)}>{daysInWeekString[i]}</td>
                {
                  hoursInDay.map((hour) => (
                    <td
                      key={`${day}-${hour}`}
                      onClick={() => updateMap(day, hour)}
                    >
                      <input
                        type="checkbox"
                        checked={activeHoursInWeek[day]
                          ? activeHoursInWeek[day].includes(hour) : false}
                        onChange={() => updateMap(day, hour)}
                        name={`activeHoursInWeek[${day}][]`}
                        value={hour}
                      />
                    </td>
                  ))
                }
              </tr>
            ))
          }
      </tbody>
    </Table>
    <Text>Timezone:</Text>
    <StyledSelect
      value={timeZone}
      onChange={(event) => setTimeZone(event! as { value: string; label: string; })}
      isSearchable
      options={formData?.timezones ? formatFormData(formData.timezones) : [] as any}
    />
  </ActiveHoursContainer>
  <Text style={{ marginBottom: 5 }}>IP White List:</Text>
  <ListTextarea
    value={listTextareas.targetingWhite}
    onChange={(e) => setListTextareas(
      (preValue) => ({ ...preValue, targetingWhite: e.target.value }),
    )}
    placeholder="Ex: 192.169.1.3,192.162.3.4,192.168.0.1/0,192.168.0.1/1,192.168.0.1/2,192.168.0.1/3"
    style={{ margin: '-2px 0 -12px' }}
  />
  <ExtraInfo>
    Only IPv4 address or valid IPv4 CIDR seperated by comma.
    See the example in the placeholder.
  </ExtraInfo>
</AdvancedTargetingWrapper>
)
        }
      </FormSectionCard>
      {/* <FormSectionCard>
        <FormSectionTitle>
          Targeting Audiences
        </FormSectionTitle>
        <Divider />
        <ExtraInfo>
          Targeting Audiences will allow you to reach users that already engaged with your website
          before. Note that it will considerably narrow your target and therefore limit your reach,
          so we suggest to use it only for specific campaigns with higher bid. You can manage your
          audiences
          {' '}
          <a href="/audiences" target="_blank" rel="noreferrer">here</a>
          . Learn more
          {' '}
          <a href="http://help.pushground.com/en/articles/5819064-audience-retargeting" target="_blank" rel="noreferrer">here</a>
          .
        </ExtraInfo>
      </FormSectionCard> */}
      {adFormat !== 'inPage' && (
        <FormSectionCard id="user-freshness">
          <FormSectionTitleContainer>
            <FormSectionTitle>
              User Freshness
            </FormSectionTitle>
            <SectionTitleHelpTooltip tooltipText="User freshness (only available for Push notifications) measure the amount of since the users first subscribed to receive push ads. Fresher groups normally convert better but limit your reach." url="http://help.pushground.com/en/articles/3568516-user-freshness-groups" />
          </FormSectionTitleContainer>
          <Divider />
          <Text>User Freshness Group</Text>
          <SmallText>Time passed since the users allowed to receive push notifications.</SmallText>
          <UserFreshnessBttn onClick={() => setSelectedUserFreshness(
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          )}
          >
            Select All
          </UserFreshnessBttn>
          <UserFreshnessBttn onClick={() => setSelectedUserFreshness([])}>Clean</UserFreshnessBttn>
          <UserFreshnessOptionsContainer>
            {userFreshnessOptions.map((userFreshness, index) => (
              <StyledLabel key={index}>
                <StyledCheckbox
                  checked={selectedUserFreshness.includes(index)}
                  onChange={() => {
                    if (selectedUserFreshness.includes(index)) {
                      setSelectedUserFreshness(
                        (prevState) => prevState.filter((value) => value !== index),
                      );
                    } else {
                      setSelectedUserFreshness((prevState) => prevState.concat(index));
                    }
                  }}
                />
                {userFreshness}
              </StyledLabel>
            ))}
          </UserFreshnessOptionsContainer>
          <SmallText>If none are selected, then by default all groups with be targeted.</SmallText>
        </FormSectionCard>
      )}
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Frequency / Capping
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Prohibit campaign from sending more than the given number of impressions to the same IP in a 24h period. Enabling this can greatly decrease your reach." url="http://help.pushground.com/en/articles/4101125-frequency-capping" />
        </FormSectionTitleContainer>
        <Divider />
        <Text>Ip by Impressions:</Text>
        <FrequencyRadioBttnsContainer>
          {Object.keys(selectedFrequency).map((frequency) => (
            <Fragment key={frequency}>
              <input
                type="radio"
                value={frequency}
                checked={selectedFrequency[frequency]}
                onChange={() => {
                  setSelectedFrequency({
                    '1:24h': false, '2:24h': false, '3:24h': false, none: false, [frequency]: true,
                  });
                }}
              />
              {' '}
              <Frequency>{frequency}</Frequency>
            </Fragment>
          ))}
        </FrequencyRadioBttnsContainer>
        <SmallText>
          3:24h means that the system is only going to deliver your ad
          3 times to the same IP during 24h.
        </SmallText>
      </FormSectionCard>
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Whitelists and Blacklists
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="These allow you to narrow down your audience by only including (Whitelist) or excluding (Blacklist) certain groups of sources that you find to be better or worse for your offers." url=" http://help.pushground.com/en/articles/5819725-whitelisting-and-blacklisting-supplyids-siteids" />
        </FormSectionTitleContainer>
        <Divider />
        <ListTitle>BULK WHITELISTS</ListTitle>
        <ListSectionContainer>
          <TextareaContainer>
            <Text>{'{supply_id} Whitelist'}</Text>
            <ListTextarea
              value={listTextareas.supplyWhite}
              onChange={(e) => setListTextareas(
                (preValue) => ({ ...preValue, supplyWhite: e.target.value }),
              )}
            />
            <SmallText>Comma separated, ex: 12,45</SmallText>
          </TextareaContainer>
          <TextareaContainer>
            <Text>{'{site_id} Whitelist'}</Text>
            <ListTextarea
              value={listTextareas.siteWhite}
              onChange={(e) => setListTextareas(
                (preValue) => ({ ...preValue, siteWhite: e.target.value }),
              )}
            />
            <SmallText>Comma separated, ex: 23-4353,2-32434</SmallText>
          </TextareaContainer>
        </ListSectionContainer>
        <ListTitle>BULK BLACKLISTS</ListTitle>
        <ListSectionContainer>
          <TextareaContainer>
            <Text>{'{supply_id} Blacklist'}</Text>
            <ListTextarea
              value={listTextareas.supplyBlack}
              onChange={(e) => setListTextareas(
                (preValue) => ({ ...preValue, supplyBlack: e.target.value }),
              )}
            />
            <SmallText>Comma separated, ex: 12,45</SmallText>
          </TextareaContainer>
          <TextareaContainer>
            <Text>{'{site_id} Blacklist'}</Text>
            <ListTextarea
              value={listTextareas.siteBlack}
              onChange={(e) => setListTextareas(
                (preValue) => ({ ...preValue, siteBlack: e.target.value }),
              )}
            />
            <SmallText>Comma separated, ex: 23-4353,2-32434</SmallText>
          </TextareaContainer>
        </ListSectionContainer>
      </FormSectionCard>
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle id="bid">
            Bid & Budget
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Check the average CPC rate of your Geo and set your bid accordingly. We recommend that you adjust often because the averages fluctuate a lot." url="http://help.pushground.com/en/articles/4102978-bid-budget" />
        </FormSectionTitleContainer>
        <Divider />
        <BidBudgetElement>
          <Text>CPC Bid</Text>
          <ErrorMessage show={Object.values(bidBudget.isBidError).includes(true)}>
            {bidBudget.isBidError.numberOfDecimals && '"Bid" must have no more than 4 decimal places.'}
            {bidBudget.isBidError.underMin && '"Bid" must be greater than or equal to 0.001.'}
          </ErrorMessage>
          <StyledInput
            value={bidBudget.bid}
            onChange={(event) => {
              if (/^\d+(\.*)(\d*)$/.test(event.target.value) || event.target.value === '') {
                setBidBudget((prevValue) => ({
                  ...prevValue,
                  bid: event.target.value,
                  isBidError: {
                    numberOfDecimals: !!(event.target.value.split('.')[1]?.length > 4),
                    underMin: event.target.value < '0.001',
                  },
                }));
              }
            }}
            isError={Object.values(bidBudget.isBidError).some(Boolean)}
          />
          <div>
            <MinBidBttn onClick={(() => setBidBudget((prevValue) => ({ ...prevValue, bid: '0.001' })))} active={bidBudget.bid !== '0.001'}>
              Min Bid: $0.001
            </MinBidBttn>
          </div>
          <SmallText>Check our platform rates by format:</SmallText>
          <SmallText>
            <CPCRatesDialogTrigger onClick={() => {
              setCPCRatesDialogData({ title: 'Push Notification CPC Rates', tableContent: [{ country: 'AF', recBid: '$0.0920' }, { country: 'AG', recBid: '$0.0036' }] });
              setOpenCPCRatesDialog(true);
            }}
            >
              Push
            </CPCRatesDialogTrigger>
            {', '}
            <CPCRatesDialogTrigger onClick={() => {
              setCPCRatesDialogData({ title: 'InPage Push Notification CPC Rates', tableContent: [{ country: 'AD', recBid: '$0.0036' }, { country: 'AE', recBid: '$0.0406' }] });
              setOpenCPCRatesDialog(true);
            }}
            >
              In-Page Push
            </CPCRatesDialogTrigger>
          </SmallText>
          <CPCRatesDialog
            openDialog={openCPCRatesDialog}
            setOpenDialog={setOpenCPCRatesDialog}
            title={CPCRatesDialogData.title}
            tableContent={CPCRatesDialogData.tableContent}
          />
        </BidBudgetElement>
        <BidBudgetElement id="budget">
          <Text style={{ marginBottom: 5 }}>Daily Budget</Text>
          <ErrorMessage show={bidBudget.isBudgetError}>
            &quot;Daily Budget&quot; must be greater than or equal to 20
          </ErrorMessage>
          <StyledInput
            value={bidBudget.budget}
            onChange={(event) => {
              if (/^\d+(\.*)(\d*)$/.test(event.target.value) || event.target.value === '') {
                setBidBudget((prevValue) => ({
                  ...prevValue,
                  budget: event.target.value,
                  isBudgetError: Number(event.target.value) < 20,
                }));
              }
            }}
            isError={bidBudget.isBudgetError}
          />
          <ExtraInfo>Your daily budget. MIN: $20</ExtraInfo>
        </BidBudgetElement>
        <Text>Budget Distribution:</Text>
        <DistributionBttn selected={budgetDistribution === 'accelerated'} onClick={() => setBudgetDistribution('accelerated')} position="left">Accelerated</DistributionBttn>
        <DistributionBttn selected={budgetDistribution === 'smooth'} onClick={() => setBudgetDistribution('smooth')}>Smooth</DistributionBttn>
        <ExtraInfo>
          Control how fast you spend your campaign budget.
          As fast as possible or smoothly distributed.
        </ExtraInfo>
      </FormSectionCard>
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Creativities
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="You can create up to 10 different creatives. We recommend that you make at least 3 to compare their performances and replace or change them every 3-5 days." url="http://help.pushground.com/en/articles/4100366-creating-creativities" />
        </FormSectionTitleContainer>
        <Divider />
        <Creativities />
      </FormSectionCard>
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Auto-Optimization by creativity
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Enabling this will make your best performing ads be shown more. We recommend this to save you time optimizing and quickly finding your best creatives. *Only enable if you are sending the conversions through postback." url="http://help.pushground.com/en/articles/3545346-auto-optimization-by-creativity" />
        </FormSectionTitleContainer>
        <Divider />
        <StyledLabel>
          <StyledCheckbox checked={isAutoOptimized} onChange={handleAutoOptimizedCbox} />
          Direct traffic to best performing creatives
        </StyledLabel>
        <SmallText>The AI optimization works correctly only if you track conversions.</SmallText>
      </FormSectionCard>
      <FormSectionCard>
        <FormSectionTitleContainer>
          <FormSectionTitle>
            Automatic Rules
          </FormSectionTitle>
          <SectionTitleHelpTooltip tooltipText="Create your own optimization algorithm using our new automatic rules.  Forget about the constant manual optimization and set back and relax as your rules do it for you." url="http://help.pushground.com/en/articles/3529940-automatic-rules" />
        </FormSectionTitleContainer>
        <Divider />
        <Text>
          Use custom optimization rules to effortlessly manage your campaigns.
          Create rules that can automatically check and change your campaigns.
          <br />
          Learn more
          {' '}
          <a href="http://help.pushground.com/en/articles/3529940-automatic-rules" target="_blank" rel="noreferrer">here</a>
        </Text>
        <AutomaticRules />
      </FormSectionCard>
      <CampaignSummaryContainer>
        <CampaignSummaryTitle>
          Campaign Summary
          <CampaignNameSummary>
            {campaignName}
          </CampaignNameSummary>
        </CampaignSummaryTitle>
        <Divider style={{ marginBottom: 0 }} />
        <CampaignSummaryData>
          <div style={{ cursor: 'pointer' }} onClick={() => handleClickScroll('ad-format')}>
            <SummarySubtitle>Formats</SummarySubtitle>
            <SummaryInfo>{adFormat === 'classic' ? 'Classic Push' : 'In-Page Push'}</SummaryInfo>
          </div>
          <div style={{ cursor: 'pointer', marginTop: 20 }} onClick={() => handleClickScroll('targeting')}>
            <SummarySubtitle style={{ color: Object.values(isCountryError).some(Boolean) ? 'red' : undefined }}>Targeting</SummarySubtitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 9 }} onClick={() => handleClickScroll('targeting')}>
              {summaryTargeting?.flat().length
                ? summaryTargeting : <SummaryInfo className="choose-targeting">Choose a targeting</SummaryInfo>}
            </div>
            <div data-tip data-for="trafficReachInfo">
              <HelpTooltip
                id="trafficReachInfo"
                effect="solid"
                place="left"
              >
                The percent of our traffic that you can reach with your current targeting.
                It is calculated from carrier, traffic type, campaign reach, network, device,
                and user freshness targeting with 100% of the traffic being that maximum amount
                of traffic for the given format and geo. This does not take into account your
                current bid.
                {' '}
                <a href="http://help.pushground.com/en/articles/5013664-traffic-reached" target="_blank" rel="noreferrer">Learn more</a>
                .
              </HelpTooltip>
              <Bar width={targetingReach || 0}>
                <div />
              </Bar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaInfoCircle
                  color="#6C757D"
                  style={{
                    cursor: 'pointer',
                    marginRight: 5,
                  }}
                />
                {
        targetingReach && !Number.isNaN(targetingReach)
          ? (
            <SmallText as="span">
              Your targeting reaches
              {' '}
              <strong>
                {parseInt(targetingReach, 10).toFixed(0)}
                %
              </strong>
              {' '}
              of the traffic.
            </SmallText>
          )
          : <SmallText as="span">Insufficient data.</SmallText>
      }
              </div>
            </div>
          </div>
          <div style={{ cursor: 'pointer', marginTop: 20 }} onClick={() => handleClickScroll('traffic-type')}>
            <SummarySubtitle>Traffic Type</SummarySubtitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 9 }}>
              {Object.entries(selectedTrafficType).map(([key, value]) => (
                value && <SummaryInfo key={key} className="capitalize">{key}</SummaryInfo>
              ))}
              {isPushgroundPlusActive && <SummaryInfo className="pushground-plus">Pushground +</SummaryInfo>}
            </div>
          </div>
          {adFormat === 'classic' && (
            <div style={{ cursor: 'pointer', marginTop: 20 }} onClick={() => handleClickScroll('user-freshness')}>
              <SummarySubtitle>User Freshness</SummarySubtitle>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 9 }}>
                {getUserFreshnessSummary()}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', marginTop: 15, marginBottom: 25 }}>
            <div
              style={{
                flex: 1, cursor: 'pointer', overflow: 'hidden', color: Object.values(bidBudget.isBidError).some(Boolean) ? 'red' : undefined,
              }}
              onClick={() => handleClickScroll('bid')}
            >
              <SummarySubtitle>Bid</SummarySubtitle>
              <div>
                $
                {bidBudget.bid}
              </div>
            </div>
            <div
              style={{
                flex: 1, cursor: 'pointer', overflow: 'hidden', color: bidBudget.isBudgetError ? 'red' : undefined,
              }}
              onClick={() => handleClickScroll('budget')}
            >
              <SummarySubtitle>Daily Budget</SummarySubtitle>
              <div>
                $
                {bidBudget.budget}
              </div>
            </div>
          </div>
        </CampaignSummaryData>
        <PublishCampaignBttnContainer>
          <SummarySubtitle>Status</SummarySubtitle>
          <FlexContainer>
            <input
              type="radio"
              value="active"
              checked={selectedPublishStatus === 'active'}
              onChange={() => setSelectedPublishStatus('active')}
            />
            &nbsp;
            <Text style={{ cursor: 'pointer' }} onClick={() => setSelectedPublishStatus('active')}>Active</Text>
            &nbsp;&nbsp;
            <input
              type="radio"
              value="paused"
              checked={selectedPublishStatus === 'paused'}
              onChange={() => {
                setSelectedPublishStatus('paused');
              }}
            />
            &nbsp;
            <Text style={{ cursor: 'pointer' }} onClick={() => setSelectedPublishStatus('paused')}>Paused</Text>
          </FlexContainer>
          <PublishCampaignBttn onClick={() => publishCampaign()}>
            <Text>{selectedPublishStatus === 'active' ? 'Publish Campaign' : 'Save as paused'}</Text>
          </PublishCampaignBttn>
        </PublishCampaignBttnContainer>
      </CampaignSummaryContainer>
    </SectionWrapper>
  );
}

export default CreateCampaign;
