import { Row, Col } from 'react-bootstrap';
import {
  Form, Fieldset, FieldsetTitle, SmallMsg, SubmitButton, Message,
} from './styles/GlobalBlacklist.styled.js';
import Input from './Input.jsx';
import { updateSettings } from '../redux/userSettingsSlice';
import { useAppSelector } from '../../../app/hooks';

function GlobalBlacklist() {
  const { supply: supplyBL = '', source: sourceBL = '' } = useAppSelector(({ userSettingsSlice: { blacklist } }) => blacklist);

  return (
    <Fieldset noMargin>
      <FieldsetTitle fontSize={22}>Global Blacklist</FieldsetTitle>
      <Message style={{ marginBottom: 15 }}>
        Block specific supplies and/or sites from all the campaigns of your account.
      </Message>
      <Form>
        <Row>
          <Col>
            <Input
              type="textarea"
              label="{supply_id} Blacklist"
              name="supply_feed_id_blacklist"
              value={supplyBL}
              onChange={(event) => updateSettings({ blacklist: { supply: event.target.value } })}
            >
              <SmallMsg>Comma separated, ex: 12,45</SmallMsg>
            </Input>
          </Col>
          <Col>
            <Input
              type="textarea"
              label="{site_id} Blacklist"
              name="source_blacklist"
              value={sourceBL}
              onChange={(event) => updateSettings({ blacklist: { source: event.target.value } })}
            >
              <SmallMsg>Comma separated, ex: 23-4353,2-32434</SmallMsg>
            </Input>
          </Col>
        </Row>
        <SubmitButton>Save Global Blacklist</SubmitButton>
      </Form>
    </Fieldset>
  );
}

export default GlobalBlacklist;
