import styled from 'styled-components/macro';
import { Popover, OutlinedInput } from '@mui/material';

interface StyledPopoverProps {
    $isTablePopover: boolean;
}

export const StyledPopover = styled(Popover)<StyledPopoverProps>`
    .MuiPopover-paper {
        font-size: 14.4px;
        padding: 15px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    }
`;

export const PopoverTitle = styled.h3`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const BidBttns = styled.button`
    font-size: 19px;
    height: 40px;
    padding: 0 13px;
    background: #e6e6e6;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
`;

export const BidSubtractBttn = styled(BidBttns)`
    border-left: 1px solid #ced4da;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
`;

export const BidAddBttn = styled(BidBttns)`
    border-right: 1px solid #ced4da;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
`;

export const BidSubtitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 15px 0 3px 0;
`;

export const SetBttnContainer = styled.div`
    margin-top: 2px;
`;

interface StyledInputProps {
    width?: number;
    $isBidInput?: boolean;
    $isDisabled?: boolean;
}

export const StyledInput = styled(OutlinedInput)<StyledInputProps>`
    height: 40px;
    min-width: ${({ width }) => !width && 150}px;
    
    &&& {
        width: ${({ width }) => width && width}ch;
        border-radius: ${({ $isBidInput }) => ($isBidInput ? 0 : '10px')};
        background-color: ${({ $isDisabled }) => $isDisabled && '#e9ecef'};
    }
`;

export const BttnsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;

export const EditorCancelOrSetBttn = styled.button`
    font-size: 14.4px;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
`;

export const EditorSaveBttn = styled.button`
    font-size: 14.4px;
    font-weight: 500;
    color: #FFF;
    background-color: #3374f6;
    padding: 0.3rem 1rem;
    border-radius: 10px;
    transition: all 0.4s ease 0s;

    &:hover {
        color: #000;
        background-color: transparent;
        text-decoration: underline;
    }
`;
