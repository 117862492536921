import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Tokens from './components/Tokens.jsx';
import Profile from './components/Profile.jsx';
import Billing from './components/Billing.jsx';
import GlobalBlacklist from './components/GlobalBlacklist.jsx';
import Security from './components/Security.jsx';

const fieldsetStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 1.5rem)',
  margin: '15px 0',
};

function UserSettings() {
  return (
    <Container>
      <Row><Col><Tokens style={{ margin: '15px 0' }} /></Col></Row>
      <Row>
        <Col><Profile style={fieldsetStyles} /></Col>
        <Col><Billing style={fieldsetStyles} /></Col>
      </Row>
      <Row><Col><GlobalBlacklist /></Col></Row>
      <Security />
    </Container>
  );
}

export default UserSettings;
