import { pushgroundApi } from '../../../app/services/pushgroundApi';
import { userFreshnessOptions } from '../data/userFreshnessOptions';

export interface UIStatus {
  main: { id: string; label: string; };
  reason: { label: string };
}

export interface Campaign {
  [key: string]: unknown;
  id: number;
  name: string;
  active: boolean;
  uiStatus: UIStatus;
  targeting: { countries: Array<string>; formats: Array<string> };
  bid: number;
  dailyBudget: number;
  createdAt: string;
}

export type SupplyFeeds = { supplyFeedId: number; source?: string | null; bid?: number; };

interface SupplyFeedBidsResponse {
  supplyFeedBids: Array<SupplyFeeds>;
}

interface SupplyFeedBlacklistsResponse {
  supplyFeedBlacklists: Array<SupplyFeeds>;
}

export const campaignsApi = pushgroundApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignsList: builder.query<Array<Campaign>, void>({
      query: () => 'https://api.beta.pushground.com/advertisers/campaigns',
    }),
    getCampaign: builder.query({
      query: ({ campaignId }) => `advertisers/campaigns/${campaignId}`,
      transformResponse: (response: {
        targeting: { userAgeGroups: Array<number> }; isBrandsafe: boolean;
      }) => ({
        ...response,
        targeting: {
          ...response.targeting,
          userAgeGroups: response.targeting.userAgeGroups.map(
            (ageGroup) => userFreshnessOptions[ageGroup],
          ),
        },
      }),
    }),
    getCampaignsStats: builder.query({
      query: ({ body }) => ({
        url: 'stats/open',
        method: 'POST',
        body,
      }),
    }),
    updateCampaign: builder.mutation({
      query: ({ campaignId, body, method }) => ({
        url: `advertisers/campaigns/${campaignId}`,
        method,
        body,
      }),
    }),
    sendCampaignToModeration: builder.mutation({
      query: ({ campaignId }) => ({
        url: `v2/moderation/campaign/${campaignId}`,
        method: 'POST',
      }),
    }),
    supplyFeedBlacklist: builder.mutation({
      query: ({ isRemove, body }) => ({
        url: `advertisers/supplyFeeds/bl${isRemove ? '/remove' : ''}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SupplyFeedBlacklist'],
    }),
    getSupplyFeedBlacklist: builder.query({
      query: ({ campaignId }) => `advertisers/supplyFeeds/bl/${campaignId}`,
      transformResponse: (response: SupplyFeedBlacklistsResponse) => response.supplyFeedBlacklists,
      providesTags: ['SupplyFeedBlacklist'],
    }),
    getSupplyFeedBids: builder.query({
      query: ({ campaignId }) => `advertisers/supplyFeeds/bid/${campaignId}`,
      transformResponse: (response: SupplyFeedBidsResponse) => response.supplyFeedBids,
    }),
    getCreativities: builder.query({
      query: ({ campaignId }) => `advertisers/creativities/${campaignId}`,
      providesTags: ['DataDrawerCreativitiesList'],
    }),
    changeCreativityState: builder.mutation({
      query: ({ creativityId, body }) => ({
        url: `advertisers/creativities/${creativityId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['DataDrawerCreativitiesList'],
    }),
    getFormatsCountryRates: builder.query<object, void>({
      query: () => '/v2/lists/recommendedBid/rates',
      transformResponse: (response: { data: object }) => response.data,
    }),
  }),
});

export const {
  useLazyGetCampaignsListQuery,
  useLazyGetCampaignQuery,
  useLazyGetCampaignsStatsQuery,
  useUpdateCampaignMutation,
  useSendCampaignToModerationMutation,
  useSupplyFeedBlacklistMutation,
  useLazyGetSupplyFeedBlacklistQuery,
  useLazyGetSupplyFeedBidsQuery,
  useLazyGetCreativitiesQuery,
  useChangeCreativityStateMutation,
  useLazyGetFormatsCountryRatesQuery,
} = campaignsApi;
