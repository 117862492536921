import { createSlice } from '@reduxjs/toolkit';
import { loginApi } from './loginAPI';
import { resetAllSlicesState } from '../../common/utils/resetAllSlicesStateAction';

const apiKey = localStorage.getItem('apiKey');
const userRole = localStorage.getItem('userRole');

export interface User {
  name: string;
  role: string;
  rank: {
    current: string;
    progress: { current: string; next: string; min: number; value: number; max: number; }
    daysLeft: number;
  } | null;
  balance: {
    current: number;
    todaySpent: { value: number; variation: number; };
    weekSpent: { value: number; variation: number; };
    monthSpent: number;
    isRunningOutBalance: boolean;
  };
  timeZone: string;
}

interface InitialState {
  isUserAuthenticated: boolean;
  apiKey: string | null;
  user: User | null;
}

const initialState = {
  isUserAuthenticated: !!apiKey,
  apiKey,
  user: userRole && { role: userRole },
} as InitialState;

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetAllSlicesState, () => {
      ['cred', 'apiKey'].forEach((item) => localStorage.removeItem(item));
      return { isUserAuthenticated: false, apiKey: null, user: null };
    });
    builder.addMatcher(loginApi.endpoints.logInUser.matchFulfilled, (state, action) => {
      state.isUserAuthenticated = true;
      state.apiKey = action.payload.apiKey;

      localStorage.setItem('cred', action.meta.arg.originalArgs);
      localStorage.setItem('apiKey', action.payload.apiKey);
    });
    builder.addMatcher(loginApi.endpoints.getUserData.matchFulfilled, (state, action) => {
      state.user = action.payload;

      localStorage.setItem('userRole', action.payload.role);
    });
  },
});

export default loginSlice.reducer;
