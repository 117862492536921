const accountManager = [
  { option: 'All Account Managers', value: 0 },
  { option: 'Irina', value: 437 },
  { option: 'Luke', value: 111449 },
  { option: 'Clara', value: 112573 },
];

const platform = [
  { option: 'All platforms', value: '' },
  { option: 'Pushground', value: 'PG' },
  { option: 'Mobidea', value: 'MOBIDEA' },
];

const accountRank = [
  { option: 'All Account Ranks', value: '' },
  { option: 'Platinum', value: 'PLATINUM' },
  { option: 'Gold', value: 'GOLD' },
  { option: 'Silver', value: 'SILVER' },
  { option: 'Bronze', value: 'BRONZE' },
];

const campaignType = [
  { option: 'All campaigns', value: '' },
  { option: 'Pushground +', value: 'PUSHGROUND_PLUS' },
  { option: 'Normal', value: 'NORMAL' },
];

const timeOfCreation = [
  { option: 'Oldest', value: 'oldest' },
  { option: 'Newest', value: 'newest' },
];

interface FiltersSelectOptions {
    [key: string]: Array<{
        option: string;
        value: string | number;
    }>
}

const filtersSelectOptions: FiltersSelectOptions = {
  accountManager,
  platform,
  accountRank,
  campaignType,
  timeOfCreation,
};

export default filtersSelectOptions;
