import styled, { css } from 'styled-components/macro';
import { FaAngleUp, FaToggleOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { CreativityWrapper } from './DataDrawer';

interface StyledTableProps {
    isThereContent?: boolean;
    isDataDrawerTable?: boolean;
    firstColumnWidth?: number;
}

export const StyledTable = styled.table<StyledTableProps>`
    display: block;
    font-size: 13px;
    width: 100%;
    max-width: min-content;
    max-height: 715px;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    border: 1px solid #d9dde5;
    border-radius: 5px;
    border-collapse: collapse;

    .no-data > td {
        padding: 30px;
    }

    thead, tfoot {
        position: sticky;
        z-index: 2;
    }

    thead {
        top: 0;
        outline: 1px solid #e6e9f1;
    }

    tfoot {
        bottom: 0;
        outline: 1px solid #e6e9f1;
    }

    th {
        padding: 15px 17px;
    }

    td {
        padding: 7px 17px;
    }

    /* td:not(:first-child, :nth-child(2n), .name, .id) {
        width: 100%;
    } */

    th, td:not(.spinner) {
        border: 1px solid #e6e9f1;
    }

    th {
        border-top: 0px;
    }

    tbody tr:nth-child(odd):not(.spinner, .no-data) td {
        background-color: #f1f3f8;
    }
    
    th, tr:nth-child(even) td, tfoot td {
        background-color: #FFF;
    }

    tbody tr {
        height: 47px;
    }

    th.name {
        min-width: 250px;
    }

    th.id {
        min-width: 84px;
    }

    th:nth-child(1),
    th:nth-child(2),
    td:nth-child(1),
    td:nth-child(2) {
        position: sticky;
        outline: 1px solid #e6e9f1;
        border: none;
        z-index: 1;
    }

    ${({ isDataDrawerTable }) => !isDataDrawerTable && css`
        th:nth-child(3),
        th:nth-child(4),
        td:nth-child(3),
        td:nth-child(4) {
            position: sticky;
            outline: 1px solid #e6e9f1;
            border: none;
            z-index: 1;
        }
    `}


    td:nth-child(1),
    th:nth-child(1) {
        left: 0;
        text-align: center;
    }
    
    td:nth-child(2),
    th:nth-child(2) {
        left: ${({ isDataDrawerTable, firstColumnWidth }) => (isDataDrawerTable ? (firstColumnWidth || 78.14) : 67)}px;
        filter: ${({ isDataDrawerTable, isThereContent }) => isDataDrawerTable && isThereContent && 'drop-shadow(13px 10px 8px rgba(0, 0, 0, 0.1))'};
    }

    th:nth-child(2) {
        filter: ${({ isDataDrawerTable, isThereContent }) => isDataDrawerTable && isThereContent && 'drop-shadow(13px 0px 8px rgba(0, 0, 0, 0.1))'};
    }

    ${({ isDataDrawerTable, isThereContent }) => !isDataDrawerTable && css`
        td:nth-child(3),
        th:nth-child(3) {
            left: 145.14px;
        }

        td:nth-child(4),
        th:nth-child(4) {
            left: 395.14px;
            filter: ${isThereContent && 'drop-shadow(13px 10px 8px rgba(0, 0, 0, 0.1))'};
        }

        th:nth-child(4) {
            filter: ${isThereContent && 'drop-shadow(13px 0px 8px rgba(0, 0, 0, 0.1))'};
        }
    `}
    

    .bid-cell, .daily-budget-cell {
        button {
            visibility: hidden;
            position: relative;
            left: 3px;
        }
    }

    tr:hover {
        .bid-cell, .daily-budget-cell {
            button {
                visibility: visible;
            }
        }

        .campaign-name {
            padding: 0 17px; 
            
            div:nth-child(2) {
                display: contents;
            }
        }
    }

    .hidden-cell {
        display: none;
    }

    .campaign-name {
        div:nth-child(2) {
            display: none;
        }
    }

    ${({ theme: { hiddenScrollbarStyles } }) => hiddenScrollbarStyles};
`;

export const HeaderCell = styled.th<{isHidden: boolean}>`
    font-weight: 500;
    text-align: left;

    &.reorder-column {
        cursor: pointer;
    }

    ${({ isHidden }) => isHidden && 'display: none'}
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    min-width: 15px;
    height: 15px;

    &:td {
        position: relative;
        top: 2px;
    }
`;

interface TooltipProps {
    isStatus?: boolean;
    isWinRate?: boolean;
    isRecommendedBid?: boolean;
}

export const Tooltip = styled(ReactTooltip).attrs<TooltipProps>(({ place, isStatus }) => ({
  effect: 'solid',
  place: place || 'bottom',
  delayShow: 100,
  delayHide: isStatus ? 200 : 0,
  clickable: isStatus,
}))<TooltipProps>`
    ${({ isStatus, isRecommendedBid }) => (isStatus || isRecommendedBid) && css`
        max-width: 300px;
        white-space: normal;
    `};

    ${({ isWinRate }) => isWinRate && css`
        max-width: 480px;
    `};
  
    /* &.type-dark {
      background-color: rgba(0, 0, 0, .8);
      padding: 3px 5px;
      white-space: normal;
      
      a {
          color: inherit;
          text-decoration: underline;
        }
        
        * {
            font-family: inherit;
        }
    } */
`;

// ${(props) => props.css && css(props.css)};
export const Bar = styled.div`
  border-radius: 10px;
`;

export const ProgressBar = styled.div<{$width: number}>`
  width: 100%;
  height: 6px;
  background-color: #ccc;
  border-radius: 10px;

  ${Bar} {
    background-color: ${(props) => (props.$width > 90 ? '#dc3545' : props.$width > 60 ? '#ffc107' : '#007bff')};
    //background: linear-gradient(90deg, #119986 0%, #38EF7D 100%);
    width: ${(props) => props.$width}%;
    height: 100%;
  }
`;

export const HeaderCellArrow = styled(FaAngleUp).attrs({ size: 19 })<{$rotationDegrees: number}>`
    transform: rotate(${({ $rotationDegrees }) => $rotationDegrees}deg);
    margin-left: 4px;
`;

export const ViewDataOrEditBttn = styled.button`
    color: #007bff;
    padding: 0;

    &:hover span {
        text-decoration: underline;
    }
`;

export const StyledToggle = styled(FaToggleOff)`
    cursor: pointer;
`;
