export const colors = {
  oldBlue: '#0068FF',
  blue: '#3374F6',
  darkBlue: '#2657ba',
  blueShadow: 'rgba(10, 0, 116, .1)',
  red: '#D13E3D',
  buttonRed: '#ff0000',
  hoverRed: '#9f2e2d',
  darkGrey: '#989898',
  grey: '#666',
  lightGrey: '#F2F4F8',
  lightblue: '',
  green: '#00D895',
  black: '#1A1A1A',
};
