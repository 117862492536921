/* eslint-disable no-underscore-dangle */
const _URL = window.URL || window.webkitURL;

const checkImageSize = (url, cb, imageLimitation) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = function () {
    // console.log(this.width + 'x' + this.height)
    if (imageLimitation.minWidth && this.width < imageLimitation.minWidth) {
      reject(new Error(`Image is too narrow. Min width allowed: ${imageLimitation.minWidth}px`));
      // } else if (imageLimitation.maxWidth && this.width > imageLimitation.maxWidth) {
      //   reject(new Error(`Image is too wide. Max width allowed: ${imageLimitation.maxWidth}px`))
    } else if (imageLimitation.minHeight && this.height < imageLimitation.minHeight) {
      reject(new Error(`Image is too low. Min height allowed: ${imageLimitation.minHeight}px`));
      // } else if (imageLimitation.maxHeight && this.height > imageLimitation.maxHeight) {
      //   reject(new Error(`Image is too high. Ma
      // x height allowed: ${imageLimitation.maxHeight}px`))
      // } else if (imageLimitation.ratio && this.width / this.height !== imageLimitation.ratio) {
      //   reject(new Error(`Image does not c
      // umpliment aspect ratio expected of ${imageLimitation.ratio}`))
    }
    cb();
    resolve(true);
  };
  img.src = url;
});

export const validateFile = async (
  file,
  fileTypesAllowed,
  maxFileSizeInKb,
  imageLimitation,
  setErrorMessage,
) => {
  const fileSizeInKb = parseFloat(file.size) / 1024;
  const objectUrl = _URL.createObjectURL(file);

  if (fileTypesAllowed.length > 0 && !fileTypesAllowed.includes(file.type)) {
    setErrorMessage('File type not permitted');
    return false;
  } if (maxFileSizeInKb && maxFileSizeInKb < fileSizeInKb) {
    setErrorMessage(`File is too big, max size allowed is ${maxFileSizeInKb}KB`);
    return false;
  }
  try {
    await checkImageSize(objectUrl, () => _URL.revokeObjectURL(objectUrl), imageLimitation);
    return true;
  } catch (error) {
    setErrorMessage(error.message);
    return false;
  }
};

export const convertImgToBase64 = (
  url,
  callback,
  outputFormat,
  onError,
  imageLimitation = null,
) => {
  let canvas = document.createElement('CANVAS');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    if (imageLimitation
        && (img.height > imageLimitation.maxHeight || img.width > imageLimitation.maxWidth)) {
      canvas.height = imageLimitation.maxHeight;
      canvas.width = imageLimitation.maxWidth;
    } else {
      canvas.height = img.height;
      canvas.width = img.width;
    }

    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat || img.type);
    callback(dataURL);
    // Clean up
    canvas = null;
  };
  img.onerror = function () {
    onError();
  };
  img.src = url;
};
