/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { colors } from '../../../common/components/styles/colors';
import Error from './FieldError.jsx';
import {
  BreakLine, FieldWrapper, Label, StyledInput, StyledTextarea,
} from './styles/FieldsStyled.js';

const CopyButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  height: ${(props) => props.height || '100%'};
  transition: .4s;
  background-color: ${colors.blue};
  cursor: pointer;
  padding: 0 .5rem;

  &:hover {
    background-color: ${colors.grey};
  }

  ${(props) => props.copied && css`
    background-color: ${colors.green} !important;
  `}
`;

const Tooltip = styled(ReactTooltip).attrs({
  effect: 'solid',
  backgroundColor: '#333',
  textColor: '#fff',
  delayHide: 300,
})`
  border-radius: 3px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
`;

function Input(props) {
  const {
    id,
    style = {}, label, type = 'text', name, inputRef = useRef(null), error,
    autoWidth = false, autoHeight = false, inline,
    children, childrenFirst = false,
    copiable = null,
    ...rest
  } = props;

  const uniqueId = uuid();

  const [copied, setCopied] = useState(false);
  const [copiableHeight, setCopiableHeight] = useState(null);

  useEffect(() => {
    if (copiable) {
      const height = inputRef.current.offsetHeight;
      setCopiableHeight(height);
    }
  }, []);

  return (
    <FieldWrapper
      id={id}
      inline={inline}
      autoWidth={autoWidth}
      autoHeight={autoHeight}
      style={{
        ...style, position: 'relative', fontFamily: 'Poppins, sans-serif', marginBottom: 0, fontSize: 16,
      }}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <BreakLine />
      <Error error={error} />
      {childrenFirst && children}
      {
        type === 'textarea'
          ? (
            <StyledTextarea
              name={name}
              id={name}
              ref={inputRef}
              rows={rest.rows || autoHeight ? 3 : undefined}
              error={error}
              {...rest}
              style={{ resize: 'none', marginBottom: 0 }}
            >
              {rest.value}
            </StyledTextarea>
          )
          : (
            <StyledInput
              style={copiable ? { paddingRight: 40, marginBottom: 0 } : { marginBottom: 0 }}
              type={type}
              name={name}
              id={name}
              ref={inputRef}
              error={error}
              {...rest}
            />
          )
      }
      {
        copiable && (
        <CopyButton
          height={copiableHeight}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            inputRef.current.select();
            document.execCommand('copy');
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
            if (window.getSelection) {
              window.getSelection().removeAllRanges();
            } else if (document.selection) {
              document.selection.empty();
            }
          }}
          copied={copied}
          data-tip
          data-for={`input-${uniqueId}-copy`}
        >
          <Tooltip id={`input-${uniqueId}-copy`}>
            {copied ? 'Copied' : 'Copy'}
          </Tooltip>
          <FontAwesomeIcon icon={faCopy} color="white" />
        </CopyButton>
        )
      }
      <BreakLine />
      {!childrenFirst && children}
    </FieldWrapper>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputRef: PropTypes.object,
  autowidth: PropTypes.bool,
  childrenFirst: PropTypes.bool,
};

export default Input;
