/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-await-in-loop */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload as UploadIcon, faTrash as RemoveIcon, faCropAlt as CropIcon,
} from '@fortawesome/free-solid-svg-icons';
import {
  Description, DropZone, DropZoneWrapper, ImageActions, ImageWrapper, Title,
  IconButton as ImageAction,
} from './styles/DropImagesStyled.js';
import ImageCropperModal from './ImageCropperModal.jsx';
import ErrorComponent from './FieldError.jsx';
import { validateFile } from './imageHelpers.js';
import { HiddenInput } from './styles/creativitiesStyled.js';
import './styles/app.css';

function DropImageInput(props) {
  const {
    title, description, disabled, error,
    fileTypesAllowed = [], maxFileSizeInKb = null,
    imageLimitation = {
      maxWidth: null, minWidth: 10, maxHeight: null, minHeight: 10, ratio: null,
    },
    name, value, required, onRemove, onAdd, reseted = false,
    ...rest
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileName, setSelectedFileName] = useState({});
  const [newImage, setNewImage] = useState(reseted);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);

  const inputRef = useRef(null);

  const openInput = () => {
    inputRef.current.click();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };
  // eslint-disable-next-line default-param-last
  const changeImage = (imageSrc, isNew = false, fileName) => {
    onAdd(imageSrc, fileName);
    setNewImage(isNew);
    setSelectedFile({ imageSrc });
    setSelectedFileName({ fileName });
  };

  const handleFiles = async (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileIsAllowed = await validateFile(
        file,
        fileTypesAllowed,
        maxFileSizeInKb,
        imageLimitation,
        setErrorMessage,
      );
      if (fileIsAllowed) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageCropModalOpen(true);
          changeImage(reader.result, true, file.name);
          // console.log('RESULT', reader.result)
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsDragging(false);
    const { files } = e.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    setErrorMessage('');
    if (inputRef.current.files.length) {
      handleFiles(inputRef.current.files);
    }
  };

  const removeFile = () => {
    onRemove();
    inputRef.current.value = '';
    setSelectedFile(null);
  };

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    if (value) {
      // if (value.startsWith('data:image/')) {
      setSelectedFile({ imageSrc: value });
      // } else {
      //   convertImgToBase64(value, value => setSelectedFile({ imageSrc: value }),
      // null, removeFile, { width: imageLimitation.maxWidth, height: imageLimitation.maxHeight })
      // }
    } else {
      setSelectedFile(null);
    }
  }, [value]);

  return (
    <DropZoneWrapper {...rest}>
      <ErrorComponent error={errorMessage} />
      <DropZone
        error={error}
        disabled={disabled}
        isDragging={isDragging}
        onClick={() => !disabled && openInput()}
        onDragOver={dragEnter}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {
          !disabled && (
          <>
            <input
              type="file"
              ref={inputRef}
              // name={name}
              onChange={filesSelected}
              accept={fileTypesAllowed.join(', ')}
            />
            <HiddenInput
              readOnly
              name={name}
              value={selectedFile?.imageSrc ? selectedFile.imageSrc : ''}
            />
            <HiddenInput
              readOnly
              name={`base64_${name}`}
              value={value?.startsWith('data:image/') && selectedFile?.imageSrc ? selectedFile.imageSrc : ''}
              required={required}
            />
          </>
          )
        }
        {
          !selectedFile
            ? !disabled
            && (
            <>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <FontAwesomeIcon icon={UploadIcon} />
            </>
            )
            : (
              <ImageWrapper>
                <img src={selectedFile.imageSrc} />
                {
                  !disabled && (
                  <ImageActions>
                    <ImageAction
                      color="#fff"
                      bgColor="#5cb85c"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setImageCropModalOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={CropIcon} />
                    </ImageAction>
                    <ImageAction
                      color="#fff"
                      bgColor="#DC3545"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeFile();
                      }}
                    >
                      <FontAwesomeIcon icon={RemoveIcon} />
                    </ImageAction>
                  </ImageActions>
                  )
                }
              </ImageWrapper>
            )
        }

      </DropZone>
      {
        selectedFile?.imageSrc && (
        <ImageCropperModal
          show={imageCropModalOpen}
          onHide={() => setImageCropModalOpen(false)}
          imageSrc={selectedFile.imageSrc}
          fileName={selectedFileName.fileName}
          imageLimitation={imageLimitation}
          newImage={newImage}
          aspectRatio={imageLimitation.ratio}
          onCrop={changeImage}
        />
        )
      }
    </DropZoneWrapper>
  );
}

export default DropImageInput;
