import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

type UseProtectedRouteParams = { authorizedUserRoles: Array<string> };

export const useProtectedRoute = ({ authorizedUserRoles }: UseProtectedRouteParams) => {
  const userRole = useAppSelector(({ loginSlice: { user } }) => user?.role || '');

  const navigate = useNavigate();

  useEffect(() => {
    if (!authorizedUserRoles.includes(userRole)) {
      navigate('/');
    }
  }, []);
};
