import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { pushgroundApi } from './services/pushgroundApi';
import loginSlice from '../features/login/loginSlice';
import campaignsSlice from '../features/campaigns/redux/campaignsSlice';
import moderationSlice from '../features/moderation/redux/moderationSlice';
import createCampaignSlice from '../features/create-campaign/redux/createCampaignSlice';
import userSettingsSlice from '../features/user-settings/redux/userSettingsSlice';

export const store = configureStore({
  reducer: {
    [pushgroundApi.reducerPath]: pushgroundApi.reducer,
    loginSlice,
    campaignsSlice,
    moderationSlice,
    createCampaignSlice,
    userSettingsSlice,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(pushgroundApi.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
