const getIconRelativePath = (iconName: string) => `/assets/user-menu-icons/${iconName}-icon.svg`;

const invoicing = {
  icon: getIconRelativePath('invoicing'),
  label: 'Invoicing',
  url: 'https://billing.pushground.com/client/dashboard/trlxdyzrsqz7ss6xmvupkd8c5eumnbg1',
};

const balance = {
  icon: getIconRelativePath('balance'),
  label: 'Balance',
  route: '/user/balance',
};

const api = {
  icon: getIconRelativePath('api'),
  label: 'API',
  url: 'https://developers.pushground.com/advertisers',
};

const inviteFriend = {
  icon: getIconRelativePath('invite-friend'),
  label: 'Invite a friend',
  route: '/user/referral',
};

const settings = {
  icon: getIconRelativePath('settings'),
  label: 'Settings',
  route: '/user/settings',
};

const logout = {
  icon: getIconRelativePath('logout'),
  label: 'Logout',
};

interface MenuItem {
    icon: string;
    label: string;
    route?: string;
    url?: string;
}

const menuItems: Array<MenuItem> = [invoicing, balance, api, inviteFriend, settings, logout];

export default menuItems;
