/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { mapOptions } from './helpers.js';
import {
  IfWithinWrapper, RuleInput, RuleSelect, Wrapper,
} from './styles/AutomaticRules';

function Condition({
  ruleId, id, dimension, metric, value,
  clause, onRuleChange, onConditionChange, isNew, index,
}) {
  const {
    options,
  } = useAppSelector(({ createCampaignSlice: { automaticRules } }) => ({
    options: automaticRules.ruleoptions,
  }));
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [stepValue, setStepValue] = useState();

  const metricUnit = {
    cpa: '$',
    roi: '%',
    clicks: '',
    deliveries: '',
    conversions: '',
    winRate: '%',
    clickRate: '%',
    conversionRate: '%',
    revenue: '$',
  };

  const dimensionsOptions = Object.keys(options?.dimensions).map((k) => ({ value: k, label: k === 'source' ? 'Site Id' : options?.dimensions[k] }));
  const metricsOptions = mapOptions(options?.metrics);
  const clausesOptions = mapOptions(options?.clauses);

  useEffect(() => {
    // metric min max step
    // roi -100 +100 .01
    // CPA 0 unset .01
    // clicks 0 unset 1
    // deliveries 0 unset 1
    // conversions 0 unset 1
    // CTR 0 unset .01
    // conversion rate 0 unset .01
    // spent rate 0 unset .01
    switch (metric) {
      case 'roi':
        setMinValue(-100);
        setMaxValue(100);
        setStepValue(0.01);
        break;
      case 'cpa':
      case 'ctr':
      case 'conversionRate':
      case 'winRate':
      case 'spent':
        setMinValue(0);
        setMaxValue(null);
        setStepValue(0.01);
        break;
      case 'clicks':
      case 'deliveries':
      case 'conversions':
        setMinValue(0);
        setMaxValue(null);
        setStepValue(1);
        break;
      default:
    }
  }, [metric]);

  return (
    <>
      <IfWithinWrapper>
        {
          index === 0 && (
          <>
            <Wrapper noEdit withinIf>If</Wrapper>
            <Wrapper>
              <RuleSelect
                // menuIsOpen
                name={`rule[${isNew ? 'new' : 'old'}][${ruleId}][dimension]`}
                options={dimensionsOptions}
                defaultValue={dimensionsOptions[0]}
                value={dimensionsOptions.find((o) => o.value === dimension)}
                disabled={!isNew}
                onChange={({ value }) => isNew && onRuleChange('entity_check', value)}
              />
            </Wrapper>
          </>
          )
        }
      </IfWithinWrapper>

      <Wrapper noEdit="first">
        {
          index !== 0 && '&'
        }
      </Wrapper>
      <Wrapper style={{ width: 160 }}>
        <RuleSelect
          // menuIsOpen
          name={`rule[${isNew ? 'new' : 'old'}][${ruleId}][condition][${index}][metric]`}
          options={metricsOptions}
          defaultValue={metricsOptions[0]}
          value={metricsOptions.find((o) => o.value === metric)}
          onChange={({ value }) => onConditionChange(id, 'metric', value)}
        />
      </Wrapper>
      <Wrapper noEdit>is</Wrapper>
      <Wrapper style={{ width: 100 }}>
        <RuleSelect
          // menuIsOpen
          name={`rule[${isNew ? 'new' : 'old'}][${ruleId}][condition][${index}][clause]`}
          options={clausesOptions}
          defaultValue={clausesOptions.find((o) => o.value === 'greater')}
          value={clausesOptions.find((o) => o.value === clause)}
          onChange={({ value }) => onConditionChange(id, 'clause', value)}
        />
      </Wrapper>
      <Wrapper noEdit>than</Wrapper>
      <Wrapper padding="0 10px" style={{ width: 70 }}>
        <RuleInput
          type="number"
          max={maxValue}
          min={minValue}
          step={stepValue}
          value={value}
          name={`rule[${isNew ? 'new' : 'old'}][${ruleId}][condition][${index}][value]`}
          onChange={({ target }) => onConditionChange(id, 'value', parseFloat(target.value))}
        />
        {metricUnit[metric]}
      </Wrapper>
    </>
  );
}

export default Condition;
