/* eslint-disable camelcase */
export const transformTemplateToAPI = (rule) => {
  const {
    entity_check, conditions, actions, __isNew__, id, active,
    ...r
  } = rule;
  const { action: actionSet, value, increaseCap } = actions;
  let actionsToSave;
  switch (actions.action) {
    case 'resume':
      actionsToSave = { resume: true };
      break;
    case 'increaseBudget':
    case 'increaseBid':
      actionsToSave = {
        [actionSet]: `${value}`,
        increaseCap: `${increaseCap}`,
      };
      break;
    case 'decreaseBid':
      actionsToSave = {
        increaseBid: `${-value}`,
        increaseCap: `${increaseCap}`,
      };
      break;
    case 'pause':
    default:
      actionsToSave = { [entity_check === 'offer' ? 'pauseCampaign' : 'doBlacklist']: true };
  }
  return {
    ...r,
    check: entity_check,
    conditions: JSON.parse(conditions),
    actions: actionsToSave,
  };
};

export const transformTempalteToLaravel = (ruleTemplate) => {
  const { actions, check } = ruleTemplate;
  const {
    doBlacklist, pauseCampaign, resume, increaseBudget, increaseBid, increaseCap,
  } = actions;
  let action; let
    value;
  switch (true) {
    case doBlacklist:
    case pauseCampaign:
      action = 'pause';
      break;
    case resume:
      action = 'resume';
      break;
    case increaseBudget && parseFloat(increaseBudget) !== 0:
      action = 'increaseBudget';
      value = Math.abs(parseFloat(increaseBudget));
      break;
    case increaseBid && parseFloat(increaseBid) !== 0:
      action = parseFloat(increaseBid) > 0 ? 'increaseBid' : 'decreaseBid';
      value = Math.abs(parseFloat(increaseBid));
      break;
    default:
  }
  return {
    ...ruleTemplate,
    entity_check: check,
    actions: {
      action,
      value,
      increaseCap,
    },
  };
};
