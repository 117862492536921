import styled from 'styled-components/macro';
import { Badge } from '@mui/material';
import ReactTooltip from 'react-tooltip';

interface StyledSideNavbarProps {
    isSideNavbarOpen: boolean;
}

export const StyledSideNavbar = styled.nav<StyledSideNavbarProps>`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    transform: translateX(${({ isSideNavbarOpen }) => (isSideNavbarOpen ? 0 : '-100%')});
    transition: .4s;
    width: 235px;
    background-color: #464646;
    padding: 8px 4.8px;
    z-index: 11;
    overflow-y: auto;
    overflow-x: hidden;

    ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
        transform: none;
        width: ${({ isSideNavbarOpen }) => (isSideNavbarOpen ? '235px' : '54px')};
    }

    ${({ theme: { customScrollbarStyles } }) => customScrollbarStyles}
`;

interface StyledBadgeProps {
    $isSideNavbarOpen: boolean;
}

export const StyledBadge = styled(Badge).attrs<StyledBadgeProps>(
  ({ $isSideNavbarOpen }) => ({
    anchorOrigin: {
      vertical: 'top',
      horizontal: window.innerWidth >= 1200 && !$isSideNavbarOpen ? 'left' : 'right',
    },
    color: 'error',
    max: 999,
  }),
)<StyledBadgeProps>`
    .MuiBadge-badge {
        font-size: 10px;
    }
`;

export const MenuItemLabel = styled.span<StyledSideNavbarProps>`
    white-space: pre;

    ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
        position: relative;
        left: ${({ isSideNavbarOpen }) => (isSideNavbarOpen ? 0 : -10)}px;
        opacity: ${({ isSideNavbarOpen }) => (isSideNavbarOpen ? 1 : 0)};
        transition: .4s;
    }
`;

export const StyledTooltip = styled(ReactTooltip).attrs({
  place: 'right',
  effect: 'solid',
  arrowColor: 'transparent',
  backgroundColor: '#2c2c2c',
  offset: { left: 13 },
  delayShow: 90,
  delayHide: 90,
})`
    &&& {
        padding: 10.5px 10px 10.5px 22px;
        opacity: 1;
    }
`;
