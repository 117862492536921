import styled from 'styled-components/macro';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { StyledTable } from '../../../campaigns/components/styles/Table';

export const StyledDialogTitle = styled(DialogTitle)`
    && {
        font-family: Poppins, sans-serif;
        font-size: 24px;
        color: #FFF;
        background-color: #2f373c;
        padding: 1rem 1rem;
        display: flex;
        gap: 10px;
    }
`;

export const StyledIconButton = styled(IconButton)`
    && {
        color: #FFF;
    }
`;

export const StyledDialogContent = styled(DialogContent)`
    &&& {
        padding: 1rem;
    }
`;

export const StyledDialogTable = styled(StyledTable)`
    margin-top: 15px;
    
    thead {
        font-weight: 600;

        td {
            padding: 14px;
        }
    }

    && td {
        text-align: left;

        &:first-child {
            min-width: 165px !important;
        }

        &:nth-child(2) {
            min-width: 305px !important;
        }
    }
`;
