import React, {
  useState, useEffect, type MouseEvent, Dispatch, SetStateAction,
} from 'react';
import { InputAdornment } from '@mui/material';
import { FaInfoCircle } from 'react-icons/fa';
import {
  StyledPopover, PopoverTitle, StyledInput, EditorCancelOrSetBttn, EditorSaveBttn, BttnsContainer,
  BidSubtractBttn, BidAddBttn, BidSubtitleContainer, SetBttnContainer,
} from './styles/EditorPopover';
import { Tooltip } from './styles/Table';
import { FlexContainer } from '../../../common/components/styles/Flex';
import { useUpdateCampaignMutation } from '../redux/campaignsAPI';
import formatNumber from '../../../common/utils/formatNumber';
import { type EditorPopoverDynamicData } from './Table';

interface InputProps {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
}

interface BidInputProps extends InputProps {
  bidData: { maxBid: number | undefined; recommendedBid: number | undefined; };
}

function CampaignNameInput({ inputValue, setInputValue }: InputProps) {
  const [inputWidth] = useState(inputValue.length + 1);

  return (
    <StyledInput
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
      width={inputWidth}
    />
  );
}

function BidInput({ inputValue, setInputValue, bidData }: BidInputProps) {
  const [numberOfDecimals] = useState(String(inputValue).split('.')[1].length);
  const changeAmount = numberOfDecimals === 4 ? 0.0001 : 0.001;

  const getFormattedNumber = (number: number) => formatNumber(
    { number: +number, numberOfDecimals },
  );

  const getBidElement = ({ subtitle, value }: { subtitle: string; value: number }) => (
    <>
      <BidSubtitleContainer>
        {subtitle === 'Recommended bid' && <FaInfoCircle color="#4474ee" data-tip data-for="recommended-bid-tooltip" cursor="pointer" />}
        <span>{subtitle}</span>
      </BidSubtitleContainer>
      <StyledInput
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        value={value}
        width={11}
        disabled
      />
      <SetBttnContainer>
        <EditorCancelOrSetBttn onClick={() => setInputValue(String(value))}>
          Set
        </EditorCancelOrSetBttn>
      </SetBttnContainer>
    </>
  );

  return (
    <>
      <FlexContainer>
        <BidSubtractBttn onClick={() => setInputValue(
          (prevValue) => (
            +getFormattedNumber(+prevValue - changeAmount)!
              ? String(getFormattedNumber(+prevValue - changeAmount))
              : prevValue
          ),
        )}
        >
          -
        </BidSubtractBttn>
        <StyledInput
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          value={formatNumber({ number: +inputValue, numberOfDecimals })}
          onChange={(event) => !!+event.target.value.split(',').join('') && setInputValue(event.target.value.split(',').join(''))}
          width={12}
          $isBidInput
        />
        <BidAddBttn onClick={() => setInputValue(
          (prevValue) => String(getFormattedNumber(+prevValue + changeAmount)),
        )}
        >
          +
        </BidAddBttn>
      </FlexContainer>
      {!!bidData!.recommendedBid && getBidElement({ subtitle: 'Recommended bid', value: bidData!.recommendedBid as number })}
      {!!bidData!.maxBid && getBidElement({ subtitle: 'Top bid', value: bidData!.maxBid as number })}
      <Tooltip id="recommended-bid-tooltip" isRecommendedBid>
        Recommended Bid for this target to get access to all sources.
        This bid guarantee you to receive more than 80% of the traffic available in the platform.
        Make sure to align your bid with this value to get the max traffic in the platfrom.
      </Tooltip>
    </>
  );
}

function DailyBudgetInput({ inputValue, setInputValue }: InputProps) {
  return (
    <StyledInput
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      value={formatNumber({ number: +inputValue })}
      onChange={(event) => !!+event.target.value.split(',').join('') && setInputValue(event.target.value.split(',').join(''))}
    />
  );
}

interface EditorPopoverProps {
  anchorEl: HTMLButtonElement | HTMLDivElement | null;
  setAnchorEl(arg0: HTMLButtonElement | HTMLDivElement | null): void;
  dynamicData: EditorPopoverDynamicData;
  campaignId: number;
  isTablePopover?: boolean;
  bidData: { maxBid: number | undefined; recommendedBid: number | undefined; };
}

function EditorPopover({
  anchorEl, setAnchorEl, campaignId, dynamicData, isTablePopover = false, bidData,
}: EditorPopoverProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (anchorEl) {
      setIsPopoverOpen(true);
    }
  }, [anchorEl]);

  const { popoverTitle, inputInitialState, propertyToChange } = dynamicData;

  const [inputValue, setInputValue] = useState(inputInitialState);

  useEffect(() => {
    setInputValue(inputInitialState);
  }, [inputInitialState]);

  const handleClosePopover = (event: CloseEvent | MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsPopoverOpen(false);
    setAnchorEl(null);
    setInputValue(inputInitialState);
  };

  const [updateCampaign] = useUpdateCampaignMutation();

  const inputs: {[key: string]: any} = {
    name: CampaignNameInput,
    bid: BidInput,
    dailyBudget: DailyBudgetInput,
  };

  const CurrentInput = inputs[propertyToChange];

  return anchorEl && (
    <StyledPopover
      anchorEl={anchorEl}
      open={isPopoverOpen}
      onClose={handleClosePopover}
      onClick={(event) => event.stopPropagation()}
      $isTablePopover={isTablePopover}
    >
      <PopoverTitle>{popoverTitle}</PopoverTitle>
      <CurrentInput inputValue={inputValue} setInputValue={setInputValue} bidData={bidData} />
      <BttnsContainer>
        <EditorCancelOrSetBttn type="button" onClick={handleClosePopover}>Cancel</EditorCancelOrSetBttn>
        <EditorSaveBttn
          type="button"
          onClick={(event) => {
            handleClosePopover(event);
            updateCampaign({ campaignId, method: 'PUT', body: { [propertyToChange]: inputValue } });
          }}
        >
          Save
        </EditorSaveBttn>
      </BttnsContainer>
    </StyledPopover>
  );
}

EditorPopover.defaultProps = {
  isTablePopover: false,
};

export default EditorPopover;
