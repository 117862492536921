import styled from 'styled-components';

export const Container = styled.label`
  display: inline-block;
  height: 16px;
  position: relative;
  width: 30px;
  margin: 0;

  > input {
    display: none;
  }
`;

export const Slider = styled.span`
  background-color: #ddd;
  border-radius: 15px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &:before {
    background-color: #999;
    border-radius: 50%;
    content: '';
    height: 15px;
    position: absolute;
    transition: 0.2s;
    width: 15px;
  }
`;

export const SliderInput = styled.input`
  &:checked + ${Slider} {
    background-color: ${(props) => (props.checked ? props.activeColor : '#0365b2')};

    &:before {
      background-color: #fff;
      transform: translateX(15px);
    }
  }
`;
