import styled, { css } from 'styled-components';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.collapsed ? 0 : '1rem')};
  margin-top: ${(props) => (props.collapsed ? '.3rem' : 0)};
  ${(props) => props.inline && css`
    flex-direction: row;

    label {
      display: flex;
      align-items: center;
      margin: 0 .6rem 0 0;
    }
  `};
  ${(props) => props.autoHeight && css`
    flex: 1;
  `};
  ${(props) => props.autoWidth && css`
    > input {
      width: 215px;
    }
  `};
  ${(props) => props.css && css(props.css)};
`;

export const Label = styled.label`
  //font-weight: 500;
  margin-bottom: 4px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  ${(props) => props.small && css`
    font-size: .9em;
  `};
  ${(props) => props.new && css`
    display: flex;
    align-items: center;

    &:before {
      background-color: red;
      border-radius: 4px;
      color: white;
      content: "NEW";
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      margin-right: 5px;
      padding: 3px 5px;
    }
  `};
`;

export const StyledCheckbox = styled.span`
  margin-right: 10px;

  input {
    margin-right: 5px;
  }

  label {
    margin: 0;
  }
`;
