/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash.omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus as addIcon, faTrash as removeIcon,
} from '@fortawesome/free-solid-svg-icons';
import { updateAutomaticRule } from '../redux/createCampaignSlice';
import { mapOptions } from './helpers.js';
import {
  Rule as RuleStyled, ConditionWrapper, RuleSelect, Wrapper, IfWithinWrapper,
  RuleInput, RuleStats, WrapperDivs,
  ActionsWrapper, AutoOverlay, OverlayButton, IconButton,
} from './styles/AutomaticRules';
import { colors } from '../../../common/components/styles/colors';
import SwitchSelector from './SwitchSelector.jsx';
import Condition from './conditions.jsx';
import { useAppDispatch } from '../../../app/hooks';

function Rule({
  rule, automaticRules, onRemove, budget,
}) {
  const {
    ruleoptions, // ruleactions,
    rulesconditions,
  } = automaticRules;
  const dispatch = useAppDispatch();
  const [conditions, setConditions] = useState(null);
  const [overlayIsOpen, setOverlayIsOpen] = useState(false);

  const withinOptions = mapOptions(ruleoptions?.within);
  const actionsOptions = mapOptions(ruleoptions?.actions).filter((a) => {
    if (rule.entity_check !== 'offer' && a.value === 'increaseBudget') {
      return false;
    }
    return true;
  });
  const freqOptions = mapOptions(ruleoptions?.freq);

  // within every options
  // 1h  [30m, 2h, 4h, 6h, 12h, 24h]
  // 2h  [30m, 2h, 4h, 6h, 12h, 24h]
  // Today  [30m, 2h, 4h, 6h, 12h, 24h]
  // 48h  [2h, 4h, 6h, 12h, 24h]
  // 72h  [6h, 12h, 24h]
  // 7d  [12h, 24h]
  // 15d  [12h, 24h]

  const handleRuleChange = (input, value) => {
    const newRule = rule;
    newRule[input] = value;
    // console.log(newRule)
    dispatch(updateAutomaticRule(newRule));
  };

  const handlePeriodChange = (period) => {
    const newConditions = conditions.map((c) => ({ ...c, period }));
    setConditions(newConditions);
    handleRuleChange('conditions', JSON.stringify(newConditions.map((c) => omit(c, 'id'))));
  };

  const handleConditionChange = (id, input, value) => {
    const index = conditions.findIndex((c) => c.id === id);
    conditions[index][input] = value;
    setConditions(conditions);
    handleRuleChange('conditions', JSON.stringify(conditions.map((c) => omit(c, 'id'))));
  };

  const addCondition = () => {
    const newCondition = {
      metric: 'roi', clause: 'greater', value: '0', period: conditions[0].period, id: uuidv4(),
    };
    const newConditions = [...conditions, newCondition];
    setConditions(newConditions);
    handleRuleChange('conditions', JSON.stringify(newConditions.map((c) => omit(c, 'id'))));
  };

  const removeCondition = (condition) => {
    const newConditions = conditions.filter((c) => c.id !== condition.id);
    setConditions(newConditions);
    handleRuleChange('conditions', JSON.stringify(newConditions.map((c) => omit(c, 'id'))));
  };

  const handleActionChange = (input, value) => {
    const newAction = rule.actions;
    newAction[input] = value;
    if (input === 'action') {
      if (value === 'increaseBid' || value === 'decreaseBid') {
        handleActionChange('value', 0.001);
        handleActionChange('increaseCap', 0.2);
      } else if (rule.actions?.action === 'increaseBudget') {
        handleActionChange('value', 5);
        handleActionChange('increaseCap', budget * 2);
      }
    }

    handleRuleChange('actions', newAction);
  };

  useEffect(() => {
    if (rule.entity_check !== 'offer' && rule.actions.action === 'increaseBudget') {
      handleActionChange('action', 'pause');
    }
  }, [rule.entity_check]);

  useEffect(() => {
    const ruleConditions = rulesconditions?.[rule.id] || JSON.parse(rule.conditions);
    const idConditions = ruleConditions?.map((c) => ({ ...c, id: uuidv4() })) || null;
    setConditions(idConditions);
  }, []);

  const isNew = rule.__isNew__;

  return (
    <RuleStyled>
      <WrapperDivs>
        <div>
          {
            conditions?.map((c, i) => (
              <ConditionWrapper key={c.id}>
                <Condition
                  ruleId={rule.id}
                  id={c.id}
                  dimension={rule.entity_check}
                  metric={c.metric}
                  value={c.value}
                  clause={c.clause}
                  onRuleChange={handleRuleChange}
                  onConditionChange={handleConditionChange}
                  isNew={isNew}
                  index={i}
                />
                {
                  i > 1
                  && (
                  <IconButton
                    bgColor="#DB3847"
                    color="#fff"
                    style={{ margin: '3px 5px', width: 28 }}
                    onClick={() => removeCondition(c)}
                  >
                    <FontAwesomeIcon icon={removeIcon} />
                  </IconButton>
                  )
                }
                {
                  (i + 1 === conditions.length)
                  && (
                  <IconButton
                    bgColor="#00D895"
                    color="#fff"
                    style={{ margin: '3px 5px', width: 28 }}
                    onClick={() => addCondition()}
                  >
                    <FontAwesomeIcon icon={addIcon} />
                  </IconButton>
                  )
                }
              </ConditionWrapper>
            ))
          }

          <ConditionWrapper>
            <IfWithinWrapper>
              <Wrapper noEdit withinIf>Within</Wrapper>
              <Wrapper>
                <RuleSelect
                  // menuIsOpen
                  name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][within]`}
                  options={withinOptions}
                  defaultValue={withinOptions.find((o) => o.value === 'today')}
                  value={withinOptions.find((o) => o.value === conditions?.[0]?.period) || null}
                  onChange={({ value }) => handlePeriodChange(value)}
                />
              </Wrapper>
            </IfWithinWrapper>
            <Wrapper noEdit>then</Wrapper>
            <Wrapper style={{ width: 160 }}>
              <RuleSelect
                // menuIsOpen
                name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][action]`}
                options={actionsOptions}
                defaultValue={actionsOptions[0]}
                value={actionsOptions.find((o) => o.value === rule.actions?.action) || null}
                onChange={({ value }) => handleActionChange('action', value)}
              />
            </Wrapper>
            {
              (rule.actions?.action === 'increaseBudget' || rule.actions?.action === 'increaseBid' || rule.actions?.action === 'decreaseBid') && (
              <>
                <Wrapper noEdit />
                <Wrapper style={{ padding: '0 10px', width: 70 }}>
                  <RuleInput
                    type="number"
                    step={0.001}
                    min={0}
                    value={rule.actions.value || 0}
                    onChange={({ target }) => handleActionChange('value', parseFloat(target.value))}
                    name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][actionValue]`}
                  />
                  $
                </Wrapper>
                <Wrapper noEdit>up to</Wrapper>
                <Wrapper style={{ padding: '0 10px', width: 70 }}>
                  <RuleInput
                    type="number"
                    step={0.001}
                    min={0}
                    value={rule.actions.increaseCap || 0}
                    onChange={({ target }) => handleActionChange('increaseCap', parseFloat(target.value))}
                    name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][actionCap]`}
                  />
                  $
                </Wrapper>
              </>
              )
            }

            <Wrapper noEdit style={{ width: 65 }}>every</Wrapper>
            <Wrapper>
              <RuleSelect
                // menuIsOpen
                name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][freq]`}
                options={freqOptions}
                defaultValue={freqOptions.find((o) => o.value.toString() === '240')}
                value={freqOptions.find(
                  (o) => o.value.toString() === rule?.frequency?.toString(),
                ) || null}
                onChange={({ value }) => handleRuleChange('frequency', value)}
              />
            </Wrapper>
          </ConditionWrapper>
        </div>
        <div style={{ position: 'relative' }}>
          {
            !rule.__isNew__ && (
              <RuleStats>
                <h3>Rule Stats</h3>

                <table>
                  <tbody>
                    <tr>
                      <td>Times Checked</td>
                      <td>{rule.checks}</td>
                    </tr>
                    <tr>
                      <td>Times Applied</td>
                      <td>{rule.applies}</td>
                    </tr>
                    <tr>
                      <td>Applied / Checked</td>
                      <td>
                        {((rule.applies / (rule.checks || 1)) * 100).toFixed(2)}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </RuleStats>
            )
          }
          <ActionsWrapper>
            <SwitchSelector
              name={`rule[${isNew ? 'new' : 'old'}][${rule.id}][active]`}
              style={{ marginRight: 20 }}
              onSelect={() => {
                handleRuleChange('active', !rule.active);
              }}
              checked={!!rule.active}
              activeColor={colors.green}
            />
            <IconButton
              bgColor="#DB3847"
              color="#fff"
              style={{ width: 28 }}
              onClick={() => setOverlayIsOpen(true)}
            >
              <FontAwesomeIcon icon={removeIcon} />
            </IconButton>
          </ActionsWrapper>
        </div>
      </WrapperDivs>
      <AutoOverlay
        isOpen={overlayIsOpen}
        onClose={() => {
          setOverlayIsOpen(false);
        }}
      >
        <div>
          Are you sure you want to remove this rule?
          <br />
          <OverlayButton
            bgColor="#ff0000"
            onClick={() => {
              setOverlayIsOpen(false);
              onRemove();
            }}
          >
            Yes
          </OverlayButton>
          <OverlayButton
            bgColor="#000"
            onClick={() => {
              setOverlayIsOpen(false);
            }}
          >
            No
          </OverlayButton>
        </div>
      </AutoOverlay>
    </RuleStyled>
  );
}

const mapStateToProps = ({ automaticRules, campaign }) => ({
  automaticRules, budget: campaign?.daily_budget,
});

export default Rule;
