/* eslint-disable react/prop-types */
import { useLayoutEffect } from 'react';
import { StyledSelect } from '../../create-campaign/components/styles/CreateCampaing';
import Input from './Input.jsx';
import {
  Form, Fieldset, FieldsetTitle, SubmitButton,
} from './styles/common.styled.js';
import { updateSettings } from '../redux/userSettingsSlice';
import { useAppSelector } from '../../../app/hooks';
import { useLazyGetFormDataQuery } from '../../create-campaign/redux/createCampaignAPI';

const experienceWithPushOptions = [
  'Noob',
  'Amateur',
  'Pro',
  'Ninja',
];

const accountTypeOptions = ['Individual', 'Company'];

const monthlyBudgetOptions = [
  '$0 - $9,999',
  '$10,000 - $29,999',
  '$30,000 - $100,000',
  '+$100,000',
];

const hearFromUsOptions = [
  'Google',
  'Social Networks',
  'Friend',
  'Events',
  'Forums',
  'Others',
];

function Profile({ style }) {
  const [getFormData] = useLazyGetFormDataQuery();

  useLayoutEffect(() => {
    getFormData();
  }, []);

  const { timezoneOptions, categories } = useAppSelector(({
    userSettingsSlice: { formData },
  }) => formData);

  const {
    fullName = '', defaultTimezone = '', accountType = '', skype = '', telegram = '', verticals = '', experienceWithPush = 0, monthlyBudget = '', heardUs = '',
  } = useAppSelector(({ userSettingsSlice: { profile } }) => profile);

  return (
    <Fieldset noMargin style={style}>
      <FieldsetTitle fontSize={22}>User Profile</FieldsetTitle>
      <Form>
        <Input
          label="Name"
          name="full_name"
          required
          value={fullName}
          onChange={({ target }) => updateSettings({ profile: { fullName: target.value } })}
        />
        <StyledSelect
          label="Timezone"
          name="timezone"
          required
          options={timezoneOptions}
          selected={defaultTimezone}
          onSelect={(value) => updateSettings({ profile: { defaultTimezone: value } })}
        />
        <StyledSelect
          label="Account Type"
          name="account_type"
          required
          options={accountTypeOptions}
          selected={accountType}
          onSelect={(value) => updateSettings({ profile: { accountType: value } })}
        />
        <Input
          label="Skype"
          name="skype"
          value={skype}
          onChange={({ target }) => updateSettings({ profile: { skype: target.value } })}
        />
        <Input
          label="Telegram"
          name="telegram"
          value={telegram}
          onChange={({ target }) => updateSettings({ profile: { telegram: target.value } })}
        />
        <StyledSelect
          isMulti
          name="verticals[]"
          label="Verticals"
          required
          options={categories.reduce((old, cat) => ({ ...old, [cat.name]: cat.name }), {})}
          selected={verticals}
          onSelect={(values) => updateSettings({ profile: { verticals: values } })}
        />
        <Input
          label="Experience with Push"
          name="experience_with_push"
          type="range"
          required
          min="0"
          max={experienceWithPushOptions.length - 1}
          value={experienceWithPush}
          onChange={({ target }) => updateSettings(
            { profile: { experience_with_push: target.value } },
          )}
        >
          <div style={{ position: 'relative', height: 20 }}>
            {
              experienceWithPushOptions.map((opt, i) => (
                <small
                  key={i}
                  style={{
                    position: 'absolute',
                    left: `${(i * 100) / (experienceWithPushOptions.length - 1)}%`,
                    transform: i !== 0 ? i === (experienceWithPushOptions.length - 1) ? 'translateX(-100%)' : 'translateX(-50%)' : 'none',
                  }}
                >
                  {opt}
                </small>
              ))
            }
          </div>
        </Input>
        <StyledSelect
          label="Monthly Budget"
          name="monthly_budget"
          required
          options={monthlyBudgetOptions}
          selected={monthlyBudget}
          onSelect={(value) => updateSettings({ profile: { monthlyBudget: value } })}
        />
        <StyledSelect
          label="Where did you hear about us"
          name="heard_us"
          required
          options={hearFromUsOptions}
          selected={heardUs}
          onSelect={(value) => updateSettings({ profile: { heardUs: value } })}
        />
        <SubmitButton>Save Profile</SubmitButton>
      </Form>
    </Fieldset>
  );
}

export default Profile;
