/* eslint-disable max-len */
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { Main, OpaqueBackground } from './components/styles/Dashboard.styled';
import Header from './components/Header';
import SideNavbar from './components/SideNavbar';

function DashboardLayout() {
  const isUserAuthenticated = useAppSelector(
    ({ loginSlice: { isUserAuthenticated } }) => isUserAuthenticated,
  );
  const isUserDataLoaded = useAppSelector(({ loginSlice: { user } }) => !!user?.name);

  const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);

  useLayoutEffect(() => {
    if (isUserDataLoaded) {
      setIsDashboardLoaded(true);
    }
  }, [isUserDataLoaded]);

  const [isSideNavbarOpen, setIsSideNavbarOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleOpaqueBgClick = () => {
    setIsSideNavbarOpen(false);
    setIsUserMenuOpen(false);
  };

  return (
    isUserAuthenticated
      ? (
        <>
          <Header
            isDashboardLoaded={isDashboardLoaded}
            useIsSideNavbarOpen={useCallback(() => [isSideNavbarOpen, setIsSideNavbarOpen], [isSideNavbarOpen])}
            useIsUserMenuOpen={useCallback(() => [isUserMenuOpen, setIsUserMenuOpen], [isUserMenuOpen])}
          />
          <Main isDashboardLoaded={isDashboardLoaded}>
            <SideNavbar isOpen={isSideNavbarOpen} setIsOpen={setIsSideNavbarOpen} setIsUserMenuOpen={setIsUserMenuOpen} />
            <Outlet />
            <OpaqueBackground show={isSideNavbarOpen || isUserMenuOpen} onClick={handleOpaqueBgClick} />
          </Main>
        </>
      )
      : <Navigate to="/login" />
  );
}

export default DashboardLayout;
