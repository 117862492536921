/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Slider,
  SliderInput,
} from './styles/switchSelectorStyles.js';

function SwitchSelector({
  name, onSelect, checked, activeColor, ...rest
}) {
  return (
    <Container {...rest}>
      <SliderInput
        name={name || null}
        type="checkbox"
        checked={checked}
        value={checked ? 1 : 0}
        onChange={onSelect}
        activeColor={activeColor}
      />
      <Slider />
    </Container>
  );
}

SwitchSelector.propTypes = {
  name: PropTypes.string,
  parentCallback: PropTypes.func,
  checked: PropTypes.bool,
  activeColor: PropTypes.string,
};

export default SwitchSelector;
