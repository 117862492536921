import { createSlice } from '@reduxjs/toolkit';
import { moderationApi } from './moderationAPI';
import { resetAllSlicesState } from '../../../common/utils/resetAllSlicesStateAction';
import type { InitialState } from '../types/moderationSliceTypes';

const initialState = {
  moderationList: null,
  totalCampaigns: null,
} as InitialState;

export const moderationSlice = createSlice({
  name: 'moderationSlice',
  initialState,
  reducers: {
    changeModerationStatus: (state, action) => {
      const { campaign: moderatedCampaign, user } = state.moderationList?.find(
        ({ campaign }) => campaign.id === action.payload.campaignId,
      ) ?? {};

      if (moderatedCampaign) {
        moderatedCampaign.isModerated = true;
        state.totalCampaigns! -= 1;
        state.moderationList?.forEach(({ user: campaignUser }) => {
          if (campaignUser.id === user!.id) {
            campaignUser.totalCampaigns -= 1;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllSlicesState, () => initialState);
    builder.addMatcher(
      moderationApi.endpoints.getModerationList.matchFulfilled,
      (state, action) => {
        const { moderationList, totalCampaigns } = action.payload;
        return { moderationList, totalCampaigns: totalCampaigns ?? state.totalCampaigns };
      },
    );
    builder.addMatcher(
      moderationApi.endpoints.allowOrRejectUrl.matchFulfilled,
      (state, action) => {
        const {
          body: requestBody,
          method: requestMethod,
          isModerationComplete,
        } = action.meta.arg.originalArgs;

        const moderatedCampaignId = requestBody.list[0];
        const { campaign: moderatedCampaign, user } = state.moderationList?.find(
          ({ campaign }) => campaign.id === moderatedCampaignId,
        ) ?? {};

        if (moderatedCampaign) {
          if (!isModerationComplete) {
            moderatedCampaign.isUrlModerated = true;
            moderatedCampaign.currentUrlStatus = requestMethod === 'PUT' ? 'good' : 'dangerous';

            if (requestMethod === 'DELETE') {
              const formattedReasons = requestBody.reasons.map((reason: string) => reason.split(' ').join('-').toLowerCase());
              moderatedCampaign.urlTags = { dangerous: formattedReasons, warning: [] };
            }
          } else {
            moderatedCampaign.isModerated = true;
            state.totalCampaigns! -= 1;
            state.moderationList?.forEach(({ user: campaignUser }) => {
              if (campaignUser.id === user!.id) {
                campaignUser.totalCampaigns -= 1;
              }
            });
          }
        }
      },
    );
    builder.addMatcher(
      moderationApi.endpoints.allowOrRejectCreativities.matchFulfilled,
      (state, action) => {
        const {
          body: { list: moderatedCreativities },
          moderationId,
          isModerationComplete,
        } = action.meta.arg.originalArgs;

        const { campaign: moderatedCampaign, user } = state.moderationList?.find(
          ({ campaign }) => campaign.id === moderationId && !campaign.isModerated,
        ) ?? {};

        if (moderatedCampaign) {
          if (!isModerationComplete) {
            moderatedCampaign.creativities = moderatedCampaign.creativities.filter(
              (creativity) => !moderatedCreativities.includes(creativity.id),
            );
          } else {
            moderatedCampaign.isModerated = true;
            state.totalCampaigns! -= 1;
            state.moderationList?.forEach(({ user: campaignUser }) => {
              if (campaignUser.id === user!.id) {
                campaignUser.totalCampaigns -= 1;
              }
            });
          }
        }
      },
    );
    builder.addMatcher(
      moderationApi.endpoints.changeCategory.matchFulfilled,
      (state, action) => {
        const { category, moderationId } = action.meta.arg.originalArgs;

        const moderatedCampaign = state.moderationList?.find(
          ({ campaign }) => campaign.id === moderationId,
        )?.campaign;

        if (moderatedCampaign) {
          moderatedCampaign.category = category;
        }
      },
    );
  },
});

export const { changeModerationStatus } = moderationSlice.actions;

export default moderationSlice.reducer;
