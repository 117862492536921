/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
import React from 'react';

// Subformats for push-ads format
export const subOptionsPush = [];

// Formats
export const formatOptions = [
  {
    name: 'push',
    label: 'Classic Push',
    description: 'Push Notifications that pops up on your device through the browser.',
    // image: "/images/resources/react/push-01.svg",
    image: '/images/resources/react/ad-format-classic-push.svg',
    visible: true,
    subOptions: subOptionsPush,
    validation: [
      'icon',
    ],
    configuration: {
      targeting: {
        os: {
          'not-allowed': ['iOS'],
        },
        iOSVersions: false,
      },
    },
    message: {
      pushground: {
        type: 'info',
        title: 'Classic Push',
        body: <>
          Before running Classic Push, please keep in mind that due to the nature
          of push notifications (and the CPC cost structure) there&apos;s a
          {' '}
          <strong>possibility of overspending your budget</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>mobile Apps</strong>
          ,
          {' '}
          <strong>casino</strong>
          ,
          {' '}
          <strong>antivirus</strong>
          ,
          {' '}
          <strong>branding</strong>
          {' '}
          and
          {' '}
          <strong>dating</strong>
          {' '}
          mainstream offers.
          <br />
          <br />
          For more information
          {' '}
          <a href="http://help.pushground.com/en/articles/3293731-overspending-your-budget" target="_blank" rel="noreferrer noopener">click here</a>
          .

              </>,
      },
      mobidea: {
        type: 'info',
        title: 'Classic Push',
        body: <>
          Before running Classic Push, please keep in mind that due to the
          nature of push notifications (and the CPC cost structure) there&apos;s a
          {' '}
          <strong>possibility of overspending your budget</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>mobile Apps, casino, antivirus, branding and dating mainstream offers</strong>
          .

              </>,
      },
    },
  },
  {
    name: 'web_push',
    label: 'In-Page Push',
    description: 'Similar to Classic Push Notifications but is sent while a user is visiting a website. IOS available.',
    image: '/images/resources/react/ad-format-inpage-push.svg',
    validation: [
      'icon',
    ],
    configuration: {
      audience: false,
    },
    visible: true,
    message: {
      pushground: {
        type: 'info',
        title: 'In-Page Push',
        body: <>
          Before running In-Page Push, please keep in mind that due to
          the nature of push notifications (and the CPC cost structure) there&apos;s a
          {' '}
          <strong>possibility of overspending your budget</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>mobile Apps, casino, antivirus, branding and dating mainstream offers</strong>
          .
          <br />
          <br />
          For more information
          {' '}
          <a href="http://help.pushground.com/en/articles/3293731-overspending-your-budget" target="_blank" rel="noreferrer noopener">click here</a>
          .

              </>,
      },
      mobidea: {
        type: 'info',
        title: 'Classic Push',
        body: <>
          Before running Classic Push, please keep in mind that due to the
          nature of push notifications (and the CPC cost structure) there&apos;s a
          {' '}
          <strong>possibility of overspending your budget</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>mobile Apps, casino, antivirus, branding and dating mainstream offers</strong>
          .

              </>,
      },
    },
  },
  {
    name: 'native',
    label: 'Native Ads (CPM)',
    description: 'A responsive ad type that matches the form of the platform it appears on.',
    image: '/images/resources/react/native-01.svg',
    isNew: true,
    visible: false,
  },
  {
    name: 'calendar_push',
    label: 'Calendar Push Ads',
    description: 'iOS alerts ads pushed through the calendar application.',
    image: '/images/resources/react/ad-format-calendar.svg',
    visible: false,
    configuration: {
      creativities: { icon: false, image: false },
      targeting: {
        os: {
          disabled: true,
          selected: ['iOS', 'Mac OS X'],
        },
        // devices: {
        // disabled: true,
        // selected: ['Smartphone'],
        // },
        browsers: false,
        // networks: false,
        // carriers: false,
        androidVersions: false,
        // iOSVersions: false,
        windowsVersions: false,
        deviceNames: false,
        activeHours: false,
        ipWhitelist: false,
      },
      frequency: false,
      traffic: false,
      audience: false,
      bidAndBudget: {
        budget: {
          min: 100,
        },
      },
    },
    message: {
      pushground: {
        type: 'info',
        title: 'iOS Calendar',
        body: <>
          Before testing Calendar push format, please keep in mind the
          {' '}
          <strong>possibility of large overspends</strong>
          , up to 3x your daily budget with clicks coming up to 3 days after the delivery.
          <br />
          <strong>CPC for calendar is higher</strong>
          {' '}
          compared to push reaching as high as $.20 - $.30 in Tier 1 geos.
          {' '}
          <br />
          Calendar traffic applies to
          {' '}
          <strong>iOS only</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>iOS apps, antivirus, VPN, casino and dating mainstream offers</strong>
          .
          <br />
          <br />
          For more information
          {' '}
          <a href="http://help.pushground.com/en/articles/4411390-ios-calendar-push" target="_blank" rel="noreferrer noopener">click here</a>
          .

              </>,
      },
      mobidea: {
        type: 'info',
        title: 'iOS Calendar',
        body: <>
          Before testing Calendar push format, please keep in mind the
          {' '}
          <strong>possibility of large overspends</strong>
          , up to 3x your daily budget with clicks coming up to 3 days after the delivery.
          <br />
          <strong>CPC for calendar is higher</strong>
          {' '}
          compared to push reaching as high as $.20 - $.30 in Tier 1 geos.
          {' '}
          <br />
          Calendar traffic applies to
          {' '}
          <strong>iOS only</strong>
          .
          <br />
          It works best with
          {' '}
          <strong>iOS apps, antivirus, VPN, casino and dating mainstream offers</strong>
          .
        </>,
      },
    },
  },
];
