import styled, { css } from 'styled-components';
import { colors } from '../../../../common/components/styles/colors';
import { boxShadow } from './helpers.js';

export const SubmitButton = styled.button`
  background-color: ${(props) => props.bgColor || colors.blue};
  border-radius: ${(props) => props.borderRadius || '10px'};
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  padding: .4rem 1.25rem;
  transition: .4s;

  &:disabled {
    background: #5179F6;
    opacity: 0.38;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.darkBlue};
  }

  ${(props) => props.isLoading && css`
    cursor: not-allowed;
    opacity: 0.55;
  `};
`;

export const Form = styled.form.attrs({
  method: 'POST',
  autoComplete: 'off',
})`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${SubmitButton} {
    margin: auto auto 0;
  }
`;

export const Fieldset = styled.fieldset`
  padding: 1rem 2rem;
  background-color: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  border-radius: 10px;
  ${boxShadow(colors.blueShadow)};
  ${(props) => !props.noMargin && css`
    @media (min-width: 1024px) {
      margin-right: 390px;
    }
  `}
  ${(props) => props.noMargin && css`
    margin: 1.5rem 0;
  `}
`;

export const FieldsetTitle = styled.h2`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '1.4rem')};
  color: #343a40;
  font-weight: 600;
  margin-bottom: 1.5rem;

  ${(props) => !props.noLine && css`
    padding-bottom: 1rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #CED4DA;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `};
`;

export const SmallMsg = styled.small`
  color: ${(props) => props.color || '#6C757D'};
  margin: .25rem 0 0 0;
`;

export const Message = styled.div`
  background-color: #F1F4F7;
  margin: .3rem 0;
  font-size: .85rem;
  border-left: 5px solid ${(props) => props.color || colors.blue};
  padding: .2rem .3rem;
`;
