import React, { useEffect, useState } from 'react';
import { useLazyGetCampaignsListQuery, useLazyGetCampaignsStatsQuery } from './redux/campaignsAPI';
import { SectionWrapper } from '../../common/components/styles/SectionWrapper';
import { Card } from './components/styles/Campaigns';
import Filters from './components/Filters';
import Table from './components/Table';
import { useAppSelector } from '../../app/hooks';
import type { CampaignsList } from './redux/campaignsSlice';

function Campaigns() {
  const [getCampaignsList, { isLoading, isFetching }] = useLazyGetCampaignsListQuery();
  const [
    getCampaignsStats, { isLoading: isLoadingStats, isFetching: isFetchingStats },
  ] = useLazyGetCampaignsStatsQuery();

  useEffect(() => {
    getCampaignsList();
  }, []);

  const { campaignsList, campaignsStats } = useAppSelector(
    ({ campaignsSlice: { campaignsList, campaignsStats } }) => (
      { campaignsList, campaignsStats }
    ),
  );

  const [currentCampaignsList, setCurrentCampaignsList] = useState<CampaignsList>();

  const [isFiltering, setIsFiltering] = useState(false);

  return (
    <SectionWrapper backgroundColor="#F2F3F8">
      <Card>
        <Filters
          campaignsList={campaignsList}
          currentCampaignsList={currentCampaignsList}
          setCurrentCampaignsList={setCurrentCampaignsList}
          setIsFiltering={setIsFiltering}
          getCampaignsStats={getCampaignsStats}
        />
        <Table
          campaignsList={currentCampaignsList}
          totalCampaignsList={campaignsList}
          campaignsStats={campaignsStats}
          isFetching={isLoading || isFetching || isFiltering || isLoadingStats || isFetchingStats}
          setIsFiltering={setIsFiltering}
        />
      </Card>
    </SectionWrapper>
  );
}

export default Campaigns;
