/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Checkbox from './Checkbox.jsx';
import Input from './Input.jsx';
import {
  Form, Fieldset, FieldsetTitle, SubmitButton, Message,
} from './styles/common.styled.js';
import { updateSettings } from '../redux/userSettingsSlice';
import { useAppSelector } from '../../../app/hooks';
import { StyledSelect } from '../../create-campaign/components/styles/CreateCampaing';

const paymentCurrencyOptions = ['USD', 'EUR'];

function Billing(props) {
  const {
    userAlertOutFunds, isEuCountry, hasTopup,
    style,
  } = props;
  const isAdvertiser = useAppSelector(({ loginSlice: { user } }) => user?.role === 'Advertiser');

  const { countriesOptions } = useAppSelector(({
    userSettingsSlice: { formData },
  }) => formData);

  const [disabledCurrency, setDisabledCurrency] = useState(false);
  const [hasInvalidEUVAT, setHasInvalidEUVAT] = useState(false);

  const {
    legalName = '', taxId = '', address = '', city = '', country = '', region = '', postalCode = '', wallet50, currency = '', ...billingInfo
  } = useAppSelector(({ userSettingsSlice: { billing } }) => billing);

  useEffect(() => {
    const isInvalidVAT = isEuCountry && billingInfo !== undefined && billingInfo.country !== 'ES' && (billingInfo?.validVat === undefined || !billingInfo.validVat);

    setHasInvalidEUVAT(isInvalidVAT);

    if (hasTopup) {
      setDisabledCurrency(true);
    } else if (currency === undefined) {
      updateSettings({ billing: { currency: 'EUR' } });
    }
    if (userAlertOutFunds && wallet50 === undefined) {
      updateSettings({ billing: { wallet50: true } });
    }
  }, []);

  return (
    <Fieldset noMargin style={{ ...style, display: 'flex', flexDirection: 'column' }}>
      <FieldsetTitle fontSize={22}>Billing Info</FieldsetTitle>
      <Message style={{ marginBottom: 15 }}>
        Make sure to fill this data properly, we will use it to generate all your invoices.
        Please note that if you modify the core information of your billing
        (Company Name and TaxId), a new invoicing panel will be generated and old invoices
        won&apos;t be attached to the new company invoicing.
      </Message>

      {
        isAdvertiser && hasInvalidEUVAT
        && (
        <Message color="red" style={{ marginBottom: 15 }}>
          Our system identified you have an invalid EU taxId number and VAT rate will be applied
          to your payments. If you are seeing this message while having a valid EU taxId number,
          please check and save your billing information again. If the issue persist please contact
          your account manager.
        </Message>
        )

      }
      <Form>
        <Input
          label="Company Name*"
          name="legal_name"
          value={legalName}
          onChange={({ target }) => updateSettings({ billing: { legalName: target.value } })}
        />
        <Input
          label="Tax ID*"
          name="tax_id"
          value={taxId}
          onChange={({ target }) => updateSettings({ billing: { taxId: target.value } })}
        />
        <Input
          label="Address*"
          name="address"
          value={address}
          onChange={({ target }) => updateSettings({ billing: { address: target.value } })}
        />
        <StyledSelect
          label="Country*"
          name="country"
          options={countriesOptions}
          selected={country}
          onSelect={(value) => updateSettings({ billing: { country: value } })}
        />
        <Input
          label="State or region"
          name="region"
          value={region}
          onChange={({ target }) => updateSettings({ billing: { region: target.value } })}
        />
        <Input
          label="City*"
          name="city"
          value={city}
          onChange={({ target }) => updateSettings({ billing: { city: target.value } })}
        />
        <Input
          label="Postal code*"
          name="postal_code"
          value={postalCode}
          onChange={({ target }) => /^[a-zA-Z0-9]*$/.test(target.value) && updateSettings({ billing: { postalCode: target.value } })}
        />
        <p style={{ fontSize: 12, color: '#00000099', margin: '-15px 0 10px' }}>Only numbers and letters are allowed.</p>
        <StyledSelect
          label="Payment Currency"
          name="currency"
          options={paymentCurrencyOptions}
          selected={currency}
          onSelect={(value) => updateSettings({ billing: { currency: value } })}
          isDisabled={disabledCurrency}
        />
        <Checkbox
          options={{ wallet50: 'Receive alert when running out of funds' }}
          selectedOptions={wallet50 ? ['wallet50'] : []}
          onCheck={(values) => updateSettings({ billing: { wallet50: values.includes('wallet50') ? 1 : 0 } })}
        />
        <SubmitButton>Save Billing</SubmitButton>
      </Form>
    </Fieldset>
  );
}

export default Billing;
