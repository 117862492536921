/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay as playIcon, faPause as pauseIcon, faRedo as cleanIcon, faTrash as trashIcon,
} from '@fortawesome/free-solid-svg-icons';
import AnimateHeight from 'react-animate-height';
import {
  updateCreativity, updateCreativitiesList, updateCreativitiesStatus,
} from '../redux/createCampaignSlice';
import {
  TextIconButton as Button, HiddenInput,
  Actions, Content, Creativity, MainContent, TextContent,
} from './styles/creativitiesStyled.js';
import DropImageInput from './DropImageInput.jsx';
import Input from './Input.jsx';
import useCreateValidation from './useCreateValidation.js';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

const fileTypesAllowed = ['image/jpeg', 'image/jpg', 'image/png'];

function CreativityEditor(props) {
  const {
    creativity, isSelected,
    iconAllowed = true, imageAllowed = true,
    creativityErrors,
    deleteCreativity,
  } = props;
  const {
    creativitiesList,
    creativitiesStatus,
  } = useAppSelector(({
    createCampaignSlice: { creativities },
  }) => ({
    creativitiesList: creativities.list.map(
      (c) => ({ ...c, status: creativities.status[c.id] || null }),
    ),
    creativitiesStatus: creativities.status,
  }));
  const dispatch = useAppDispatch();

  const {
    id, title, description, icon, image, status, __isNew__,
  } = creativity;
  const creativityIndex = creativitiesList.findIndex((c) => c.id === creativity.id);

  const [originalCreativity, setOriginalCreativity] = useState({});

  const handleCreativityChange = (inputName, inputValue) => {
    dispatch(updateCreativity({ id, inputName, inputValue }));
  };

  const cleanCreativty = () => {
    const list = [...creativitiesList];

    list[creativityIndex] = {
      ...creativity, title: '', description: '', icon: '', image: '',
    };

    dispatch(updateCreativitiesList(list));
  };

  const resetCreativty = () => {
    const list = [...creativitiesList];
    const newStatus = { ...creativitiesStatus };

    list[creativityIndex] = { ...creativity, ...originalCreativity };
    newStatus[id] = originalCreativity.status;

    dispatch(updateCreativitiesList(list));
    dispatch(updateCreativitiesStatus(newStatus));
  };

  const toggleStatus = () => {
    const newStatus = { ...creativitiesStatus };

    if (status === 'running') {
      newStatus[id] = 'paused_manually';
    } else {
      newStatus[id] = 'running';
    }

    dispatch(updateCreativitiesStatus(newStatus));
  };

  const { validateField } = useCreateValidation();

  const validateInput = () => {
    validateField('creativities', 'creativities');
  };

  useEffect(() => {
    if (title !== undefined) {
      validateInput();
    }
  }, [title, description, icon, image, status]);

  useEffect(() => {
    setOriginalCreativity({
      title, description, icon, image, status,
    });
  }, []);

  const inputPrefix = `${__isNew__ ? 'new_' : ''}creativity`;
  const rejectedError = status === 'rejected' && !icon.startsWith('data:image/') && 'Please change your image to comply with our moderation.';

  return (
    <AnimateHeight height={isSelected ? 'auto' : 0}>
      <Creativity>
        <Content>
          <MainContent>
            {
              (isSelected || iconAllowed) && (
                iconAllowed && (
                  <DropImageInput
                    error={creativityErrors?.icon || rejectedError}
                    title="Icon"
                    description={(
                      <>
                        From 192x192px up to 500x500px
                        <br />
                        {' '}
                        .jpg or .png - 500KB maximum
                      </>
)}
                    id={`${inputPrefix}-${id}-icon`}
                    name={`${inputPrefix}[${id}][icon]`}
                    fileTypesAllowed={fileTypesAllowed}
                    maxFileSizeInKb={500}
                    imageLimitation={{
                      ratio: 1,
                      maxWidth: 500,
                      maxHeight: 500,
                      minWidth: 192,
                      minHeight: 192,
                    }}
                    style={{ width: 154, minHeight: 154 }}
                    value={icon}
                    onRemove={() => {
                      handleCreativityChange('icon', '');
                      handleCreativityChange('iconFileName', '');
                    }}
                    onAdd={(imageBase64, fileName) => {
                      handleCreativityChange('icon', imageBase64);
                      handleCreativityChange('iconFileName', fileName);
                    }}
                    required
                  />
                )
              )
            }
            <TextContent
              id={`${inputPrefix}-${id}-text`}
            >
              <Input
                error={creativityErrors?.title}
                name={`${inputPrefix}[${id}][title]`}
                placeholder="New Title"
                // ToDo: maxlength dinamic
                maxLength={(true) ? '36' : '72'}
                value={title || ''}
                id={`${inputPrefix}-${id}-title`}
                onChange={(e) => handleCreativityChange('title', e.target.value)}
                onBlur={() => validateInput()}
              />
              <Input
                error={creativityErrors?.description}
                type="textarea"
                name={`${inputPrefix}[${id}][description]`}
                id={`${inputPrefix}-${id}-description`}
                placeholder="New Description"
                autoHeight
                maxLength={80}
                style={{ resize: 'none' }}
                value={description || ''}
                onChange={(e) => handleCreativityChange('description', e.target.value)}
                onBlur={() => validateInput()}
              />
            </TextContent>
          </MainContent>
          {
            (isSelected || imageAllowed) && (
              imageAllowed && (
                <DropImageInput
                  title="Optional Image"
                  description={(
                    <>
                      From 360x240px up to 1024x512px
                      <br />
                      {' '}
                      .jpg or .png - 500KB maximum
                    </>
)}
                  name={`${inputPrefix}[${id}][image]`}
                  id={`${inputPrefix}-${id}-image`}
                  fileTypesAllowed={fileTypesAllowed}
                  maxFileSizeInKb={500}
                  imageLimitation={{
                    ratio: 2,
                    maxWidth: 1024,
                    maxHeight: 512,
                    minWidth: 360,
                    minHeight: 240,
                  }}
                  style={{ width: 470, minHeight: 235 }}
                  value={image}
                  onRemove={() => {
                    handleCreativityChange('image', '');
                    handleCreativityChange('imageFileName', '');
                  }}
                  onAdd={(imageBase64, fileName) => {
                    handleCreativityChange('image', imageBase64);
                    handleCreativityChange('imageFileName', fileName);
                  }}
                />
              )
            )
          }
          <HiddenInput
            name={`${inputPrefix}[${id}][status]`}
            value={!__isNew__ ? status || '' : 'running'}
            readOnly
          />
        </Content>
        <Actions>
          {
            !__isNew__ && status !== 'rejected' && status !== 'moderation' && (
              status !== 'running'
                ? (
                  <Button color="#5cb85c" onClick={toggleStatus}>
                    <FontAwesomeIcon icon={playIcon} />
                    {' '}
                    Resume
                  </Button>
                )
                : (
                  <Button color="#000" onClick={toggleStatus}>
                    <FontAwesomeIcon icon={pauseIcon} />
                    {' '}
                    Pause
                  </Button>
                )
            )
          }
          {
            creativitiesList?.length > 1 && (
            <Button
              onClick={deleteCreativity}
              color="#DC3545"
            >
              <FontAwesomeIcon icon={trashIcon} />
              {' '}
              Delete
            </Button>
            )
          }
          {
            __isNew__
              ? (
                <Button color="#9d9da0" onClick={cleanCreativty}>
                  <FontAwesomeIcon icon={cleanIcon} />
                  {' '}
                  Clean
                </Button>
              )
              : (
                <Button color="#9d9da0" onClick={resetCreativty}>
                  <FontAwesomeIcon icon={cleanIcon} />
                  {' '}
                  Reset
                </Button>
              )
          }
        </Actions>
      </Creativity>
    </AnimateHeight>
  );
}

export default CreativityEditor;
