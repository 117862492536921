import styled, { css } from 'styled-components/macro';
import { FlexContainer } from '../../../../common/components/styles/Flex';

export const Title = styled.h1`
    font-size: 32px;
    font-weight: 500;
    color: #212529;
`;

export const NumberOfCampaignsBadge = styled.div`
    width: fit-content;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #495057;
    background-color: #F1F3F8;
    padding: 0 8px;
    border-radius: 8px;
    margin: 3px 0 17px;
`;

export const ResultsNumberAndBulkBttnsContainer = styled(FlexContainer)`
    display: flex;
    justify-content: space-between;
    align-items: end;
`;

export const ResultsNumber = styled.span<{ isFetching: boolean }>`
    font-size: 14px;
    color: #989898;
    ${({ isFetching }) => isFetching && 'visibility: hidden'};
`;

interface ButtonsContainerProps {
    gap: number;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ gap }) => gap}px;
`;

interface ButtonProps {
    isDisabled?: boolean;
    isBulkAction?: boolean;
}

export const CommonButtonStyles = styled.button<ButtonProps>`
    ${({ isBulkAction }) => (css`
        width: ${isBulkAction ? 150 : 130}px; 
        height: ${isBulkAction ? 48 : 36}px;
    `)}

    font-size: 16px;
    font-weight: 500;
    color: #FFF;
    border-radius: 8px;

    ${({ isDisabled }) => (isDisabled && css`
        opacity: 0.37; 
        cursor: default;
    `)}
`;

export const ApproveButton = styled(CommonButtonStyles)`
    background-color: #4176EE;
    
    ${({ isDisabled }) => (!isDisabled && css`
        &:hover {
            background-color: #0044DF;
        }
    `)}
`;

export const RejectButton = styled(CommonButtonStyles)`
    color: #212529;
    background-color: #E9E9E9;
    
    ${({ isDisabled }) => (!isDisabled && css`
        &:hover {
            color: #FFF;
            background-color: #212529;
        }
    `)}
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px 0;
`;
