import formatNumber from '../../../common/utils/formatNumber';

interface GetViewContentParams {
    viewContent: number | undefined;
    clicks: number | undefined;
}

function getViewContent({ viewContent, clicks }: GetViewContentParams) {
  return (!!viewContent && !!clicks) ? `${(formatNumber({ number: (viewContent / clicks) * 100, numberOfDecimals: 2 }))}%` : '--';
}

export default getViewContent;
