import styled, { css } from 'styled-components';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.esm.js';
import CreatableSelect from 'react-select/creatable';
import Overlay from '../Overlay/index.jsx';
import { colors } from '../../../../common/components/styles/colors';

const animatedComponents = makeAnimated();

export const FieldSet = styled.fieldset<{noMargin?: any}>`
  padding: 1rem 2rem;
  background-color: #fff;
  margin-bottom: 1.5rem;
  box-shadow: rgba(10, 0, 116, 0.1) 0px 10px 15px 0px;
  position: relative;
  border-radius: 10px;
  border: none;

  ${(props) => !props.noMargin && css`
  ${({ theme: { mediaQueries } }) => mediaQueries.md} {

      margin-right: 390px;
    }
  `}
  ${(props) => props.noMargin && css`
    margin: 1.5rem 0;
  `}
`;

export const Rule = styled(FieldSet).attrs({
  noMargin: true,
})`
  padding: 1rem;
  //overflow-x: hidden;
`;

export const WrapperDivs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RuleStats = styled.div`
  display: none;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  table {
    margin: 15px 0;
    background-color: #F2F2F2;
    border-radius: 5px;
    border-collapse: collapse;
    border-style: hidden;
    box-shadow: 0 0 0 1px #ccc;

    tr {
      td {
        font-size: 16px;
        border: 1px solid #ccc;
        padding: 7px 10px;
        color: #999;

        &:nth-child(2) {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    display: block;
  }
`;

export const Button2 = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid #000;
  border-radius: 8px;
  height: 2.4rem;
  padding: 0 .75rem;
`;

export const IconButton = styled(Button2)<{bgColor?: string}>`
  border: none;
  height: auto;
  padding: 5px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000')};
  transition: .4s;

  &:hover {
    color: ${(props) => (props.bgColor ? props.bgColor : '#000')};
    background-color: ${(props) => (props.color ? props.color : '#fff')};
  }
`;

export const IfWithinWrapper = styled.div`
  display: flex;
  width: 160px;
  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    width: 175px;
  }

  > div {
    &:first-of-type {
      border-left: 1px solid #ccc;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-of-type {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export const Wrapper = styled.div<{noEdit?: any; padding?:number; withinIf?:boolean; children?: any}>`
  border: 1px solid #ccc;
  border-left: none;
  line-height: 20px;
  text-align: center;
  padding: ${(props) => props.padding || 0};
  white-space: nowrap;

  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  ${(props) => props.withinIf && css`
    flex: 1;
  `};

  ${({ noEdit, children }) => (noEdit ? css`
    padding: 5px 10px;
    background-color: #F2F2F2;
    color: #999;
    ${noEdit === 'first' && css`
      width: 55px;
      ${children && css`
        border-left: 1px solid #ccc;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      `};
    `}
  ` : css`
    display: flex;
    align-items: center;
    justify-content: center;
  `)};
`;

export const Paragraph = styled.p`
  margin: 5px 0;
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;

  div {
    border: 0.2rem solid grey;
    width: 2rem;
    height: 2rem;
    border-top-color: white;
    border-left-color: white;
    border-radius: 50%;
    animation: spinner 1s linear 0s infinite;
    max-width: 100%;
    max-height: 100%;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }

`;

export const StyledError = styled.div`
  background-color: #F8D7DA;
  border-radius: 8px;
  color: #721C24;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > svg {
    cursor: pointer;
  }
`;

export const ConditionWrapper = styled.div`
  display: flex;
  margin-bottom: .5rem;
`;

export const RuleSelect = styled(Select).attrs({
  classNamePrefix: 'select',
  components: animatedComponents,
  menuPlacement: 'auto',
  isSearchable: false,
})<{disabled?: any}>`
  width: 100%;

  .select__menu {
    min-width: 100%;
    width: unset;
    transform: translateX(-50%);
    left: 50%;
  }

  .select__placeholder, .select__single-value {
    position: relative;
    transform: none;
    top: unset;
    margin: 0;
    max-width: unset;
  }

  .select__control {
    border: none;
    box-shadow: none !important;
    min-height: unset;
    padding: 0 10px;
    cursor: pointer;
    height: 100%;
  }

  .select__option {
    white-space: nowrap;
  }

  .select__value-container {
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    justify-content: center;

    .select__single-value + div {
      padding: 0;
      margin: 0;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    padding: 0;
  }

  ${(props) => props.disabled && css`
    .select__control {
      pointer-events: none;
      cursor: auto;
      background-color: hsl(0, 0%, 95%);

      .select__single-value {
        color: #999;
      }
    }
  `};
`;

export const RuleInput = styled.input.attrs({})`
  text-align: center;
  border: none;
  outline: none;
  max-width: 100%;
  height: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    position: relative;
  }
`;

export const Button = styled.button.attrs({
  type: 'button',
})<{bgColor?: any}>`
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 0 8px -5px #000;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  line-height: 1;
  padding: 5px 10px;

  color: ${(props) => props.color || '#fff'};
  background-color: ${(props) => props.bgColor || colors.blue};
  transition: .4s;

  &:hover {
    box-shadow: 0 0 10px -2px #000;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
`;

const SelectStyles = css`
  width: 160px;
  margin: 0 10px;

  .select__control {
    min-height: unset;
    flex: 1;
  }

  .select__indicator {
    padding: 0 5px;
  }

  .select__value-container {
    flex-wrap: nowrap;
  }

  .select__placeholder, .select__single-value {
    position: relative;
    transform: none;
    top: unset;
    margin: 0;
    max-width: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .select__indicator-separator {
    display: none;
  }
`;

export const TemplateSelect = styled(Select).attrs({
  classNamePrefix: 'select',
  components: animatedComponents,
  menuPlacement: 'auto',
  isSearchable: false,
})`
  ${SelectStyles};
`;

export const TemplateNameSelect = styled(CreatableSelect).attrs({
  classNamePrefix: 'select',
  components: animatedComponents,
  menuPlacement: 'top',
})`
  ${SelectStyles};
  display: inline-flex;
  margin-left: 0;
  width: 400px;

`;

export const AutoOverlay = styled(Overlay)`
  border-radius: 12px;

  > div {
    border-radius: 12px;
    text-align: center;
    width: 300px;
    ${(props) => props.full && css`
      padding: 2rem;
      min-height: 200px;
    `}
  }
`;

export const OverlayButton = styled(Button)`
  border-radius: 10px;
  margin: 10px 20px;
  font-weight: 600;
  padding: 5px 20px;
`;
