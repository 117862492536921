import {
  useState, useCallback, type MouseEvent, type Dispatch, type SetStateAction, useEffect,
} from 'react';
import { ClickAwayListener, Fade, Divider } from '@mui/material';
import {
  StyledPopper, CustomColumnsBttn, PopperContainer, PopperTitle, CboxesContainer, PopperCboxRow,
} from './styles/CustomColumnsPopper';
import { FiltersFormControlLabel, StyledCheckbox } from './styles/Filters';

interface CustomColumnsProps {
    hiddenColumns: string[];
    setHiddenColumns: Dispatch<SetStateAction<string[]>>;
    hideableColumns: string[];
}

function CustomColumns({ hiddenColumns, setHiddenColumns, hideableColumns }: CustomColumnsProps) {
  const [openPopper, setOpenPopper] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCustomColumnsBttnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(event.currentTarget);
    setOpenPopper((prevState) => !prevState);
  };

  useEffect(() => {
    if (!openPopper) {
      setPopperAnchorEl(null);
    }
  }, [openPopper]);

  const getCheckboxesArr = useCallback(() => {
    const checkboxesArr = [(
      <PopperCboxRow key="all-columns">
        <FiltersFormControlLabel
          label="All columns"
          control={(
            <StyledCheckbox
              checked={!hiddenColumns.length}
              onChange={() => {
                if (hiddenColumns.length) {
                  setHiddenColumns([]);
                } else {
                  setHiddenColumns(hideableColumns);
                }
              }}
            />
          )}
        />
      </PopperCboxRow>
    )];

    for (let i = 0; i < hideableColumns.length; i += 2) {
      checkboxesArr.push(
        <PopperCboxRow key={i}>
          {[0 + i, 1 + i].map((position) => (
            hideableColumns[position] && (
              <div style={{ width: 200 }} key={position}>
                <FiltersFormControlLabel
                  label={hideableColumns[position]}
                  control={(
                    <StyledCheckbox
                      checked={!hiddenColumns.includes(hideableColumns[position])}
                      onChange={() => {
                        const currentColumn = hideableColumns[position];

                        if (hiddenColumns.includes(currentColumn)) {
                          setHiddenColumns(
                            (prevState) => prevState.filter((column) => column !== currentColumn),
                          );
                        } else {
                          setHiddenColumns((prevState) => ([...prevState, currentColumn]));
                        }
                      }}
                    />
                      )}
                />
              </div>
            )
          ))}
        </PopperCboxRow>,
      );
    }

    return checkboxesArr;
  }, [hiddenColumns.length]);

  return (
    <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
      <div>
        <CustomColumnsBttn onClick={handleCustomColumnsBttnClick}>
          <img src="/assets/column-selector-logo.svg" alt="Column selector" />
        </CustomColumnsBttn>
        <StyledPopper open={openPopper} anchorEl={popperAnchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <PopperContainer>
                <PopperTitle>Custom columns</PopperTitle>
                <Divider />
                <CboxesContainer>
                  {getCheckboxesArr()}
                </CboxesContainer>
              </PopperContainer>
            </Fade>
          )}
        </StyledPopper>
      </div>
    </ClickAwayListener>
  );
}

export default CustomColumns;
