import { useLayoutEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { useLazyGetUserDataQuery, useLazyLogInUserQuery } from '../../features/login/loginAPI';

export const useGetUserData = () => {
  const { isUserAuthenticated, user: userData } = useAppSelector(
    ({ loginSlice: { isUserAuthenticated, user } }) => ({ isUserAuthenticated, user }),
    shallowEqual,
  );

  const [logInUser] = useLazyLogInUserQuery();
  const [getUserData] = useLazyGetUserDataQuery({ pollingInterval: 300000 });

  useLayoutEffect(() => {
    if (isUserAuthenticated && !userData?.name) {
      const cred = localStorage.getItem('cred');

      if (cred) {
        (async () => {
          const { isSuccess: logInSuccess } = await logInUser(cred);

          if (logInSuccess) {
            getUserData();
          }
        })();
      }
    }
  }, []);
};
