import styled from 'styled-components/macro';
import { Menu } from '@mui/material';

export const StyledCustomInput = styled.div`
    min-width: 285px;
    background-color: #edf1ff;
    padding: 2px 10px;
    border-radius: 5px;
`;

export const CalendarButton = styled.button`
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
    background-color: rgb(237, 241, 255);
    padding: 2px 10px;
    border-radius: 5px;
    border-radius: 10px;
    color: rgb(74, 118, 255);
    padding: 6px 12px;
`;

export const StyledMenu = styled(Menu)`
    &&& {
        top: 15px;
        left: -8px;

        .MuiButtonBase-root {
            font-family: Poppins, sans-serif !important;
            font-size: 15px;
        }
    }
`;
