/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import getCroppedImg from './cropImage.js';
import './styles/custom.css';
import './styles/pushground.css';

const maxZoom = 10;
const minZoom = 1;
const zoomStep = 0.5;

function ImageCropperModal(props) {
  const {
    show, onHide, imageSrc, onCrop, aspectRatio = 1, newImage = false, imageLimitation, fileName,
  } = props;

  const [crop, setCrop] = useState({ x: 50, y: 50 });
  const [zoom, setZoom] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [oldImage, setOldImage] = useState(null);

  const image = oldImage || imageSrc;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, imageLimitation);
      setOldImage(image);
      onCrop && onCrop(croppedImage, newImage, fileName);
      setInitialCroppedArea(croppedAreaPixels);
      onHide();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (newImage) {
      setOldImage(null);
      setZoom(1);
      setInitialCroppedArea(undefined);
    }
  }, [newImage]);

  return (
    <Modal
      show={show}
      onHide={showCroppedImage}
      size="xl"
      centered
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Modal.Header closeButton style={{ borderBottom: 'none' }}>
        <Modal.Title>
          Crop Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div style={{ height: 500, overflow: 'hidden', position: 'relative' }}>
          <Cropper
            initialCroppedAreaPixels={initialCroppedArea}
            image={image}
            crop={crop}
            zoom={zoom || 1}
            minZoom={minZoom}
            maxZoom={maxZoom}
            zoomSpeed={zoomStep}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{ mediaStyle: { maxWidth: '80%', maxHeight: '80%' } }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Form.Control
          type="range"
          value={zoom || 1}
          min={minZoom}
          max={maxZoom}
          step={zoomStep}
          onChange={(e) => setZoom(e.target.value)}
        />
        <Button style={{ whiteSpace: 'nowrap' }} onClick={showCroppedImage}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ImageCropperModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  onCrop: PropTypes.func,
  aspectRatio: PropTypes.number,
  newImage: PropTypes.bool,
};

export default ImageCropperModal;
