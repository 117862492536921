import React, {
  useState, useEffect, useLayoutEffect, useMemo, type MouseEvent,
} from 'react';
import { IconButton, type SelectChangeEvent } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import {
  useAllowOrRejectUrlMutation, useAllowOrRejectCreativitiesMutation, useBanUserMutation,
  useChangeCategoryMutation,
} from '../redux/moderationAPI';
import { changeModerationStatus } from '../redux/moderationSlice';
import { ButtonsContainer, ApproveButton, RejectButton } from './styles/Moderation.styled';
import {
  Card, CardHeader, CardHeaderText, UserTotalCampaigns, CampaignIdLink, CardBody,
  CampaignStatusIcon, BasicInfoTooltip, LandingPageWrapper, UpperSectionContainer,
  SectionTitleAndTagContainer, SectionTitle, LandingPageTag, AccordionArrow,
  DomainStatusIconConatainer, UrlContainer, StyledUrl, UrlApproveOrRejectButton,
  TagsContainer, Tag, ScreenshotContainer, VerticalWrapper, VerticalTag, VerticalSelect,
  VerticalSelectOption, CreativitiesWrapper, NumberOfCreativities, CreativitiesContainer,
  CreativityLogoAndTextContainer, StyledCheckbox, CreativityLogo, CreativityTextContainer,
  CreativityTitle, CreativityImg, CardFooter, BanUserIcon, CardHeaderRightContainer,
} from './styles/ModerationCard.styled';
import { FlexContainer } from '../../../common/components/styles/Flex';
import RejectDialog from './RejectDialog';
import getCheckedItems from '../../../common/utils/getCheckedItems';
import { urlApproveOrRejectBttnIcons, domainStatusIcons } from '../data/moderationCardIcons';
import type { ModerationItemData } from '../types/moderationSliceTypes';
import type { UseSelectedCardsStateHook } from '../Moderation';

interface ModerationCardProps {
  moderationCardData: ModerationItemData;
  useSelectedCards: UseSelectedCardsStateHook;
  setIdFilter(arg0: string): void;
  categoriesOptions: Array<string>;
}

type ModerateCreativitiesParams = { method: string; reasons?: Array<string>; }

function ModerationCard({
  moderationCardData, useSelectedCards, setIdFilter, categoriesOptions,
}: ModerationCardProps) {
  const dispatch = useAppDispatch();

  const {
    user: {
      id: userId,
      name: userName,
      totalCampaigns: userTotalCampaigns,
      balance: userBalance,
    },
    campaign: {
      isPushgroundPlus,
      isActive: isCampaignActive,
      id: moderationId,
      campaignId,
      basicInfo: campaignBasicInfo,
      currentUrlStatus,
      url,
      urlTags,
      screenshotUrl,
      isUrlModerated,
      creativities: campaignCreativities,
      category: campaignCategory,
      isModerated,
    },
  } = moderationCardData;

  const [selectedCards, setSelectedCards] = useSelectedCards();
  const isCardSelected = !!selectedCards.find((id) => id === moderationId);

  const handleCardClick = () => {
    const isSelectingText = !!document.getSelection()?.toString();

    if (isSelectingText) {
      return;
    }

    setSelectedCards(
      isCardSelected
        ? selectedCards.filter((id) => id !== moderationId)
        : [...selectedCards, moderationId],
    );
  };

  const handleUserTotalCampaignsClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIdFilter(userId.toString());
  };

  const getUrlTags = (tagsType: string, tagsArray: Array<string> | null) => (
    tagsArray?.map((tag, index) => (
      <Tag tagType={tagsType} key={index}>{tag}</Tag>
    ))
  );

  const [isAccordionOpen, setIsAccordionOpen] = useState<{[key: string]: boolean}>(
    { landingPage: true, vertical: false },
  );

  const handleAccordionArrowClick = (event: MouseEvent<HTMLElement>, section: string) => {
    event.stopPropagation();
    setIsAccordionOpen((prevState) => (
      { ...prevState, [section]: !prevState[section] }));
  };

  const pgPlusCreativityCboxesInitialState = useMemo(
    () => campaignCreativities.reduce((acc, curr) => ({ ...acc, [curr.id]: false }), {}),
    [campaignCreativities.length],
  );

  const [
    checkedCreativities, setCheckedCreativities,
  ] = useState<{[key: string]: boolean}>(pgPlusCreativityCboxesInitialState);

  useEffect(() => {
    setCheckedCreativities(pgPlusCreativityCboxesInitialState);
  }, [campaignCreativities.length]);

  const handleChange = (creativityId: number) => {
    setCheckedCreativities(
      (prevState) => ({ ...prevState, [creativityId]: !prevState[creativityId] }),
    );
  };

  const [rejectDialog, setRejectDialog] = useState({ isOpen: false, type: 'campaign' });

  const [banUser] = useBanUserMutation();
  const [allowOrRejectUrl] = useAllowOrRejectUrlMutation();
  const [allowOrRejectCreativities] = useAllowOrRejectCreativitiesMutation();
  const [changeCategory] = useChangeCategoryMutation();

  const moderateCreativities = ({ method, reasons }: ModerateCreativitiesParams) => {
    if (!campaignCreativities.length) {
      dispatch(changeModerationStatus({ campaignId: moderationId }));
      return;
    }

    const checkedCreativitiesIdsArr = getCheckedItems(checkedCreativities).map((id) => +id);
    const allCreativitiesIdsArr = campaignCreativities.map((creativity) => creativity.id);

    const requestBodyCreativitiesArr = checkedCreativitiesIdsArr.length
      ? checkedCreativitiesIdsArr
      : allCreativitiesIdsArr;

    allowOrRejectCreativities({
      method,
      body: { list: requestBodyCreativitiesArr, ...(!!reasons && { reason: reasons[0] }) },
      isModerationComplete: requestBodyCreativitiesArr.length === allCreativitiesIdsArr.length,
      moderationId,
    });
  };

  const handleCreativitiesBttnsClick = ({ method }: { method: string }) => {
    const numberOfCreativities = campaignCreativities.length;

    if (!isUrlModerated) {
      allowOrRejectUrl({
        method: currentUrlStatus !== 'dangerous' ? 'PUT' : 'DELETE',
        body: {
          list: [moderationId],
          ...(currentUrlStatus === 'dangerous' && { reasons: Object.values(urlTags).flat() }),
        },
        isModerationComplete: !numberOfCreativities,
      });
    }

    if (numberOfCreativities) {
      switch (method) {
        case 'PUT': {
          moderateCreativities({ method: 'PUT' });
          break;
        }
        case 'DELETE': {
          setRejectDialog({ isOpen: true, type: 'creativity' });
          break;
        }
        default:
      }
    } else if (isUrlModerated) {
      dispatch(changeModerationStatus({ campaignId: moderationId }));
    }
  };

  const handleUrlBttnClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (currentUrlStatus !== 'dangerous') {
      setRejectDialog({ isOpen: true, type: 'campaign' });
    } else {
      allowOrRejectUrl({
        method: 'PUT',
        body: { list: [moderationId] },
      });
    }
  };

  const handleRejectDialogBttnClick = ({ reasons }: { reasons: Array<string> }) => {
    switch (rejectDialog.type) {
      case 'campaign': {
        allowOrRejectUrl({
          method: 'DELETE',
          body: { list: [moderationId], reasons },
        });
        break;
      }
      case 'creativity': {
        moderateCreativities({ method: 'DELETE', reasons });
        break;
      }
      default:
    }
  };

  const handleVerticalChange = (event: SelectChangeEvent<unknown>) => {
    changeCategory({ category: event.target.value, moderationId });
  };

  useLayoutEffect(() => {
    if (userId === 5) {
      allowOrRejectUrl({
        method: 'DELETE',
        body: { list: [moderationId], reasons: ['Demo account'] },
      });

      moderateCreativities({ method: 'DELETE', reasons: ['Demo account'] });
    }
  }, []);

  const [isModeratedTransitionComplete, setIsModeratedTransitionComplete] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isModerated) {
        setIsModeratedTransitionComplete(true);
      }
    }, 380);

    return () => { clearTimeout(timeoutId); };
  }, [isModerated]);

  return (
    <>
      <Card
        onClick={handleCardClick}
        isSelected={isCardSelected}
        isModerated={isModerated}
        isModeratedTransitionComplete={isModeratedTransitionComplete}
      >
        <CardHeader isPushgroundPlus={isPushgroundPlus}>
          <CardHeaderText>
            (
            <UserTotalCampaigns onClick={handleUserTotalCampaignsClick}>
              {userTotalCampaigns}
            </UserTotalCampaigns>
            )
            {` ${userId} - ${userName}`}
          </CardHeaderText>
          <CardHeaderRightContainer>
            <FlexContainer>
              <CampaignStatusIcon size={12} color={isCampaignActive ? '#56E497' : '#989696'} />
              <CardHeaderText data-tip data-for={`basic-info-${campaignId}-tooltip`}>
                {'id: '}
                <CampaignIdLink
                  href={`https://app.pushground.com/campaign/edit/${campaignId}`}
                  target="_blank"
                  isPushgroundPlus={isPushgroundPlus}
                  onClick={(event) => event.stopPropagation()}
                >
                  {campaignId}
                </CampaignIdLink>
              </CardHeaderText>
              <BasicInfoTooltip id={`basic-info-${campaignId}-tooltip`}>
                <ul>
                  {campaignBasicInfo.map((campaignInfo, index) => (
                    <li key={index}>{campaignInfo}</li>
                  ))}
                </ul>
              </BasicInfoTooltip>
            </FlexContainer>
            <CardHeaderText>
              {`Balance: ${userBalance} $`}
            </CardHeaderText>
          </CardHeaderRightContainer>
        </CardHeader>
        <CardBody>
          <LandingPageWrapper $isAccordionOpen={isAccordionOpen.landingPage}>
            <UpperSectionContainer>
              <SectionTitleAndTagContainer>
                <SectionTitle>Landing Page</SectionTitle>
                <LandingPageTag isUrlApproved={currentUrlStatus !== 'dangerous'} $isAccordionOpen={isAccordionOpen.landingPage}>
                  {currentUrlStatus !== 'dangerous' ? 'Approved' : 'Rejected'}
                </LandingPageTag>
              </SectionTitleAndTagContainer>
              <IconButton size="small" onClick={(event) => handleAccordionArrowClick(event, 'landingPage')}>
                <AccordionArrow $isAccordionOpen={isAccordionOpen.landingPage} />
              </IconButton>
            </UpperSectionContainer>
            <UrlContainer>
              <DomainStatusIconConatainer>
                {domainStatusIcons[currentUrlStatus]}
              </DomainStatusIconConatainer>
              <StyledUrl href={url} target="_blank" onClick={(event) => event.stopPropagation()}>
                {url}
              </StyledUrl>
              <UrlApproveOrRejectButton onClick={handleUrlBttnClick} isRejectButton={currentUrlStatus === 'dangerous'}>
                {urlApproveOrRejectBttnIcons[currentUrlStatus !== 'dangerous' ? 'reject' : 'approve']}
              </UrlApproveOrRejectButton>
            </UrlContainer>
            <TagsContainer>
              {currentUrlStatus !== 'good' && Object.entries(urlTags).map(([tagsType, tagsArray]) => (
                getUrlTags(tagsType, tagsArray)
              ))}
            </TagsContainer>
            <ScreenshotContainer>
              <img src={screenshotUrl} alt="No screenshot available" />
            </ScreenshotContainer>
          </LandingPageWrapper>
          <VerticalWrapper $isAccordionOpen={isAccordionOpen.vertical}>
            <UpperSectionContainer>
              <SectionTitleAndTagContainer>
                <SectionTitle>Vertical</SectionTitle>
                <VerticalTag $isAccordionOpen={isAccordionOpen.vertical}>
                  {campaignCategory}
                </VerticalTag>
              </SectionTitleAndTagContainer>
              <IconButton size="small" onClick={(event) => handleAccordionArrowClick(event, 'vertical')}>
                <AccordionArrow $isAccordionOpen={isAccordionOpen.vertical} />
              </IconButton>
            </UpperSectionContainer>
            {!!categoriesOptions && (
              <VerticalSelect
                value={campaignCategory}
                onChange={handleVerticalChange}
                onClick={(event) => event.stopPropagation()}
              >
                {categoriesOptions.map((category) => (
                  <VerticalSelectOption value={category} key={category}>
                    {category}
                  </VerticalSelectOption>
                ))}
              </VerticalSelect>
            )}
          </VerticalWrapper>
          <CreativitiesWrapper>
            <SectionTitle>Creativities</SectionTitle>
            {!campaignCreativities.length && (
              <NumberOfCreativities>0 creativities to moderate</NumberOfCreativities>
            )}
            {campaignCreativities.map(({
              id: creativityId, logo, title, subtitle, image,
            }) => (
              <CreativitiesContainer key={creativityId}>
                <CreativityLogoAndTextContainer>
                  <StyledCheckbox
                    checked={checkedCreativities[creativityId]}
                    onChange={() => handleChange(creativityId)}
                    onClick={(event) => event.stopPropagation()}
                  />
                  <CreativityLogo src={logo} />
                  <CreativityTextContainer>
                    <CreativityTitle>{title}</CreativityTitle>
                    <p>{subtitle}</p>
                  </CreativityTextContainer>
                </CreativityLogoAndTextContainer>
                {!!image && <CreativityImg src={image} />}
              </CreativitiesContainer>
            ))}
          </CreativitiesWrapper>
        </CardBody>
        <CardFooter>
          <BanUserIcon
            size={30}
            onClick={(event) => {
              event.stopPropagation();
              banUser(userId);
            }}
          />
          <ButtonsContainer gap={16} onClick={(event) => event.stopPropagation()}>
            <RejectButton onClick={() => handleCreativitiesBttnsClick({ method: 'DELETE' })} isDisabled={false}>
              Reject
            </RejectButton>
            <ApproveButton onClick={() => handleCreativitiesBttnsClick({ method: 'PUT' })} isDisabled={false}>
              Approve
            </ApproveButton>
          </ButtonsContainer>
        </CardFooter>
      </Card>
      <RejectDialog
        rejectionType={rejectDialog.type as 'campaign' | 'creativity'}
        open={rejectDialog.isOpen}
        setOpen={(isOpen) => setRejectDialog((prevState) => ({ ...prevState, isOpen }))}
        rejectionAction={handleRejectDialogBttnClick}
      />
    </>
  );
}

export default ModerationCard;
