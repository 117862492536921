import React, { useEffect, useState } from 'react';
import type {
  FormEvent, ChangeEvent, SetStateAction, Dispatch,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { useLazyGetUserDataQuery, useLazyLogInUserQuery } from './loginAPI';
import {
  SectionWrapper, Container, Title, Form, FormLabel, CredentialsErrorContainer,
  CredentialsErrorMessage, Input, ForgotYourPasswordLink, SignUpLink, Button,
} from './Login.styled';
import TipsAside from './TipsAside';

const useShowError = (isError: boolean) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(isError);
  }, [isError]);

  return [showError, setShowError] as [boolean, Dispatch<SetStateAction<boolean>>];
};

function Login() {
  const [inputsValue, setInputsValue] = useState<{[key: string]: string}>({ email: '', password: '' });
  const [logInUser, { isSuccess, isError }] = useLazyLogInUserQuery();
  const [getUserData] = useLazyGetUserDataQuery({ pollingInterval: 300000 });

  const [showError, setShowError] = useShowError(isError);

  const [isRememberMeChecked, setIsRememeberMeChecked] = useState(false);

  const navigate = useNavigate();

  const { isUserAuthenticated, isUserDataLoaded } = useAppSelector(
    ({ loginSlice: { isUserAuthenticated, user } }) => ({
      isUserAuthenticated, isUserDataLoaded: !!user?.name,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if ((isSuccess || isUserAuthenticated) && isUserDataLoaded) {
      navigate('/campaigns');
    }
  }, [isSuccess, isUserAuthenticated, isUserDataLoaded]);

  const handleChange = ({ target: { type, value } }: ChangeEvent<HTMLInputElement>) => {
    setInputsValue((prevState) => ({ ...prevState, [type]: value }));

    if (showError) {
      setShowError(false);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const { email, password } = inputsValue;
    const base64Credentials = btoa(`${email}:${password}`);

    const { isSuccess: logInSuccess } = await logInUser(base64Credentials);

    if (logInSuccess) {
      getUserData();
    }
  };

  const getInput = ({ type }: { type: string }) => (
    <Input
      type={type}
      value={inputsValue[type]}
      onChange={handleChange}
      required
      isError={showError}
    />
  );

  const credentialsErrorMessage = (
    <CredentialsErrorContainer showErrorMsg={showError}>
      <CredentialsErrorMessage>
        These credentials do not match our records.
      </CredentialsErrorMessage>
    </CredentialsErrorContainer>
  );

  return (
    <SectionWrapper>
      <Container>
        <Title>Welcome to Pushground.</Title>
        <Form onSubmit={handleSubmit}>
          <FormLabel>Log in</FormLabel>
          {credentialsErrorMessage}
          {getInput({ type: 'email' })}
          {getInput({ type: 'password' })}
          <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="remember-me-cbox" style={{ cursor: 'pointer', fontSize: 14 }}>
              <input type="checkbox" id="remember-me-cbox" style={{ marginRight: 5 }} />
              Remember me
            </label>
            <ForgotYourPasswordLink type="button">Forgot your password?</ForgotYourPasswordLink>
          </div>
          <Button type="submit">Log in</Button>
        </Form>
        <div style={{ fontSize: 16, marginTop: 25 }}>
          Dont have an account?
          <SignUpLink type="button"> Sign up</SignUpLink>
        </div>
      </Container>
      <TipsAside />
    </SectionWrapper>
  );
}

export default Login;
