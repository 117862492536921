/* eslint-disable no-shadow */
/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import {
  updateAutomaticRules, updateAutomaticActions, updateRuleTemplate, deleteRuleTemplate,
  resetRuleTemplateMessages,
} from '../redux/createCampaignSlice';
import {
  AutoOverlay, Button, TemplateNameSelect, TemplateSelect, Spinner, Paragraph,
} from './styles/AutomaticRules';
import Rule from './rule.jsx';
import Error from './FieldError.jsx';
import { transformTemplateToAPI } from './ruleTemplates.js';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useDeleteAutomaticRulesTemplateMutation, useSaveAutomaticRulesTemplateMutation } from '../redux/createCampaignAPI';

function AutomaticRules(props: any) {
  const {
    rules,
    rulesactions,
    templates,
    templateError,
    templateUpdated,
    templateLoading,
    automaticRules,
  } = useAppSelector(({ createCampaignSlice: { automaticRules } }: any) => ({
    rules: Array.isArray(automaticRules?.rules) && automaticRules.rules.map(
      (r:any) => ({ ...r, actions: automaticRules?.ruleactions[r.id] }),
    ),
    rulesactions: automaticRules.ruleactions,
    templates: { ...automaticRules.ruletemplates },
    templateError: automaticRules.templatesUpdatedError,
    templateUpdated: automaticRules.templatesUpdatedMessage,
    templateLoading: automaticRules.loading,
    automaticRules,
  }));
  const dispatch = useAppDispatch();
  const [idNewRule, setIdNewRule] = useState(0);
  const [templateSelected, setTemplateSelected] = useState<any>(null);
  const [templateName, setTemplateName] = useState<any>(null);

  const newRule = {
    entity_check: 'offer',
    conditions: [
      {
        metric: 'roi', clause: 'greater', value: '0', period: 'today',
      },
      {
        metric: 'roi', clause: 'greater', value: '0', period: 'today',
      },
    ],
    frequency: '240',
  };

  const addNewRule = (rule: any = null) => {
    const oldRules = rules || [];
    const oldRulesActions = rulesactions || {};

    const ruleToAdd = rule || newRule;
    const actionToAdd = rule?.actions || { action: 'pause' };

    dispatch(updateAutomaticRules([...oldRules, {
      ...ruleToAdd,
      id: idNewRule,
      actions: actionToAdd,
      conditions: JSON.stringify(ruleToAdd.conditions),
      active: 1,
      __isNew__: true,
    }]));
    dispatch(updateAutomaticActions({ ...oldRulesActions, [idNewRule]: actionToAdd }));

    setIdNewRule(idNewRule + 1);
  };

  const removeRule = (id: any) => {
    dispatch(updateAutomaticRules(rules.filter((r: any) => r.id !== id)));
  };

  const importTemplate = () => {
    if (templateSelected) {
      const oldRules = rules || [];
      const oldRulesActions = rulesactions || {};

      const newRules = templates[templateSelected.value].map((r: any, i: any) => ({
        ...r,
        id: idNewRule + i,
        conditions: JSON.stringify(r.conditions),
        active: 1,
        __isNew__: true,
      }));

      dispatch(updateAutomaticRules([...oldRules, ...newRules]));
      dispatch(updateAutomaticActions(
        {
          ...oldRulesActions,
          ...newRules.reduce((old: any, r: any) => ({ ...old, [r.id]: r.actions }), {}),
        },
      ));
      setIdNewRule(idNewRule + templates[templateSelected.value].length);
    }
  };

  const [saveAutomaticRule] = useSaveAutomaticRulesTemplateMutation();

  const saveTemplate = () => {
    const template = {
      name: templateName.value,
      ruleTemplates: rules.map(transformTemplateToAPI),
    };

    if (templateName) {
      dispatch(updateRuleTemplate(null));
      saveAutomaticRule(template);
    }
  };

  const [deleteAutomaticRule] = useDeleteAutomaticRulesTemplateMutation();

  const deleteTemplate = () => {
    if (templateSelected) {
      const template = { id: templates[templateName?.value]?.[0]?.template_id };
      dispatch(deleteRuleTemplate(null));
      deleteAutomaticRule({ ruleIds: [template.id] });
    }
  };

  return (

    <>
      <Button onClick={() => addNewRule()} style={{ margin: '10px 10px 20px 0' }}>Add New Rule</Button>

      {
            Object.keys(templates).length > 0 && (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              or select a template to import
              <TemplateSelect
                value={templateSelected}
                onChange={(template: any) => setTemplateSelected(template)}
                options={Object.keys(templates).map((k) => ({ value: k, label: k }))}
              />
              <Button
                disabled={!templateSelected}
                onClick={importTemplate}
                style={{ marginLeft: 10 }}
              >
                Import
              </Button>
            </div>
            )
          }

      {
          Array.isArray(rules) && rules?.map((r) => (
            <Rule
              automaticRules={automaticRules}
              key={r.id}
              rule={r}
              onRemove={() => removeRule(r.id)}
              budget={50}
            />
          )) || null
        }

      {
          Array.isArray(rules) && rules.length > 0
          && <Button onClick={() => addNewRule()} style={{ margin: '10px 10px 20px 0' }}>Add New Rule</Button>
        }

      {
          Array.isArray(rules) && rules.length > 0 && (
          <>
            <Paragraph>Save as a template:</Paragraph>
            <Error
              error={templateError}
            />
            <TemplateNameSelect
              placeholder="Search or create a template"
              value={templateName}
              onChange={(name) => setTemplateName(name)}
              options={Object.keys(templates).map((k) => ({ value: k, label: k }))}
            />
            <Button
              disabled={!templateName}
              onClick={saveTemplate}
            >
              {Object.keys(templates).includes(templateName?.value) ? 'Update' : 'Save'}
              &nbsp;Template
            </Button>
            {
              templateName && Object.keys(templates).includes(templateName?.value) && (
              <Button
                bgColor="#DB3847"
                style={{ marginLeft: 10 }}
                onClick={deleteTemplate}
              >
                Delete&nbsp;Template
              </Button>
              )
            }

            <AutoOverlay
              full
              isOpen={templateUpdated || templateError || templateLoading}
              onClose={() => {
                dispatch(resetRuleTemplateMessages());
                setTemplateName(null);
              }}
            >
              <div>
                {
                  templateUpdated || templateError || (
                    templateLoading && (
                    <Spinner>
                      <div />
                    </Spinner>
                    )
                  )
                }
              </div>
            </AutoOverlay>
          </>
          )
}
    </>
  );
}

export default AutomaticRules;
