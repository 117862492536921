const macros = [
  'click_id',
  'supply_id',
  'site_id',
  'source',
  'country',
  'campaign_id',
  'deviceName',
  'city',
  'language',
  'bid',
  'bidcpm',
  'creativity_id',
  'format',
  'userAge',
  'trafficType',
  'reach',
];

export default macros;
