import { createSlice } from '@reduxjs/toolkit';
import { userSettingsApi } from './userSettingsAPI';
import { createCampaignApi } from '../../create-campaign/redux/createCampaignAPI';
import { resetAllSlicesState } from '../../../common/utils/resetAllSlicesStateAction';

interface InitialState {
  profile: {},
  billing: {},
  blacklist: {},
  security: {},
  formData: { timezoneOptions: {}, categories: {}, countriesOptions: {} }
}

const initialState = {
  profile: {},
  billing: {},
  blacklist: {},
  security: {},
} as InitialState;

export const userSettingsSlice = createSlice({
  name: 'userSettingsSlice',
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      const { section, data } = action.payload;
      return { ...state, [section]: data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllSlicesState, () => initialState);
    builder.addMatcher(createCampaignApi.endpoints.getFormData.matchFulfilled, (state, action) => {
      state.formData = {
        timezoneOptions: action.payload,
        categories: action.payload,
        countriesOptions: action.payload,
      };
    });
  },
});

export const { updateSettings } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
