import { pushgroundApi } from '../../../app/services/pushgroundApi';
import formatNumber from '../../../common/utils/formatNumber';
import type { Creativity } from '../types/moderationSliceTypes';

export const moderationApi = pushgroundApi.injectEndpoints({
  endpoints: (builder) => ({
    getModerationList: builder.query({
      query: (rawFiltersData) => {
        const {
          nameOrId,
          accountManager: accountManagers,
          platform: platforms,
          accountRank: accountRanks,
          campaignType: campaigns,
          timeOfCreation,
        } = rawFiltersData;

        const requestBody = {
          ...(!!nameOrId && { nameOrId: +nameOrId || nameOrId }),
          ...(!!accountManagers && { accountManagers: [accountManagers] }),
          ...(!!platforms && { platforms: [platforms] }),
          ...(!!accountRanks && { accountRanks: [accountRanks] }),
          ...(!!campaigns && { campaigns: [campaigns] }),
          ...(!!timeOfCreation && { newestFirstSort: timeOfCreation === 'newest' }),
        };

        return ({
          url: 'v2/moderation/campaigns',
          method: 'POST',
          body: requestBody,
        });
      },
      transformResponse: (response: { list: Array<object>; totalCampaigns: number }, meta) => {
        if (response) {
          const usersTotalCampaigns: {[key: number]: number} = {};

          return {
            totalCampaigns: response.totalCampaigns,
            moderationList: response.list.map((item: any) => {
              const { campaign, user: currUser } = item;

              const basicInfo = Object.values(campaign.basicInfo).flat();
              const baseUrl = 'https://app.pushground.com';

              const creativities = campaign.creativities.map((creativity: Creativity) => {
                const { logo: logoPath, image: imagePath } = creativity;

                return {
                  ...creativity, logo: baseUrl + logoPath, image: imagePath && baseUrl + imagePath,
                };
              });

              const {
                urltags: urlTags, urlstatus, ...campaignRest
              } = campaign;
              const { dangerous, warning } = urlTags;
              const currentUrlStatus = dangerous?.length ? 'dangerous' : (warning?.length ? 'warning' : 'good');

              if (!usersTotalCampaigns[currUser.id]) {
                const currUserTotalCampaigns = response.list.filter(
                  (responseItem: any) => responseItem.user.id === currUser.id,
                ).length;

                usersTotalCampaigns[currUser.id] = currUserTotalCampaigns;
              }

              return {
                campaign: {
                  ...campaignRest,
                  basicInfo,
                  creativities,
                  currentUrlStatus,
                  urlTags,
                  isUrlModerated: false,
                  isModerated: false,
                },
                user: {
                  ...currUser,
                  totalCampaigns: usersTotalCampaigns[currUser.id],
                  balance: formatNumber({ number: currUser.balance.current, numberOfDecimals: 2 }),
                },
              };
            }),
          };
        }

        if (meta?.response?.status === 204) {
          return { moderationList: [] };
        }

        return response;
      },
      providesTags: (result, error, arg) => (Object.keys(arg).length ? ['ModerationList'] : []),
    }),
    allowOrRejectUrl: builder.mutation({
      query: ({ method, body }) => ({
        url: '/v2/moderation/campaigns/url',
        method,
        body,
      }),
      invalidatesTags: (result, error, arg) => (error?.status === 400 ? ['ModerationList'] : []),
    }),
    allowOrRejectCreativities: builder.mutation({
      query: ({ method, body }) => ({
        url: '/v2/moderation/creativities',
        method,
        body,
      }),
      invalidatesTags: (result, error, arg) => (error?.status === 400 ? ['ModerationList'] : []),
    }),
    getCategories: builder.query<any, void>({
      query: () => '/v2/lists/verticals',
      transformResponse: (response: { list: Array<{ name: string }> }) => (
        response.list.map((category) => category.name)
      ),
    }),
    changeCategory: builder.mutation({
      query: ({ category, moderationId }) => ({
        url: `/v2/moderation/campaigns/category/${category}`,
        method: 'PATCH',
        body: { list: [moderationId] },
      }),
    }),
    banUser: builder.mutation({
      query: (userId) => ({
        url: `/v2/moderation/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ModerationList'],
    }),
  }),
});

export const {
  useLazyGetModerationListQuery,
  useAllowOrRejectUrlMutation,
  useAllowOrRejectCreativitiesMutation,
  useLazyGetCategoriesQuery,
  useChangeCategoryMutation,
  useBanUserMutation,
} = moderationApi;
