import styled, { css } from 'styled-components/macro';
import { FaUserAltSlash, FaCircle, FaAngleUp } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { StyledSelect, StyledOption } from '../../../../common/components/styles/Select';

interface CardProps {
    isSelected?: boolean;
    isModerated: boolean;
    isModeratedTransitionComplete: boolean;
}

export const Card = styled.div<CardProps>`
    display: ${({ isModeratedTransitionComplete }) => (isModeratedTransitionComplete ? 'none' : 'flex')};
    flex-direction: column;
    width: 412px;
    height: 622px;
    flex-shrink: 0;
    border: 1px solid #CACFD5;
    border-radius: 8px;
    overflow: hidden;

    ${({ isSelected }) => (isSelected && css`
        border: 1px solid #212529;
        box-shadow: -39px 62px 29px rgba(25, 32, 56, 0.01), -22px 35px 25px rgba(25, 32, 56, 0.05), -10px 16px 18px rgba(25, 32, 56, 0.09), -2px 4px 10px rgba(25, 32, 56, 0.1), 0px 0px 0px rgba(25, 32, 56, 0.1);
    `)}

    width: ${({ isModerated }) => (isModerated ? 0 : 412)}px;
    opacity: ${({ isModerated }) => (isModerated ? 0 : 1)};
    transition: width .4s, opacity .4s;
`;

interface CardHeaderProps {
    isPushgroundPlus?: boolean;
}

export const CardHeaderSubtitle = styled.div`
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #495057;
`;

export const CardHeaderText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #212529;
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserTotalCampaigns = styled.span`
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const CampaignIdLink = styled.a<{ isPushgroundPlus: boolean }>`
    color: ${({ isPushgroundPlus }) => (isPushgroundPlus ? '#000' : '#FFF')};
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

export const CardHeader = styled.div<CardHeaderProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 81px;
    background: ${({ isPushgroundPlus }) => (isPushgroundPlus ? 'linear-gradient(123.36deg, #EFC166 20.1%, #F9E9A9 100%)' : 'linear-gradient(135deg, #721CC4 0%, #6656F8 100%)')} ;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 8px 24px rgba(25, 32, 56, 0.04);

    & ${CardHeaderSubtitle}, ${CardHeaderText} {
        color: ${({ isPushgroundPlus }) => (!isPushgroundPlus && '#FFF')}
    };
`;

export const TrustedUserTag = styled.div`
    width: 90px;
    height: 20px;
    background: #F9F9F9;
    border-radius: 70px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 473px;
`;

export const UserStatusImg = styled.img`
    position: relative;
    top: 6px;
    width: 22px;
    margin-right: 5px;
`;

export const CardHeaderRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const CampaignStatusIcon = styled(FaCircle)`
    align-self: flex-start;
    position: relative;
    right: 5px;
    top: 4px;
`;

export const BasicInfoTooltip = styled(ReactTooltip).attrs({
  effect: 'solid',
  place: 'bottom',
  delayShow: 100,
  delayHide: 100,
  backgroundColor: '#616161',
})`
    ul {
        padding-inline-start: 10px;

        li {
            text-transform: capitalize;
        }
    }
`;

export const SectionWrapper = styled.div`
    width: 396px;
    background-color: #F9F9F9;
    padding: 7px 10px 10px;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    transition: .4s;
    overflow: hidden;
`;

interface AccordionProps {
    $isAccordionOpen: boolean;
}

export const LandingPageWrapper = styled(SectionWrapper)<AccordionProps>`
    height: ${({ $isAccordionOpen }) => ($isAccordionOpen ? 280.3 : 40)}px;
    margin: 7px auto 0;
`;

export const UpperSectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
`;

export const SectionTitleAndTagContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SectionTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
`;

export const SectionTag = styled.span<AccordionProps>`
    height: 15px;
    color: #FFF;
    font-size: 10px;
    padding: 0px 5px;
    border-radius: 4px;
    opacity: ${({ $isAccordionOpen }) => ($isAccordionOpen ? 0 : 1)};
    transition: .4s;
`;

interface LandingPageTagProps {
    isUrlApproved: boolean;
}

export const LandingPageTag = styled(SectionTag)<LandingPageTagProps>`
    background-color: ${({ isUrlApproved }) => (isUrlApproved ? '#155724' : '#E45656')};
`;

export const AccordionArrow = styled(FaAngleUp).attrs({ size: 17 })<AccordionProps>`
    color: #64748B;
    transform: rotate(${({ $isAccordionOpen }) => ($isAccordionOpen ? 0 : 180)}deg);
    transition: .4s;
`;

export const DomainStatusIconConatainer = styled.div`
    height: 17px;
    margin: 0 5px 2px 10px;
`;

export const UrlContainer = styled.div`
    display: flex;
    align-items: center;
    width: 376px;
    height: 24px;
    background-color: #FFF;
    border: 1px solid #D9DDE5;
    border-radius: 4px;
`;

export const StyledUrl = styled.a`
    font-size: 10px;
    margin-right: auto;
    white-space: nowrap;
    overflow-x: auto;

    ${({ theme: { hiddenScrollbarStyles } }) => hiddenScrollbarStyles}
`;

interface UrlApproveOrRejectButtonProps {
    isRejectButton?: boolean;
}

export const UrlApproveOrRejectButton = styled.button<UrlApproveOrRejectButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 100%;
    border-left: 1px solid #D9DDE5;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-left: 10px;

    &:hover {
        background-color: ${({ isRejectButton }) => (isRejectButton ? '#F1F3F8' : '#F8D7DA')};
    }
`;

export const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 5px;
    height: 17px;
    max-width: fit-content;
    margin: 5px 0 7px;

    ${({ theme: { customScrollbarStyles } }) => customScrollbarStyles}
`;

interface TagProps {
    tagType: string;
}

export const Tag = styled.span<TagProps>`
    height: 17px;
    font-size: 10px;
    color: #212529;
    background-color: ${({ tagType }) => (tagType === 'dangerous' ? '#F8D7DA' : 'rgba(152, 152, 152, 0.25)')};
    padding: 0 7px;
    border: 1px solid ${({ tagType }) => (tagType === 'dangerous' ? '#d3605b' : '#989898')};
    border-radius: 100px;
`;

export const ScreenshotContainer = styled.div`
    width: 376px;
    height: 175px;
    border-radius: 2px;
    overflow-x: auto;
    
    ${({ theme: { customScrollbarStyles } }) => customScrollbarStyles}

    img {
        width : 371px;
    }
`;

export const VerticalWrapper = styled(SectionWrapper)<AccordionProps>`
    height: ${({ $isAccordionOpen }) => ($isAccordionOpen ? 85 : 40)}px;
    margin: 5px auto;
`;

export const VerticalTag = styled(SectionTag)`
    background-color: #4176EE;
`;

export const VerticalSelect = styled(StyledSelect)`
    && {
        width: 376px;
        height: 34px;
        background-color: #FFF;
        border-radius: 4px;
    }
`;

export const VerticalSelectOption = styled(StyledOption)`
`;

export const CreativitiesWrapper = styled(SectionWrapper)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    margin: 0 auto 7px;
    overflow-y: scroll;
    overflow-x: hidden;

    ${({ theme: { customScrollbarStyles } }) => customScrollbarStyles}
`;

export const NumberOfCreativities = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    text-align: center;
`;

export const CreativitiesContainer = styled.div`
    width: 374px;
    background-color: #FFF;
    padding: 10px 10px 0;
    border-radius: 8px;
`;

export const CreativityLogoAndTextContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    min-width: 15px;
    height: 15px;
    margin-right: 10px;
`;

export const CreativityLogo = styled.img`
    width: 58px;
    height: 58px;
    border-radius: 7px;
    object-fit: cover;
`;

export const CreativityTextContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    color: #212529;
    text-align: center;
    padding: 0 45px;
`;

export const CreativityTitle = styled.h4`
    font-weight: 600;
`;

interface CreativityImgProps {
    objectFit?: string;
}

export const CreativityImg = styled.img<CreativityImgProps>`
    width: 100%;
    height: 150px;
    border-radius: 7px;
    margin-bottom: 5px;
    object-fit: ${({ objectFit }) => objectFit || 'cover'} ;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    background: #F3F3F3;
    padding: 16px;
    border-top: 1px solid #CCD2D8;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px -12px 24px rgba(25, 32, 56, 0.06);
`;

export const BanUserIcon = styled(FaUserAltSlash)`
    color: #212529;
    cursor: pointer;

    &:hover {
        color: #000;
    }
`;
