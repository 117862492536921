import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

interface DashboardMainProps {
    isDashboardLoaded: boolean;
}

export const Main = styled.main<DashboardMainProps>`
    opacity: ${({ isDashboardLoaded }) => (isDashboardLoaded ? 1 : 0)};
    transition: .3s 0.7s ;
`;

export const StyledMenuLink = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 14.5px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    min-height: 43px;
    padding: 9.6px;
    border-radius: 4px;
    cursor: pointer;
    transition: .4s;
    
    &:hover {
        background-color: #2c2c2c;
    }
`;

export const MenuIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25px;
    text-align: center;
    margin-right: 14px; 

    > img {
      width: fit-content;
    }
`;

interface OpaqueBackgroundProps {
    show: boolean;
}

export const OpaqueBackground = styled.div<OpaqueBackgroundProps>`
      position: fixed;
      top: 62px;
      height: 100%;
      width: 100%;
      background-color: rgba(51, 51, 51, 0.2);
      opacity: ${(({ show }) => (show ? 1 : 0))};
      z-index: ${(({ show }) => (show ? 10 : 0))};
      pointer-events: ${(({ show }) => (show ? 'all' : 'none'))};
      transition: .4s;
  `;
