import React, {
  useState, useLayoutEffect, useRef, type SyntheticEvent, useEffect,
} from 'react';
import {
  Drawer, IconButton, Tabs, Tab,
} from '@mui/material';
import { FaToggleOn, FaToggleOff, FaPen } from 'react-icons/fa';
import moment from 'moment';
import {
  BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { ClipLoader } from 'react-spinners';
import {
  useUpdateCampaignMutation, useLazyGetCampaignQuery, useLazyGetCampaignsStatsQuery, type Campaign,
} from '../redux/campaignsAPI';
import { useAppSelector } from '../../../app/hooks';
import {
  Wrapper, StatusAndCreationDateContainer, Status, SubduedText, DataContainer, EditLink,
  TargetingBadge, PushgroundPlusBadge, ChartNoDataOrLoaderContainer,
} from './styles/DataDrawer';
import { StyledToggle } from './styles/Table';
import { StyledOption, StyledSelect } from '../../../common/components/styles/Select';
import { Divider } from '../../../common/components/styles/Divider';
import EditorPopover from './EditorPopover';
import Calendar from './Calendar';
import DataDrawerTable from './DataDrawerTable';
import usePopover from '../hooks/usePopover';
import formatNumber from '../../../common/utils/formatNumber';
import { metricOptions, timeZonesOptions } from '../data/dataDrawerSelectFiltersOptions';
import { userFreshnessOptions } from '../data/userFreshnessOptions';
import tabs from '../data/dataDrawerTabs';
import type { EditorPopoverDynamicData } from './Table';
import type { Leaf } from '../redux/campaignsSlice';

interface DataDrawerProps {
    isOpen: boolean;
    onClose(): void;
    campaign: Campaign | undefined;
    bidData: { maxBid: number | undefined; recommendedBid: number | undefined; };
}

function DataDrawer({
  isOpen, onClose, campaign, bidData,
}: DataDrawerProps) {
  const [getCampaignData, { currentData: campaignData }] = useLazyGetCampaignQuery();

  useLayoutEffect(() => {
    if (campaign?.id) {
      getCampaignData({ campaignId: campaign.id });
    }
  }, [campaign?.id]);

  const [updateCampaign] = useUpdateCampaignMutation();

  const {
    anchorEl, currPopover, handleAnchorElClick, setAnchorEl,
  } = usePopover();

  const editorPopoverDymanicData: {[key: string]: EditorPopoverDynamicData} = {
    name: { popoverTitle: 'Campaign Name', inputInitialState: campaign?.name, propertyToChange: 'name' },
    bid: { popoverTitle: 'Bid', inputInitialState: campaign?.bid, propertyToChange: 'bid' },
    dailyBudget: { popoverTitle: 'Daily Budget', inputInitialState: campaign?.dailyBudget, propertyToChange: 'dailyBudget' },
  };

  const [currentTab, setCurrentTab] = useState(0);

  const tabsRef = useRef<null | HTMLButtonElement>(null);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    tabsRef.current!.scrollIntoView({ behavior: 'smooth' });
  };

  const userTimeZone = useAppSelector(({ loginSlice: { user } }) => user?.timeZone);

  const [selectedMetric, setSelectedMetric] = useState(metricOptions[0]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    timeZonesOptions.find((timeZone) => timeZone === userTimeZone) || timeZonesOptions[0],
  );

  const [calendarStartDate, setCalendarStartDate] = useState<Date | null>(moment().startOf('day').toDate());
  const [calendarEndDate, setCalendarEndDate] = useState<Date | null>(moment().endOf('day').toDate());

  const [
    getCampaignsStats, { data: campaignStats, isFetching, isError },
  ] = useLazyGetCampaignsStatsQuery();

  const currDimension = tabs[currentTab].dimension;

  useLayoutEffect(() => {
    if (campaign) {
      const requestBody = {
        dateStart: moment(calendarStartDate).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: moment(calendarEndDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dimensions: ['offer', currDimension, ...(currDimension === 'source' ? ['supplyFeed'] : [])],
        filters: { offer: [campaign.id] },
        timeZone: selectedTimeZone,
      };

      getCampaignsStats({ body: requestBody, addResponseToStore: false });
    }
  }, [campaign?.id, currentTab, selectedTimeZone, calendarStartDate?.toDateString(),
    calendarEndDate?.toDateString()]);

  const chartData = campaignStats?.leafs.map((leaf: Leaf) => {
    const dimensionPropName = leaf.dimensions[tabs[currentTab].dimension];

    const chartDataName: {[key: string]: string} = {
      userAge: userFreshnessOptions[+dimensionPropName],
      day: moment(new Date(dimensionPropName)).format('MMM D'),
    };

    const chartDataValue: {[key: string]: number} = {
      viewContent: leaf.metrics.clicks && (leaf.metrics.viewContent / leaf.metrics.clicks) * 100,
    };

    return ({
      name: chartDataName[tabs[currentTab].dimension] || dimensionPropName,
      [selectedMetric.name]: chartDataValue[selectedMetric.key] || leaf.metrics[selectedMetric.key],
    });
  }) || [];

  const resetFilters = () => {
    setSelectedMetric(metricOptions[0]);
    setSelectedTimeZone(timeZonesOptions[0]);
    setCalendarStartDate(moment().startOf('day').toDate());
    setCalendarEndDate(moment().endOf('day').toDate());
    setCurrentTab(0);
  };

  return (
    <Drawer anchor="right" open={isOpen && !!campaignData} onClose={() => { onClose(); resetFilters(); }}>
      {!!campaign && (
        <Wrapper>
          <StatusAndCreationDateContainer>
            <Status>
              <StyledToggle
                as={campaign.active ? FaToggleOn : FaToggleOff}
                color={campaign.active ? '#56E497' : '#D9DDE5'}
                size={30}
                onClick={() => updateCampaign({
                  campaignId: campaign.id,
                  body: { active: !campaign.active },
                  method: 'PATCH',
                })}
              />
              <span>{campaign.active ? 'Running' : 'Paused'}</span>
            </Status>
            <SubduedText>
              {`Created on ${moment(campaign.createdAt).format('MMMM DD, YYYY')}`}
            </SubduedText>
          </StatusAndCreationDateContainer>
          <DataContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                {campaign.name}
                <IconButton size="medium" onClick={(event) => handleAnchorElClick(event, 'name')} style={{ position: 'relative', bottom: 2 }}>
                  <FaPen color="#878787" size={14} />
                </IconButton>
              </div>
              <div>
                {`ID: ${campaign.id}`}
                <EditLink onClick={() => { window.open(`https://app.pushground.com/campaign/edit/${campaign.id}`, '_self'); }}>
                  <span>Edit</span>
                  &nbsp;
                  <FaPen size={14} style={{ position: 'relative', top: 1 }} />
                </EditLink>
              </div>
            </div>
            <Divider />
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                Bid:&nbsp;&nbsp;
                <span>
                  {`$${campaign.bid}`}
                  <IconButton size="medium" onClick={(event) => handleAnchorElClick(event, 'bid')} style={{ position: 'relative', bottom: 2 }}>
                    <FaPen color="#878787" size={14} />
                  </IconButton>
                </span>
              </div>
              <div style={{ flex: 1 }}>
                Daily budget:&nbsp;&nbsp;
                <span>
                  {`$${formatNumber({ number: campaign.dailyBudget })}`}
                  <IconButton size="medium" onClick={(event) => handleAnchorElClick(event, 'dailyBudget')} style={{ position: 'relative', bottom: 2 }}>
                    <FaPen color="#878787" size={14} />
                  </IconButton>
                </span>
              </div>
            </div>
            <Divider />
            <div style={{ display: 'flex' }}>
              <span style={{ width: '80px' }}>Targeting</span>
              <div style={{
                display: 'flex', gap: 5, flexWrap: 'wrap', marginLeft: '7%',
              }}
              >
                {campaignData?.isBrandsafe && (
                  <PushgroundPlusBadge>Pushground +</PushgroundPlusBadge>
                )}
                {!!campaignData?.targeting && Object.entries(campaignData.targeting).sort(
                  ([keyA], [keyB]) => keyA.localeCompare(keyB),
                ).map(
                  ([key, value]) => (
                    Array.isArray(value) && value.map((badgeValue) => (
                      badgeValue !== 'Unknown' && (
                        <TargetingBadge isExcludeBadge={key.includes('exclude')} key={badgeValue}>
                          {badgeValue.toString().split('_').join(' ')}
                        </TargetingBadge>
                      )
                    ))
                  ),
                )}
              </div>
            </div>
            <Divider />
            <div style={{ display: 'flex', gap: '7%' }}>
              <span style={{ width: '80px' }}>Automatic Rules</span>
              <SubduedText>0 Automatic rules applied on this campaign</SubduedText>
            </div>
            <EditorPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              dynamicData={editorPopoverDymanicData[currPopover]}
              campaignId={campaign.id}
              bidData={bidData}
            />
          </DataContainer>
          <DataContainer>
            <Tabs value={currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" ref={tabsRef}>
              {tabs.map((tab) => (
                <Tab label={tab.name} wrapped style={{ flex: 1 }} key={tab.name} />
              ))}
            </Tabs>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 20,
              margin: '20px 0',
            }}
            >
              <StyledSelect
                value={selectedMetric.name}
                onChange={(event) => {
                  const newMetric = metricOptions.find(
                    (metricOption) => metricOption.name === event.target.value,
                  );

                  setSelectedMetric(newMetric!);
                }}
                width={160}
                height={39}
              >
                {metricOptions.map((metric) => (
                  <StyledOption value={metric.name} key={metric.name}>
                    {metric.name}
                  </StyledOption>
                ))}
              </StyledSelect>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                <StyledSelect
                  value={selectedTimeZone}
                  onChange={(event) => setSelectedTimeZone(event.target.value as string)}
                  width={160}
                  height={39}
                >
                  {timeZonesOptions.map((timeZone) => (
                    <StyledOption value={timeZone} key={timeZone}>
                      {timeZone}
                    </StyledOption>
                  ))}
                </StyledSelect>
                <Calendar
                  startDate={calendarStartDate}
                  setStartDate={setCalendarStartDate}
                  endDate={calendarEndDate}
                  setEndDate={setCalendarEndDate}
                />
              </div>
            </div>
            {campaignStats && !isFetching && !isError ? (
              <ResponsiveContainer width="100%" height={213}>
                <BarChart data={chartData.sort(
                  (a: {[key: string]: number}, b: {[key: string]: number}) => (
                    b[selectedMetric.name] - a[selectedMetric.name]
                  ),
                ).slice(0, 15)}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <Tooltip
                    formatter={(value) => (selectedMetric.unit === '$' ? '$' : '') + formatNumber({
                      number: Number(value), numberOfDecimals: 2,
                    }) + (selectedMetric.unit === '%' ? '%' : '')}
                  />
                  <Bar dataKey={selectedMetric.name} fill="#4d78ff" barSize={14} />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <ChartNoDataOrLoaderContainer>
                {campaignStats || isError ? <ClipLoader size={60} /> : 'No data to display'}
              </ChartNoDataOrLoaderContainer>
            )}
            <DataDrawerTable
              campaignStats={campaignStats}
              currDimension={currDimension}
              isFetchingStats={isFetching}
              campaignBid={campaign.bid}
              campaignId={campaign.id}
            />
          </DataContainer>
        </Wrapper>
      )}
    </Drawer>
  );
}

export default DataDrawer;
