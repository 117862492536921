const tabs = [
  { name: 'Supply ID', dimension: 'supplyFeed' },
  { name: 'Site ID', dimension: 'source' },
  { name: 'Formats', dimension: 'format' },
  { name: 'Creativities', dimension: 'creativity' },
  { name: 'User Freshness', dimension: 'userAge' },
  { name: 'Day', dimension: 'day' },
  { name: 'Device', dimension: 'device' },
];

export default tabs;
