import styled, { css } from 'styled-components/macro';
import { Select, MenuItem } from '@mui/material';

export const filterStyles = css`
    width: 220px;
    height: 44px;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    border-radius: 10px;    
`;

interface StyledSelectProps {
    width?: number;
    height?: number;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
    && {
        ${filterStyles}

        width: ${({ width }) => !!width && `${width}px`};
        height: ${({ height }) => !!height && `${height}px`};

        .MuiSelect-icon {
            font-size: 20px;
        }
    }
`;

interface MenuItemInterface {
    $isSelected?: boolean;
  }

export const StyledOption = styled(MenuItem)<MenuItemInterface>`
      && {
          display: ${({ $isSelected }) => $isSelected && 'none'};
          font-family: Poppins, sans-serif;
          font-size: 15px;
          height: 40px;
          
          &:hover {
              background-color: #EDF1FF;
          }
      }
  `;
