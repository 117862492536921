import React, { useEffect, type Dispatch, type SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLazyGetModerationListQuery } from '../../moderation/redux/moderationAPI';
import {
  StyledSideNavbar, StyledBadge, MenuItemLabel, StyledTooltip,
} from './styles/SideNavbar.styled';
import { StyledMenuLink, MenuIconContainer } from './styles/Dashboard.styled';
import menuItems from '../data/sideNavbarMenuItems';

interface SideNavbarProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setIsUserMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function SideNavbar({ isOpen, setIsOpen, setIsUserMenuOpen }: SideNavbarProps) {
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  const [getModerationList] = useLazyGetModerationListQuery({ pollingInterval: location.pathname === '/admin/moderation' ? 0 : 60000 });

  const userRole = useAppSelector(({ loginSlice: { user } }) => user?.role || 'Advertiser');

  useEffect(() => {
    const usersWithModerationPanel = ['Account Manager', 'Super Admin'];

    if (usersWithModerationPanel.includes(userRole)) {
      getModerationList({});
    }
  }, [userRole]);

  const totalCampaignsToModerate = useAppSelector(
    ({ moderationSlice: { totalCampaigns } }) => totalCampaigns,
  );

  return (
    <>
      <StyledSideNavbar isSideNavbarOpen={isOpen}>
        {menuItems[userRole].map((menuItem) => {
          const isExternalLink = !!menuItem.url;

          return (
            <StyledMenuLink
              {...(isExternalLink
                ? { as: 'a', href: menuItem.url, target: '_blank' }
                : { to: menuItem.route }
                )}
              data-tip
              data-for={menuItem.label}
              key={menuItem.label}
              onClick={() => {
                const closeOpenNavbarOnCurrentRouteClick = () => {
                  if (menuItem.route === location.pathname && isOpen) {
                    setIsOpen(false);
                  }
                };

                closeOpenNavbarOnCurrentRouteClick();
                setIsUserMenuOpen(false);
              }}
            >
              <MenuIconContainer>
                <StyledBadge badgeContent={totalCampaignsToModerate} invisible={menuItem.label !== 'Moderation'} $isSideNavbarOpen={isOpen}>
                  <img src={menuItem.icon} alt={`${menuItem.label} icon`} />
                </StyledBadge>
              </MenuIconContainer>
              <MenuItemLabel isSideNavbarOpen={isOpen}>{menuItem.label}</MenuItemLabel>
            </StyledMenuLink>
          );
        })}
      </StyledSideNavbar>
      {menuItems[userRole].map((menuItem) => (
        <StyledTooltip id={menuItem.label} disable={isOpen} key={menuItem.label}>
          {menuItem.label}
        </StyledTooltip>
      ))}
    </>
  );
}

export default SideNavbar;
