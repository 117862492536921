import styled, { css } from 'styled-components/macro';
import {
  DialogTitle, DialogContent, IconButton, DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const PaddingContainer = styled.div`
    padding: 30px 40px 25px;
`;

export const StyledIconButton = styled(IconButton)`
    && {
        position: absolute;
        top: 16px;
        right: 30px;
        color: #212529;
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    && {
        font-size: 18px;
    }
`;

export const StyledDialogTitle = styled(DialogTitle)`
    && {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        color: #212529;
        padding: 0;
    }
`;

export const StyledDialogContent = styled(DialogContent)`
    && {
        padding: 15px 35px 7px 0;
    }
`;

export const StyledText = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #989898;
`;

interface RejectionReasonsContainerProps {
    width: number;
}

export const RejectionReasonsContainer = styled.div<RejectionReasonsContainerProps>`
    width: ${({ width }) => width}%;
    padding: 10px;
`;

export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #212529;
    margin: 12px 0 12px 17px;
`;

interface RejectButtonProps {
    isDisabled: boolean;
}

export const RejectButton = styled.button<RejectButtonProps>`
    width: 100px;
    height: 48px;
    font-size: 14px;
    color: #FFFFFF;
    background: #E45656;
    border-radius: 8px;

    ${({ isDisabled }) => (
    isDisabled
      ? css`
        opacity: 0.37; 
        cursor: default;
    `
      : css`
        &:hover {
            background-color: #E02020;
        }
    `)}
`;

export const StyledDialogActions = styled(DialogActions)`
    && {
        padding: 20px 0 0;
    }
`;
