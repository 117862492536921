import styled from 'styled-components/macro';

export const Card = styled.div`
    min-width: 300px;
    background-color: #FFF;
    padding: 22px;
    border-radius: 5px;
    margin-bottom: 22px;
    box-shadow: rgb(25 32 56 / 4%) 0px 8px 24px 0px;
`;
