type FormatNumberParams = {
    number: number | undefined;
    numberOfDecimals?: number;
}

const formatNumber = ({ number, numberOfDecimals }: FormatNumberParams) => {
  if (number) {
    const exponent = numberOfDecimals ? 10 ** numberOfDecimals : 1;
    const roundedNumber = Math.round(number * exponent) / exponent;
    const roundedNumberWithLocale = roundedNumber.toLocaleString('en-US', { maximumFractionDigits: 4 });

    return roundedNumberWithLocale;
  }

  return number;
};

export default formatNumber;
