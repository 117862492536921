import React, { useState, useLayoutEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  PaddingContainer, StyledDialogTitle, StyledDialogContent, StyledText, RejectionReasonsContainer,
  StyledLabel, StyledIconButton, StyledCloseIcon, StyledDialogActions, RejectButton,
} from './styles/RejectDialog.styled';
import { StyledCheckbox } from './styles/ModerationCard.styled';
import { FlexContainer } from '../../../common/components/styles/Flex';
import rejectDialogContent from '../data/rejectDialogContent';
import getCheckedItems from '../../../common/utils/getCheckedItems';

interface RejectDialogProps {
    rejectionType: 'campaign' | 'creativity';
    open: boolean;
    setOpen: (arg0: boolean) => void;
    rejectionAction: (arg0: { reasons: Array<string> }) => void;
}

function RejectDialog({
  rejectionType, open, setOpen, rejectionAction,
}: RejectDialogProps) {
  const { title, rejectionReasons } = rejectDialogContent[rejectionType];

  const checkboxesInitialState = useMemo(
    () => rejectionReasons.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
    [rejectionType],
  );

  const [checked, setChecked] = useState<{[key: string]: boolean}>(checkboxesInitialState);

  useLayoutEffect(() => {
    setChecked(checkboxesInitialState);
  }, [rejectionType]);

  const handleChange = (rejectionReason: string) => {
    setChecked((prevState) => ({
      ...(rejectionType === 'creativity' ? checkboxesInitialState : prevState),
      [rejectionReason]: !prevState[rejectionReason],
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setChecked(checkboxesInitialState);
  };

  const handleRejectBttnClick = () => {
    const rejectActionReasons = getCheckedItems(checked);

    rejectionAction({ reasons: rejectActionReasons });
    handleClose();
  };

  const isRejectBttnDisabled = !Object.values(checked).some((checkbox) => checkbox);

  const formatRejectionReasons = () => {
    const numberOfLoops = Math.ceil(rejectionReasons.length / 10);
    const formattedRejectionReasons = [];

    for (let i = 0; i < numberOfLoops; i++) {
      const startSlicePosition = i * 10;
      const endSlicePosition = startSlicePosition + 10;

      const rejectionReasonsSlice = rejectionReasons.slice(startSlicePosition, endSlicePosition);

      formattedRejectionReasons.push(
        <RejectionReasonsContainer key={i} width={100 / numberOfLoops}>
          {rejectionReasonsSlice.map((rejectionReason) => (
            <StyledLabel htmlFor={rejectionReason} key={rejectionReason}>
              <StyledCheckbox
                id={rejectionReason}
                checked={checked[rejectionReason]}
                onChange={() => handleChange(rejectionReason)}
              />
              {rejectionReason}
            </StyledLabel>
          ))}
        </RejectionReasonsContainer>,
      );
    }

    return formattedRejectionReasons;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <PaddingContainer>
        <StyledDialogTitle>
          {title}
          <StyledIconButton onClick={handleClose}>
            <StyledCloseIcon />
          </StyledIconButton>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <StyledText>{`Select the rejected reason${rejectionType === 'campaign' ? 's' : ''} the user will see:`}</StyledText>
          <FlexContainer>
            {formatRejectionReasons()}
          </FlexContainer>
        </StyledDialogContent>
        <StyledDialogActions>
          <RejectButton onClick={handleRejectBttnClick} isDisabled={isRejectBttnDisabled}>
            Reject
          </RejectButton>
        </StyledDialogActions>
      </PaddingContainer>
    </Dialog>
  );
}

export default RejectDialog;
