import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { shallowEqual } from 'react-redux';
import theme from './styles/theme';
import { useAppSelector } from './app/hooks';
import { useGetUserData } from './common/hooks/useGetUserData';
import GlobalStyles from './styles/GlobalStyles';
import Login from './features/login/Login';
import DashboardLayout from './features/dashboard-layout/DashboardLayout';
import Campaigns from './features/campaigns/Campaigns';
import CreateCampaign from './features/create-campaign/CreateCampaign';
import Stats from './features/stats/Stats';
import Moderation from './features/moderation/Moderation';
import Balance from './features/balance/Balance';
import Settings from './features/user-settings/UserSettings.jsx';

function App() {
  const { isUserAuthenticated, isUserDataLoaded } = useAppSelector(
    ({ loginSlice: { isUserAuthenticated, user } }) => ({
      isUserAuthenticated, isUserDataLoaded: !!user?.name,
    }),
    shallowEqual,
  );

  useGetUserData();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Navigate to={isUserAuthenticated && isUserDataLoaded ? '/campaigns' : '/login'} />} />
        <Route path="/login" element={<Login />} />
        <Route element={<DashboardLayout />}>
          <Route path="/campaigns">
            <Route path="" element={<Campaigns />} />
            <Route path="create" element={<CreateCampaign />} />
            <Route path="edit/*" element={<CreateCampaign />} />
            <Route path="stats" element={<Stats />} />
          </Route>
          <Route path="/admin">
            <Route path="moderation" element={<Moderation />} />
          </Route>
          <Route path="/user">
            <Route path="balance" element={<Balance />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
