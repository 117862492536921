import { DefaultTheme, css } from 'styled-components/macro';

const getMediaQuery = (breakpoint: string) => `@media only screen and (min-width: ${breakpoint})`;

const theme: DefaultTheme = {
  mediaQueries: {
    sm: getMediaQuery('600px'),
    md: getMediaQuery('960px'),
    lg: getMediaQuery('1200px'),
  },
  customScrollbarStyles: css`
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 20px;
      visibility: hidden;
    }

    :hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  `,
  hiddenScrollbarStyles: css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
};

export default theme;
