import React, {
  useState, useLayoutEffect, useDeferredValue, type ChangeEvent, type Dispatch, type SetStateAction,
} from 'react';
import type { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import type { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import type { SelectChangeEvent } from '@mui/material';
import { FiltersWrapper, StyledInput } from './styles/Filters.styled';
import { StyledOption, StyledSelect } from '../../../common/components/styles/Select';
import filtersSelectOptions from '../data/filtersSelectOptions';

type FiltersState = {
  [key: string]: string | number;
  nameOrId: string;
  accountManager: number;
  platform: string;
  accountRank: string;
  campaignType: string;
  timeOfCreation: string;
}

interface FiltersProps {
  useFiltersState(): [FiltersState, Dispatch<SetStateAction<FiltersState>>]
  getModerationList: LazyQueryTrigger<any>;
  isFetching: boolean;
}

function Filters({ useFiltersState, getModerationList, isFetching }: FiltersProps) {
  const [filters, setFilters] = useFiltersState();

  const deferredNameOrIdFilter = useDeferredValue(filters.nameOrId);

  const [currentRequest, setCurrentRequest] = useState<QueryActionCreatorResult<any>>();

  useLayoutEffect(() => {
    if (isFetching && !!currentRequest) {
      currentRequest.abort();
    }

    const request = getModerationList(filters);

    setCurrentRequest(request);
  }, Object.values({ ...filters, nameOrId: deferredNameOrIdFilter }));

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
    filterType: string,
  ) => setFilters(
    (prevState) => ({ ...prevState, [filterType]: event.target.value as string | number }),
  );

  const getSelect = ({ selectType }: { selectType: string }) => (
    <StyledSelect
      value={filters[selectType]}
      onChange={(event) => handleChange(event, selectType)}
      displayEmpty
      key={selectType}
    >
      {filtersSelectOptions[selectType].map(({ option, value }) => (
        <StyledOption value={value} $isSelected={filters[selectType] === value} key={option}>
          {option}
        </StyledOption>
      ))}
    </StyledSelect>
  );

  return (
    <FiltersWrapper>
      <StyledInput type="text" value={filters.nameOrId} onChange={(event) => handleChange(event, 'nameOrId')} placeholder="Search by Name or ID" />
      {['accountManager', 'platform', 'accountRank', 'campaignType', 'timeOfCreation'].map((selectType) => (
        getSelect({ selectType })
      ))}
    </FiltersWrapper>
  );
}

export default Filters;
