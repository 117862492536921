/* eslint-disable react/prop-types */
import { useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { Fieldset, FieldsetTitle, ColorButton } from './styles/common.styled.js';
import Input from './Input.jsx';
import { useAppSelector } from '../../../app/hooks';

function Tokens(props) {
  const {
    /* showApiToken = true, */
    userApiToken, userApiTokenLoading, getAPIToken,
    userVoluumToken, userVoluumTokenLoading, getVoluumToken,
    style,
  } = props;

  const apiTokenRef = useRef();
  const voluumTokenRef = useRef();

  const isAdvertiser = useAppSelector(({ loginSlice: { user } }) => user?.role === 'Advertiser');

  return (
    <Fieldset noMargin style={style}>
      <FieldsetTitle fontSize={22}>Tokens</FieldsetTitle>
      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end', width: 600 }}>
          <Input
            style={{ flex: 1, maxWidth: 500 }}
            inputRef={apiTokenRef}
            label="API Token"
            value={userApiToken || 'Generate your token'}
            copiable={!!userApiToken}
            readOnly
          />
          {
              !userApiToken && !userApiTokenLoading
              && (
              <ColorButton
                style={{ margin: '0 0 21px 15px' }}
                disabled={!!userApiToken}
                onClick={() => !userApiToken && getAPIToken()}
              >
                Get Token
              </ColorButton>
              )
            }
          {
              userApiTokenLoading && <Spinner animation="border" style={{ margin: '0 0 16px 15px' }} />
            }
        </div>
      </div>
      {
        isAdvertiser && (
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end', width: 600 }}>

            <Input
              style={{ flex: 1, maxWidth: 500 }}
              inputRef={voluumTokenRef}
              label="Voluum Token"
              value={userVoluumToken || 'Generate your token'}
              copiable={!!userVoluumToken}
              readOnly
            />
            {
              !userVoluumToken && !userVoluumTokenLoading
              && (
              <ColorButton
                style={{ margin: '0 0 21px 15px' }}
                disabled={!!userVoluumToken}
                onClick={() => !userVoluumToken && getVoluumToken()}
              >
                Get Token
              </ColorButton>
              )
            }
            {
              userVoluumTokenLoading && <Spinner animation="border" style={{ margin: '0 0 16px 15px' }} />
            }
          </div>
        </div>
        )
      }
    </Fieldset>
  );
}

export default Tokens;
