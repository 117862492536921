export const metricOptions = [
  { name: 'Conversions', key: 'conversions' },
  { name: 'Spent', key: 'cost', unit: '$' },
  { name: 'Clicks', key: 'clicks' },
  { name: 'Opportunities', key: 'bids' },
  { name: 'Deliveries', key: 'deliveries' },
  { name: 'Click Rate', key: 'clickRate', unit: '%' },
  { name: 'Win Rate', key: 'winRate', unit: '%' },
  { name: 'Conversion Rate', key: 'conversionRate', unit: '%' },
  { name: 'View Content', key: 'viewContent', unit: '%' },
  { name: 'CPA', key: 'cpa', unit: '$' },
];

export const timeZonesOptions = [
  'UTC',
  'Europe/Madrid',
  'Europe/Amsterdam',
  'Europe/Berlin',
  'Europe/Moscow',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Freetown',
  'Africa/Johannesburg',
  'America/Buenos_Aires',
  'America/Los_Angeles',
  'America/Mexico_City',
  'America/Montreal',
  'America/New_York',
  'America/Sao_Paulo',
  'America/Toronto',
  'America/Vancouver',
  'Asia/Bangkok',
  'Asia/Calcutta',
  'Asia/Dubai',
  'Asia/Istanbul',
  'Asia/Qatar',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Tel_Aviv',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Pacific/Honolulu',
  'Pacific/Tahiti',
  'CET',
  'GMT',
  'Etc/GMT-1',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT+1',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
];
