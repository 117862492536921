const campaignRejectionReasons = [
  'Scareware URL',
  'Landing page error',
  'Unsafe content adult',
  'Domain is banned',
  'Suspicious ltd URL',
  'Fraudulent technical support',
  'Site warning: Alerts in the form of a number or warning',
  'Mismatch',
  'No balance',
  'Tech support/Suspicious',
];

const creativityRejectionReasons = [
  'Poor quality images.',
  'Brand logos / references.',
  'Scary offers.',
  'Phishing offers.',
  'Prohibited products/sevices.',
  'Prohibited image content.',
  'Prohibited text content.',
  'Contact account manager.',
  'Adult campaign',
  'Nutra vertical',
  'Text imitating system',
  'Site warning',
  'Simulate button/buttons',
  'No balance',
  'Misleading',
  'Alerts in the form of a number or warning',
  'Mismatch',
];

const rejectionTypeDialogContent = {
  campaign: {
    title: 'Campaign Rejection',
    rejectionReasons: campaignRejectionReasons.sort(),
  },
  creativity: {
    title: 'Rejection Reasons',
    rejectionReasons: creativityRejectionReasons.sort(),
  },
};

export default rejectionTypeDialogContent;
