/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  Dispatch, SetStateAction, useEffect, useLayoutEffect, useState,
} from 'react';
import {
  FaCircle, FaExclamationTriangle, FaToggleOff, FaToggleOn, FaRegPlayCircle, FaPen,
  FaRegClone, FaRegFile,
} from 'react-icons/fa';
import {
  IoMdPause,
} from 'react-icons/io';
import { GoGraph } from 'react-icons/go';
import { ClipLoader } from 'react-spinners';
import { IconButton } from '@mui/material';
import { Portal } from 'react-portal';
import { useNavigate } from 'react-router-dom';
import { useLazyGetFormatsCountryRatesQuery, useUpdateCampaignMutation, type UIStatus } from '../redux/campaignsAPI';
import {
  StyledTable, HeaderCell, Tooltip, ProgressBar, Bar, HeaderCellArrow,
  StyledCheckbox, ViewDataOrEditBttn,
} from './styles/Table';
import { StyledOption, StyledSelect } from '../../../common/components/styles/Select';
import formatNumber from '../../../common/utils/formatNumber';
import getViewContent from '../utils/getViewContent';
import getBidByFormatsCountries from '../utils/legacyGetBidByFormatsCountries';
import { CampaignsList, CampaignsStats } from '../redux/campaignsSlice';
import { Divider } from '../../../common/components/styles/Divider';
import usePopover from '../hooks/usePopover';
import EditorPopover from './EditorPopover';
import CustomColumnsPopper from './CustomColumnsPopper';
import TablePagination from './TablePagination';
import DataDrawer from './DataDrawer';
import { useLazyGetCampaignDataQuery } from '../../create-campaign/redux/createCampaignAPI';

const columns = [
  { header: 'On/Off', canReorder: false },
  { header: 'Name', canReorder: false },
  { header: 'Id', canReorder: false },
  { header: 'Status', canReorder: false },
  { header: 'Country', canReorder: false },
  { header: 'Bid', canReorder: false },
  { header: 'Budget', canReorder: false },
  { header: 'Spent', canReorder: true, statName: 'cost' },
  { header: 'Opport.', canReorder: true, statName: 'bids' },
  { header: 'Deliveries', canReorder: true, statName: 'deliveries' },
  { header: 'Win Rate', canReorder: true, statName: 'winRate' },
  { header: 'View Content', canReorder: true, statName: 'viewContent' },
  { header: 'Clicks', canReorder: true, statName: 'clicks' },
  { header: 'CTR', canReorder: true, statName: 'clickRate' },
  { header: 'Conversions', canReorder: true, statName: 'conversions' },
  { header: 'CPA', canReorder: false },
];

const getStatus = ({ campaignId, uiStatus }: { campaignId: number; uiStatus: UIStatus; }) => {
  const { id: statusId, label: statusLabel } = uiStatus?.main ?? {};
  const statusTooltipLabel = uiStatus?.reason?.label;

  const showTooltip = !!statusTooltipLabel;

  const CurrentIcon = statusId === 'STOPPED' ? FaExclamationTriangle : FaCircle;

  const getCurrentIconColor = (status: string) => {
    const availableColors: {[key: string]: string} = {
      running: '#56E497',
      pending: '#FCDF46',
      moderation: '#FCDF46',
      paused: '#E45656',
      rejected: '#E45656',
      stopped: '#5179F6',
    };

    const currentIconColor = availableColors[status?.toLowerCase()] || '#999';

    return currentIconColor;
  };

  return (
    <>
      <div data-tip data-for={`status-${campaignId}`}>
        <CurrentIcon color={getCurrentIconColor(statusId)} size={9} style={{ marginRight: 4 }} />
        {statusId === 'UNKNOWN' ? 'Unknown' : statusLabel}
      </div>
      {showTooltip && (
        <Tooltip id={`status-${campaignId}`} isStatus>
          <div dangerouslySetInnerHTML={{ __html: statusTooltipLabel }} />
        </Tooltip>
      )}
    </>
  );
};

interface TableProps {
  campaignsList: CampaignsList | undefined;
  totalCampaignsList: CampaignsList | undefined;
  campaignsStats: CampaignsStats | null;
  isFetching: boolean;
  setIsFiltering: Dispatch<SetStateAction<boolean>>;
}

export interface EditorPopoverDynamicData {
  popoverTitle: string;
  inputInitialState: string | number | undefined;
  propertyToChange: string;
}

function Table({
  campaignsList, campaignsStats, isFetching, setIsFiltering, totalCampaignsList,
}: TableProps) {
  const [updateCampaign] = useUpdateCampaignMutation();

  const totalMetrics = totalCampaignsList?.length === campaignsList?.length
    ? campaignsStats?.total?.metrics : null;

  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);

  useEffect(() => {
    if (isFetching) {
      setSelectedCampaigns([]);
    }
  }, [isFetching]);

  const handleHeaderCboxChange = (event: any) => {
    event.stopPropagation();

    if (selectedCampaigns.length) {
      setSelectedCampaigns([]);
    } else if (campaignsList) {
      setSelectedCampaigns(campaignsList.map(({ id }) => id));
    }
  };

  const getCampaignStats = (campaignId: number) => {
    const { metrics: campaignStats } = campaignsStats?.leafs?.find(
      ({ dimensions: { offer: offerId } }) => +offerId === campaignId,
    ) || {};

    return campaignStats;
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const {
    anchorEl, currPopover, handleAnchorElClick, setAnchorEl,
  } = usePopover();

  const [editorData, setEditorData] = useState<{
    dailyBudget?: number; bid?: number; maxBid?:number; recommendedBid?: number;
    name?: string; campaignId: number;
  }>();

  const [currOrderingColumn, setCurrOrderingColumn] = useState({ headerCellName: 'spent', statName: 'cost', higherFirst: true });

  const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
  const hideableColumns = ['Country', 'Bid', 'Budget', 'Spent', 'Opport.', 'Deliveries', 'Win Rate', 'View Content', 'Clicks', 'CTR', 'Conversions', 'CPA'];

  const [numberOfRows, setNumberOfRows] = useState(25);

  const numberOfPages = Number(formatNumber({
    number: campaignsList ? Math.ceil(campaignsList.length / numberOfRows) : 1, numberOfDecimals: 0,
  })) || 1;

  function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  const getSortedCampaigns = () => (campaignsList?.slice().sort((a, b) => {
    const { [currOrderingColumn.statName]: value1 } = getCampaignStats(a.id)
      || {} as { [key: string]: number};
    const { [currOrderingColumn.statName]: value2 } = getCampaignStats(b.id)
      || {} as { [key: string]: number};

    if (currOrderingColumn.higherFirst) {
      return (value1 || 0) >= (value2 || 0) ? -1 : 1;
    }

    return (value2 || 0) >= (value1 || 0) ? -1 : 1;
  }));

  const [currPageRows, setCurrPageRows] = useState<any>(null);

  useEffect(() => {
    const sortedCampaignsList = getSortedCampaigns();

    if (sortedCampaignsList && typeof campaignsStats === 'object' && campaignsStats) {
      setCurrPageRows(Array.from(chunks(sortedCampaignsList ?? [], numberOfRows)));
      setIsFiltering(false);
    }
  }, [campaignsList, campaignsStats, numberOfRows,
    currOrderingColumn.statName, currOrderingColumn.higherFirst]);

  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    if (!!numberOfPages && currPage > numberOfPages!) {
      setCurrPage(numberOfPages!);
    }
  }, [numberOfPages]);

  const [drawerData, setDrawerData] = useState({ isOpen: false, campaignId: null });

  const editorPopoverData: {[key: string]: EditorPopoverDynamicData} = {
    name: { popoverTitle: 'Campaign Name', inputInitialState: editorData!?.name, propertyToChange: 'name' },
    bid: { popoverTitle: 'Bid', inputInitialState: editorData!?.bid, propertyToChange: 'bid' },
    dailyBudget: { popoverTitle: 'Daily Budget', inputInitialState: editorData!?.dailyBudget, propertyToChange: 'dailyBudget' },
  };

  const [
    getFormatsCountryRates, { data: formatsCountryRates },
  ] = useLazyGetFormatsCountryRatesQuery();

  useLayoutEffect(() => {
    getFormatsCountryRates();
  }, []);

  const [getCampaignData, { isSuccess, originalArgs }] = useLazyGetCampaignDataQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/campaigns/edit/${originalArgs?.campaignId}`);
    }
  }, [isSuccess]);

  return (
    <>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledSelect
          value="Actions"
          disabled={!selectedCampaigns.length}
          style={{ fontSize: 15, marginBottom: '25px' }}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <StyledOption value="Actions" style={{ display: 'none' }}>
            <FaPen style={{ marginRight: 5, marginLeft: 2, paddingTop: 2 }} />
            {' '}
            Actions
          </StyledOption>
          {[{ icon: <FaRegPlayCircle style={{ marginRight: 10 }} />, label: 'Active' },
            { icon: <IoMdPause style={{ marginRight: 10 }} />, label: 'Pause' },
            { icon: <FaRegFile style={{ marginRight: 10 }} />, label: 'Report' },
            ...(selectedCampaigns.length < 2 ? [{ icon: <FaRegClone style={{ marginRight: 10 }} />, label: 'Clone' }] : []),
          ].map((option) => (
            <StyledOption
              key={option.label}
              onClick={() => {
                switch (option.label) {
                  case 'Active': {
                    selectedCampaigns.forEach((campaignId) => {
                      updateCampaign({
                        campaignId, body: { active: true }, method: 'PATCH',
                      });
                    });
                    break;
                  }
                  case 'Pause': {
                    selectedCampaigns.forEach((campaignId) => {
                      updateCampaign({
                        campaignId, body: { active: false }, method: 'PATCH',
                      });
                    });
                    break;
                  }
                  case 'Report': {
                    window.open(`https://app.pushground.com/campaigns/stats?ref=index&campaignFilter=${selectedCampaigns.join(',')}`, '_self');
                    break;
                  }
                  case 'Clone': {
                    window.open(`https://app.pushground.com/campaign/${selectedCampaigns[0]}/clone/`, '_self');
                    break;
                  }
                  default:
                }

                handleClose();
                setSelectedCampaigns([]);
              }}
            >
              {option.icon}
              {option.label}
            </StyledOption>
          ))}
        </StyledSelect>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <CustomColumnsPopper
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            hideableColumns={hideableColumns}
          />
          <TablePagination
            numberOfPages={numberOfPages}
            currPage={currPage}
            setCurrPage={setCurrPage}
            numberOfRows={numberOfRows}
            setNumberOfRows={setNumberOfRows}
          />
        </div>
      </div>
      <StyledTable isThereContent={!!campaignsList?.length && !isFetching}>
        <thead>
          <tr>
            <th style={{ minWidth: 67 }}>
              <IconButton data-tip data-for="header-checkbox" size="medium" disabled={!campaignsList?.length || isFetching} onClick={handleHeaderCboxChange}>
                <StyledCheckbox
                  checked={!!selectedCampaigns.length}
                  onChange={handleHeaderCboxChange}
                />
              </IconButton>
              <Portal>
                <Tooltip id="header-checkbox" arrowColor="transparent">
                  {selectedCampaigns.length ? 'Unselect all' : 'Select all'}
                </Tooltip>
              </Portal>
            </th>
            {columns.map(({ header, canReorder, statName }) => (
              <HeaderCell
                {...(header === 'Win Rate' && { 'data-tip': true, 'data-for': 'winrate-tooltip' })}
                className={`${header.toLowerCase()} ${canReorder && 'reorder-column'}`}
                onClick={() => {
                  if (canReorder) {
                    setCurrOrderingColumn({
                      headerCellName: header.toLowerCase(),
                      higherFirst: currOrderingColumn.headerCellName === header.toLowerCase()
                        ? !currOrderingColumn.higherFirst : true,
                      statName: statName!,
                    });
                  }
                }}
                isHidden={hiddenColumns.includes(header)}
                key={header}
              >
                <div style={{ display: 'flex' }}>
                  {header}
                  {' '}
                  {header.toLowerCase() === currOrderingColumn.headerCellName && (
                    <HeaderCellArrow $rotationDegrees={currOrderingColumn.higherFirst ? 180 : 0} />
                  )}
                </div>

              </HeaderCell>
            ))}
          </tr>
        </thead>
        {isFetching || !campaignsList || !currPageRows
          ? (
            <tbody>
              <tr className="spinner">
                <td colSpan={columns.length} className="spinner">
                  <ClipLoader size={60} cssOverride={{ margin: '20px calc(100vw / 2.3)' }} />
                </td>
              </tr>
            </tbody>
          )
          : (
            <tbody>
              {[...(currPageRows[currPage - 1] || [])].map((campaign) => {
                const campaignStats = getCampaignStats(campaign.id);

                const CurrentToggle = campaign.active ? FaToggleOn : FaToggleOff;

                const spentPercentage = (campaignStats?.cost && campaign.dailyBudget)
                  ? (campaignStats.cost / campaign.dailyBudget) * 100
                  : 0;

                const formattedSpentPercentage = formatNumber({
                  number: spentPercentage > 100 ? 100 : spentPercentage,
                  numberOfDecimals: 2,
                });

                const { minBid, maxBid, recBid: recommendedBid } = getBidByFormatsCountries(
                  formatsCountryRates,
                  campaign.targeting.formats,
                  campaign.targeting?.countries,
                  campaign.isBrandsafe,
                );

                return (
                  <tr
                    key={campaign.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedCampaigns((prevState) => {
                        if (prevState.includes(campaign.id)) {
                          return prevState.filter((id) => id !== campaign.id);
                        }
                        return ([...prevState, campaign.id]);
                      });
                    }}
                  >
                    <td style={{ minWidth: 67 }}>
                      <IconButton size="medium">
                        <StyledCheckbox
                          readOnly
                          checked={selectedCampaigns.includes(campaign.id)}
                        />
                      </IconButton>
                    </td>
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CurrentToggle
                          color={campaign.active ? '#56E497' : '#D9DDE5'}
                          size={30}
                          onClick={(event) => {
                            event.stopPropagation();

                            updateCampaign({
                              campaignId: campaign.id, body: { active: !campaign.active }, method: 'PATCH',
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td
                      data-tip
                      data-for={campaign.name}
                      className="campaign-name"
                      style={{
                        maxWidth: 250, minWidth: 250,
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden', textOverflow: 'ellipsis',
                        }}
                        onClick={(event) => {
                          handleAnchorElClick(event, 'name');
                          setEditorData({
                            name: campaign.name, campaignId: campaign.id,
                          });
                        }}
                      >
                        {campaign.name}
                      </div>
                      <div onClick={(event) => event.stopPropagation()} style={{ color: '#007bff' }}>
                        <ViewDataOrEditBttn type="button" onClick={() => { setDrawerData({ isOpen: true, campaignId: campaign.id }); }}>
                          <GoGraph style={{ position: 'relative', top: 1 }} />
                          <span style={{ marginLeft: 3 }}>View data</span>
                        </ViewDataOrEditBttn>
                        <ViewDataOrEditBttn type="button" onClick={() => getCampaignData({ campaignId: campaign.id })} style={{ marginLeft: 13 }}>
                          <FaPen size={11} />
                          <span style={{ marginLeft: 3 }}>Edit</span>
                        </ViewDataOrEditBttn>
                      </div>
                      <Portal>
                        <Tooltip id={campaign.name} offset={{ top: 7 }} key={campaign.name}>
                          {campaign.name}
                        </Tooltip>
                      </Portal>
                    </td>
                    <td style={{ maxWidth: 84, minWidth: 84 }}>
                      {campaign.id}
                    </td>
                    <td>{getStatus({ campaignId: campaign.id, uiStatus: campaign.uiStatus })}</td>
                    {/* <td>{campaign.userId}</td> */}
                    <td
                      style={{ maxWidth: 20, overflow: 'hidden', textOverflow: 'ellipsis' }}
                      className={`${hiddenColumns.includes('Country') && 'hidden-cell'}`}
                    >
                      {campaign.targeting.countries.join(', ')}

                    </td>
                    <td className={`bid-cell ${hiddenColumns.includes('Bid') && 'hidden-cell'}`}>
                      {`$ ${campaign.bid}`}
                      <span>
                        <IconButton onClick={(event) => {
                          handleAnchorElClick(event, 'bid');
                          setEditorData({
                            bid: campaign.bid, campaignId: campaign.id, maxBid, recommendedBid,
                          });
                        }}
                        >
                          <FaPen size={13} />
                        </IconButton>
                      </span>
                    </td>
                    <td className={`daily-budget-cell ${hiddenColumns.includes('Budget') && 'hidden-cell'}`}>
                      {`$ ${formatNumber({ number: campaign.dailyBudget })}`}
                      <IconButton
                        onClick={(event) => {
                          handleAnchorElClick(event, 'dailyBudget');
                          setEditorData({
                            dailyBudget: campaign.dailyBudget, campaignId: campaign.id,
                          });
                        }}
                      >
                        <FaPen size={13} />
                      </IconButton>
                    </td>
                    <td data-tip data-for={`spent-${campaign.id}`} className={`${hiddenColumns.includes('Spent') && 'hidden-cell'}`}>
                      {`$${formatNumber({ number: campaignStats?.cost, numberOfDecimals: 2 }) || 0}`}
                      <ProgressBar $width={formattedSpentPercentage as number}>
                        <Bar />
                        <Tooltip id={`spent-${campaign.id}`} offset={{ top: 10 }}>
                          You already spent
                          {' '}
                          {formattedSpentPercentage}
                          % of your Daily Budget ($
                          {campaign.dailyBudget}
                          )
                        </Tooltip>
                      </ProgressBar>
                    </td>
                    <td className={`${hiddenColumns.includes('Opport.') && 'hidden-cell'}`}>{formatNumber({ number: campaignStats?.bids }) || 0}</td>
                    <td className={`${hiddenColumns.includes('Deliveries') && 'hidden-cell'}`}>{formatNumber({ number: campaignStats?.deliveries }) || 0}</td>
                    <td className={`${hiddenColumns.includes('Win Rate') && 'hidden-cell'}`}>
                      {`${formatNumber({ number: campaignStats?.winRate, numberOfDecimals: 2 }) || 0}%`}
                    </td>
                    <td className={`${hiddenColumns.includes('View Content') && 'hidden-cell'}`}>{getViewContent({ viewContent: campaignStats?.viewContent, clicks: campaignStats?.clicks })}</td>
                    <td className={`${hiddenColumns.includes('Clicks') && 'hidden-cell'}`}>{formatNumber({ number: campaignStats?.clicks }) || 0}</td>
                    <td className={`${hiddenColumns.includes('CTR') && 'hidden-cell'}`}>
                      {`${formatNumber({ number: campaignStats?.clickRate, numberOfDecimals: 2 }) || 0}%`}
                    </td>
                    <td className={`${hiddenColumns.includes('Conversions') && 'hidden-cell'}`}>{formatNumber({ number: campaignStats?.conversions }) || 0}</td>
                    <td className={`${hiddenColumns.includes('CPA') && 'hidden-cell'}`}>
                      {`$${formatNumber({ number: campaignStats?.cpa, numberOfDecimals: 2 }) || 0}`}
                    </td>
                  </tr>
                );
              })}
              {campaignsList && !campaignsList.length && (
                <tr className="no-data">
                  <td colSpan={columns.length}>
                    No data to display
                  </td>
                </tr>
              )}
            </tbody>
          )}
        <tfoot>
          <tr>
            <td />
            <td>-</td>
            <td>{`${(!isFetching && campaignsList?.length) || 0} Campaigns`}</td>
            <td>TOTAL</td>
            <td />
            <td className={`${hiddenColumns.includes('Country') && 'hidden-cell'}`}>-</td>
            <td className={`${hiddenColumns.includes('Bid') && 'hidden-cell'}`}>-</td>
            <td className={`${hiddenColumns.includes('Budget') && 'hidden-cell'}`}>-</td>
            <td className={`${hiddenColumns.includes('Spent') && 'hidden-cell'}`}>
              {`$${formatNumber({ number: totalMetrics?.cost, numberOfDecimals: 2 }) || 0}`}
            </td>
            <td className={`${hiddenColumns.includes('Opport.') && 'hidden-cell'}`}>{totalMetrics?.bids || 0}</td>
            <td className={`${hiddenColumns.includes('Deliveries') && 'hidden-cell'}`}>{totalMetrics?.deliveries || 0}</td>
            <td className={`${hiddenColumns.includes('Win Rate') && 'hidden-cell'}`}>
              {`${formatNumber({ number: totalMetrics?.winRate, numberOfDecimals: 2 }) || 0}%`}
            </td>
            <td className={`${hiddenColumns.includes('View Content') && 'hidden-cell'}`}>-</td>
            <td className={`${hiddenColumns.includes('Clicks') && 'hidden-cell'}`}>{formatNumber({ number: totalMetrics?.clicks }) || 0}</td>
            <td className={`${hiddenColumns.includes('CTR') && 'hidden-cell'}`}>
              {`${formatNumber({ number: totalMetrics?.clickRate, numberOfDecimals: 2 }) || 0}%`}
            </td>
            <td className={`${hiddenColumns.includes('Conversions') && 'hidden-cell'}`}>{formatNumber({ number: totalMetrics?.conversions }) || 0}</td>
            <td className={`${hiddenColumns.includes('CPA') && 'hidden-cell'}`}>
              {`$${formatNumber({ number: totalMetrics?.cpa, numberOfDecimals: 2 }) || 0}`}
            </td>
          </tr>
        </tfoot>
      </StyledTable>
      <EditorPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        dynamicData={editorPopoverData[currPopover]}
        campaignId={editorData!?.campaignId}
        bidData={{ maxBid: editorData!?.maxBid, recommendedBid: editorData!?.recommendedBid }}
      />
      <DataDrawer
        isOpen={drawerData.isOpen}
        onClose={() => { setDrawerData((prevState) => ({ ...prevState, isOpen: false })); }}
        campaign={campaignsList?.find((campaign) => campaign.id === drawerData.campaignId)}
        bidData={{ maxBid: editorData!?.maxBid, recommendedBid: editorData!?.recommendedBid }}
      />
      <Tooltip id="winrate-tooltip" place="top" isWinRate>
        Deliveries divided by Opportunities. This number indicates how many
        opportunities are you winning from the total of your target. In order to
        increase it, increase your bid.
      </Tooltip>
    </>
  );
}

export default Table;
