import React, {
  useState, useEffect, useLayoutEffect, useCallback, type Dispatch, type SetStateAction,
} from 'react';
import { ClipLoader } from 'react-spinners';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
  useLazyGetModerationListQuery, useAllowOrRejectUrlMutation, useAllowOrRejectCreativitiesMutation,
  useLazyGetCategoriesQuery,
} from './redux/moderationAPI';
import { useProtectedRoute } from '../../common/hooks/useProtectedRoute';
import { SectionWrapper } from '../../common/components/styles/SectionWrapper';
import {
  Title, NumberOfCampaignsBadge, ResultsNumberAndBulkBttnsContainer,
  ResultsNumber, ButtonsContainer, ApproveButton, RejectButton, CardsWrapper,
} from './components/styles/Moderation.styled';
import { Divider } from '../../common/components/styles/Divider';
import Filters from './components/Filters';
import ModerationCard from './components/ModerationCard';

export type UseSelectedCardsStateHook<T = number> = () => [T[], Dispatch<SetStateAction<T[]>>];

function Moderation() {
  useProtectedRoute({ authorizedUserRoles: ['Super Admin', 'Account Manager'] });

  const { moderationList, totalCampaigns } = useAppSelector(
    ({ moderationSlice: { moderationList, totalCampaigns } }) => (
      { moderationList, totalCampaigns }
    ),
    shallowEqual,
  );

  const [getModerationList, { isLoading, isFetching }] = useLazyGetModerationListQuery();

  const [selectedCards, setSelectedCards] = useState<Array<number>>([]);

  useEffect(() => {
    setSelectedCards([]);
  }, [isLoading, isFetching]);

  const useSelectedCards = useCallback(
    () => [selectedCards, setSelectedCards],
    [selectedCards],
  ) as UseSelectedCardsStateHook;

  const areBulkButtonsDisabled = !selectedCards.length;

  const [allowOrRejectUrl] = useAllowOrRejectUrlMutation();
  const [allowOrRejectCreativities] = useAllowOrRejectCreativitiesMutation();

  const handleBulkBttnClick = ({ method }: { method: 'PUT' | 'DELETE'}) => {
    if (areBulkButtonsDisabled) {
      return;
    }

    allowOrRejectUrl({ method, body: { list: selectedCards } });
    allowOrRejectCreativities({
      method,
      body: {
        list: moderationList?.reduce((acc: Array<number>, curr) => {
          if (selectedCards.includes(curr.campaign.id)) {
            const creativitiesIdsArray = curr.campaign.creativities.map(
              (creativity) => creativity.id,
            );

            return [...acc, ...creativitiesIdsArray];
          }

          return acc;
        }, []),
      },
      isModerationComplete: true,
    });
  };

  const [filters, setFilters] = useState({
    nameOrId: '',
    accountManager: 0,
    platform: '',
    accountRank: '',
    campaignType: '',
    timeOfCreation: 'oldest',
  });

  const [getCategories, { data: categoriesOptions }] = useLazyGetCategoriesQuery();

  useLayoutEffect(() => {
    getCategories();
  }, []);

  const numberOfResults = moderationList?.filter((item) => !item.campaign.isModerated).length;

  return (
    <SectionWrapper padding="89px 35px 35px" lgPaddingLeft="89px">
      <Title>Moderation</Title>
      <NumberOfCampaignsBadge>{`${totalCampaigns} pending campaign${totalCampaigns === 1 ? '' : 's'}`}</NumberOfCampaignsBadge>
      <Filters
        useFiltersState={() => ([filters, setFilters])}
        getModerationList={getModerationList}
        isFetching={isLoading || isFetching}
      />
      <Divider />
      <ResultsNumberAndBulkBttnsContainer>
        <ResultsNumber isFetching={isLoading || isFetching}>
          {!!moderationList && `We found ${numberOfResults} result${numberOfResults === 1 ? '' : 's'}`}
        </ResultsNumber>
        <ButtonsContainer gap={20}>
          <RejectButton isBulkAction isDisabled={areBulkButtonsDisabled} onClick={() => handleBulkBttnClick({ method: 'DELETE' })}>
            Reject all
          </RejectButton>
          <ApproveButton isBulkAction isDisabled={areBulkButtonsDisabled} onClick={() => handleBulkBttnClick({ method: 'PUT' })}>
            Approve all
          </ApproveButton>
        </ButtonsContainer>
      </ResultsNumberAndBulkBttnsContainer>
      <CardsWrapper>
        {isLoading || isFetching
          ? <ClipLoader size={80} cssOverride={{ margin: '0 auto' }} />
          : moderationList?.map((moderationCardData, index) => (
            <ModerationCard
              moderationCardData={moderationCardData}
              useSelectedCards={useSelectedCards}
              setIdFilter={(userId: string) => setFilters(
                (prevState) => ({ ...prevState, nameOrId: userId }),
              )}
              categoriesOptions={categoriesOptions}
              key={index}
            />
          ))}
      </CardsWrapper>
    </SectionWrapper>
  );
}

export default Moderation;
