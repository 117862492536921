/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import Async from 'react-select/async'
import ReactTooltip from 'react-tooltip';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.collapsed ? 0 : '1rem')};
  margin-top: ${(props) => (props.collapsed ? '.3rem' : 0)};
  ${(props) => props.inline && css`
    flex-direction: row;

    label {
      display: flex;
      align-items: center;
      margin: 0 .6rem 0 0;
    }
  `};
  ${(props) => props.autoHeight && css`
    flex: 1;
  `};
  ${(props) => props.autoWidth && css`
    > input {
      width: 200px;
    }
  `};
  ${(props) => props.css && css(props.css)};
`;

export const FieldWrapperDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.collapsed ? 0 : '1rem')};
  margin-top: ${(props) => (props.collapsed ? '.3rem' : 0)};
  padding: '2px 10px';
  border-radius: 5px;
  background-color: #EDF1FF;
  padding: 2px 10px;
  border-radius: 5px;
  
  &:hover {
    background-color: #DCE4FF;
  }


  ${(props) => props.inline && css`
    flex-direction: row;

    label {
      display: flex;
      align-items: center;
      margin: 0 .6rem 0 0;
    }
  `};
  ${(props) => props.autoHeight && css`
    flex: 1;
  `};
  ${(props) => props.autoWidth && css`
    > input {
      width: 200px;
    }
  `};
  ${(props) => props.css && css(props.css)};
`;

export const BreakLine = styled.div`
  flex-basis: 100% 0;
  height: 0;
`;

export const Label = styled.label`
  //font-weight: 500;
  margin-bottom: 4px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  ${(props) => props.small && css`
    font-size: .9em;
  `};
  ${(props) => props.new && css`
    display: flex;
    align-items: center;

    &:before {
      background-color: red;
      border-radius: 4px;
      color: white;
      content: "NEW";
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      margin-right: 5px;
      padding: 3px 5px;
    }
  `};
`;

export const StyledError = styled.div`
  background-color: #F8D7DA;
  border-radius: 8px;
  color: #721C24;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > svg {
    cursor: pointer;
  }
`;

export const HiddenInputWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const HiddenInput = styled.input.attrs({
  type: 'hidden',
})`
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

export const inputStyles = css`
  border: solid 1px ${(props) => (props.error ? 'red' : '#CED4DA')};
  border-radius: 10px;
  color: #495057;
  padding: ${(props) => (props.type !== 'range' ? '6px 12px' : 0)};
  font-family: Poppins, sans-serif;
  font-size: 15px;

  &:focus {
    border-color: #80BDFF;
  }

  &:disabled, &:read-only {
    background-color: #E9ECEF;
  }
`;

export const StyledInput = styled.input`
  ${inputStyles};
`;

export const DPStyles = css`
  border: none;
  border-radius: 10px;
  color: #4A76FF;
  font-weight: 500;
  padding: ${(props) => (props.type !== 'range' ? '6px 12px' : 0)};
  background-color: transparent;
`;

export const DPickerInput = styled.input`
  ${DPStyles};
`;

export const StyledTextarea = styled.textarea`
  ${inputStyles};
  min-height: 80px;
`;

const selectDisabledStyles = css`
  background-color: #DDD;
  color: #777;
  pointer-events: none;
`;
const selectEnabledStyles = css`
  cursor: text;
`;

export const selectStyles = css`
  flex: 1;

  ${(props) => props.style && css(props.style)};

  .select__control {
    border-radius: 10px;
    ${(props) => (props.disabled ? selectDisabledStyles : selectEnabledStyles)};
    ${(props) => props.error && css`border-color: red;`}
  }

  .select__option {
    cursor: pointer;
    z-index: 100;
  }

  .select__value-container--is-multi {
    > div {
      width: auto !important;
    }
  }

  .select__multi-value {
    background-color: ${(props) => (props.excluded ? '#ED3030' : props.excluded === false ? '#3B88FD' : '#556270')};
    transition: linear 0.15s;
    border-radius: 8px;
  }

  .select__menu {
    z-index: 100;
  }

  .select__multi-value__label {
    color: #FFF;
    white-space: nowrap;
    ${(props) => props.disabled && css`
      padding-right: 6px;
    `}
  }

  .select__multi-value__remove {
    color: ${(props) => (props.excluded !== 'undefined' ? '#fff' : '#FF6B6B')};
    cursor: pointer;
    ${(props) => props.disabled && css`
      display: none;
    `};
  }

  .select__clear-indicator {
    cursor: pointer;
  }

  .select__dropdown-indicator {
    cursor: pointer;
  }

  .select__placeholder {
    color: ${(props) => (props.disabled ? '#777' : '#495057')};
  }
`;

export const SelectorWrapper = styled.div`
  position: relative;
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${selectStyles};
`;

export const StyledSelect = styled(Select).attrs({
  classNamePrefix: 'select',
  blurInputOnSelect: false,
})`

  .select__indicator-separator {
      display: none
  }

  ${selectStyles};
`;

export const SelectWrapper = styled.div`
  ${(props) => props.flexible && css`
    display: flex;
    width: 100%;

    > div:not(:last-of-type) {
      margin-right: 10px;
    }
  `};
`;

export const StyledCheckbox = styled.span`
  margin-right: 10px;

  input {
    margin-right: 5px;
  }

  label {
    margin: 0;
  }
`;

export const Card = styled.div`
  display: inline-flex;
  flex-flow: ${(props) => (props.selected ? 'column wrap' : 'row wrap')};
  align-items: ${(props) => (props.selected ? 'left' : 'center')};
  height: ${(props) => (props.selected ? 'calc(100% - 280px)' : 'auto')};
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${(props) => (props.selected ? '#3b88fd' : '#DEE2E6')};
  border-radius: 8px;

  label {
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
  }

  label, input {
    cursor: pointer;
  }

  &:hover {
    background-color: #F4F6F9;
  }

  .subComponent {
    width: 81%;
    margin-top: 0px;
    margin-left: -1.8rem !important;
    margin-right: auto !important;
    padding: 15px 26px;
  }

  ${(props) => (props.horizontal
    ? css`
            padding: 0 0 0 1.8rem;
            justify-content: ${(props) => (props.selected ? 'flex-start' : 'space-between')};

            &:not(:first-of-type) {
              margin: .6rem 0 0;
            }

            > input {
              width: ${(props) => (props.selected ? '20px' : 'auto')};
              order: 0
            }

            > * {
              order: 1;
              margin: 0 1.2rem 0 0 !important;
            }

            > img {
              order: 10;
              height: 100%;
              background-color: #F1F3F8;
              width: 176px;
              padding: 20px;
              margin-right: 1px !important;
              margin-left: auto !important;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }

            p.w-75 {
              padding-left: ${(props) => (props.selected ? '26px' : '0')};
            }

            label, p {
              margin: ${(props) => (props.selected ? '20px 0 0 0 !important' : '0 !important')};
              text-align: left;
            }

            .subComponent div p {
              margin-top: 0px !important;
            }
          `
    : css`
            padding: 1.8rem 2.4rem;
            width: 240px;
            flex-direction: column;
            margin-right: 1.2rem;
            margin-bottom: .6rem;
            justify-content: space-between;

            > div:first-of-type {
              text-align: center;
            }
          `)};
  ${(props) => props.new && css`
    position: relative;

    &:before {
      background: red;
      border-radius: 4px;
      color: white;
      content: "BETA";
      font-size: .75rem;
      font-weight: 600;
      line-height: .85;
      padding: .25rem .4rem;
      position: absolute;
      left: .9rem;
      top: .9rem;
    }
  `}
`;

export const CardSelector = styled.div`
  //margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  ${(props) => props.horizontal && css`
    flex: 1;
    //height: 100%;
    //display: flex;
    flex-direction: column;
    margin-bottom: 0;

    ${Card} {
      flex: 1;
    }
  `};
`;

export const TextWrapper = styled.div`
  pointer-events: none;
`;

export const CardDescription = styled.p`
  font-size: 1em;
  margin: .3rem 0 .6rem;
  text-align: center;
`;

export const IncludeExpludeWrapper = styled.div`
`;

export const HelpTooltip = styled(ReactTooltip).attrs((props) => ({
  effect: 'solid',
  delayHide: 300,
  delayShow: 300,
  delayUpdate: 500,
  place: props.place || 'left',
  backgroundColor: '#333',
  textColor: '#fff',
}))`
  &.type-custom {
    border-radius: 8px;
    font-size: .8rem;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    max-width: 300px;
    padding: 1rem;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
`;
