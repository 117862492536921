/* eslint-disable no-useless-escape */
import * as Joi from 'joi';
import { formatOptions, subOptionsPush } from './formatsInfo.js';

const validFormats = [...formatOptions.filter(
  (f) => f.visible,
).map(
  (f) => f.name,
), ...subOptionsPush.filter((f) => f.visible).map((f) => f.name)];

const rulesRegExp = {
  'valid-url': /^(http(?:s)?\:\/\/[a-zA-Z0-9]+(?:(?:\.|\-)[a-zA-Z0-9]+)+(?:\:\d+)?(?:\/[\w\-]+)*(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+\=[\w\-]+)?)?(?:\&[\w]+\=[\w\-]+)*)/,
};

const creativitiesRule = {
  id: Joi.number(),
  title: Joi.string().required().label('Title'),
  // .error(new Error('Title is not allowed to be empty.')),
  description: Joi.string().required().label('Description'),
  // .error(new Error('Description is not allowed to be empty.')),
  image: Joi.string().allow(null, ''),
  __isNew__: Joi.bool().allow(null),
  status: Joi.string().allow(null, ''),
  user_id: Joi.number().allow(null, ''),
  offer_id: Joi.number().allow(null, ''),
  live: Joi.number().allow(null, ''),
  created_at: Joi.string().allow(null, ''),
  updated_at: Joi.string().allow(null, ''),
  moderated_at: Joi.string().allow(null, ''),
  archived_at: Joi.string().allow(null, ''),
  rejected_at: Joi.string().allow(null, ''),
  status_id: Joi.number().allow(null, ''),
};
const iconNotAllowedRule = Joi.string().valid('', null);

const iconAllowedRule = Joi.alternatives()
  .try(Joi.string().dataUri(), Joi.string()).required()
  .label('Icon')
  .messages({
    'string.dataUri': 'Use a valid file to continue.',
    'string.empty': 'Use a valid file to continue.',
    'alternatives.match': 'Use a valid file to continue.',
    'alternatives.types': 'Use a valid file to continue.',
    'alternatives.any': 'Use a valid file to continue.',
    'any.empty': 'Use a valid file to continue.',
  });

export const creativitiesByFormatRules = {
  push: Joi.array().min(1).required()
    .label('Creativities')
    .items(Joi.object({
      ...creativitiesRule,
      icon: iconAllowedRule,
    }))
    .messages({
      'array.min': 'At least 1 running creativity is needed.',
    }),
  calendar: Joi.array().min(1).required()
    .label('Creativities')
    .items(Joi.object({
      ...creativitiesRule,
      icon: iconNotAllowedRule,
    }))
    .messages({
      'array.min': 'At least 1 running creativity is needed.',
    }),
};

export const rules = {
  name: Joi.string().trim().min(1).max(255)
    .required()
    .label('Name'),
  url: Joi.string().regex(rulesRegExp['valid-url']).required()
    .label('Tracking Url')
    .messages({
      'string.empty': '*Mandatory. This is the destination URL after someone clicks on your Ad',
      'string.pattern.base': 'Please use a valid URL here',
    }),
  category_id: Joi.alternatives().try(Joi.string(), Joi.number())
    .label('Category')
    .messages({
      'string.empty': '*Mandatory. Set a category for the campaign',
    }),
  formats: Joi.array().min(1).required()
    .label('Formats')
    .items(Joi.string().valid(...validFormats))
    .error(new Error('Mandatory')),
  icon: iconAllowedRule,
  title: creativitiesRule.title,
  description: creativitiesRule.description,
  countries: Joi.array().min(1).required()
    .label('Country')
    .messages({
      'array.max': 'Only those with an <a href="http://help.pushground.com/en/articles/5190616-account-ranks" target="_blank">account rank</a> of gold or above can set multiple countries per campaign.',
      'array.min': '*Mandatory. Set a Country for the campaign',
    }),
  bid: Joi.number().min(0.001).required()
    .label('Bid'),
  daily_budget: Joi.number().required()
    .label('Daily Budget'),
};
