import React, { memo } from 'react';
import {
  StyledAside, TipImage, TipTitle, TipText,
} from './TipsAside.styled';

const TIPS = [
  {
    image: '/assets/login-aside-tips/quick-tip-01.png',
    tip: 'We recommend separating campaigns by device type (i.e. Mobile and Desktop).',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-02.png',
    tip: 'Page loading speeds can have a huge impact on conversion rates so be sure that your landing page loads quickly in your targeted geo.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-03.png',
    tip: 'We recommend using at least 3-5 creatives for each campaign.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-04.png',
    tip: 'Using pre-landers can help you improve the performance of your campaigns.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-05.png',
    tip: 'Make the most of our helpful account managers. Their sole job is to help you succeed.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-06.png',
    tip: 'We have found user freshness to be one of the most significant conversion factors. Make sure to take advantage of this targeting.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-07.png',
    tip: 'Having a low win rate means you could be left with the lowest quality traffic. Aim for a win rate of at least 40%.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-08.png',
    tip: 'Depending on your offer, you might want to target specific days or times when conversions rates are higher.',
  },
  {
    image: '/assets/login-aside-tips/quick-tip-09.png',
    tip: 'Make sure there is consistency between your ads, landing pages and offers.',
  },
];

function TipsAside() {
  const getRandomTip = () => TIPS[Math.floor(Math.random() * TIPS.length)];
  const { image, tip } = getRandomTip();

  return (
    <StyledAside>
      <TipImage src={image} alt="Quick tip" />
      <TipTitle>Quick tip</TipTitle>
      <TipText>{tip}</TipText>
    </StyledAside>
  );
}

export default memo(TipsAside);
