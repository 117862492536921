export const userFreshnessOptions = [
  'Unspecified',
  '0-1 hours',
  '1-2 hours',
  '2-6 hours',
  '6-12 hours',
  '12 hours-1 day',
  '1-2 days',
  '2-7 days',
  '7-14 days',
  '14-21 days',
  '21-30 days',
  '30-45 days',
  '45-60 days',
  '+60 days',
];

export const userFreshnessSummary = [
  { value: 'Unspecified' },
  { value: '0-1 hours', from: '0 hours' },
  { value: '1-2 hours', from: '1 hour', to: '2 hours' },
  { value: '2-6 hours', from: '2 hours', to: '6 hours' },
  { value: '6-12 hours', from: '6 hours', to: '12 hours' },
  { value: '12 hours-1 day', from: '12 hours', to: '1 day' },
  { value: '1-2 days', from: '1 day', to: '2 days' },
  { value: '2 days', from: '2 days', to: '7 days' },
  { value: '7-14 days', from: '7 days', to: '14 days' },
  { value: '14-21 days', from: '14 days', to: '21 days' },
  { value: '21-30 days', from: '21 days', to: '30 days' },
  { value: '30-45 days', from: '30 days', to: '45 days' },
  { value: '45-60 days', from: '45 days', to: '60 days' },
  { value: '+60 days', to: '+60 days' },
];
