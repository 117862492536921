import React, { type ChangeEvent, type Dispatch, type SetStateAction } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import {
  PaginationInputContainer, PaginationInput, NumberOfRowsSelect, StyledIconButton,
} from './styles/TablePagination';
import { StyledOption } from '../../../common/components/styles/Select';
import { CenteredFlexContainer, FlexContainer } from '../../../common/components/styles/Flex';

interface TablePaginationProps {
    numberOfPages: number;
    currPage: number;
    setCurrPage: Dispatch<SetStateAction<number>>;
    numberOfRows: number;
    setNumberOfRows: Dispatch<SetStateAction<number>>;
}

function TablePagination({
  numberOfPages, currPage, setCurrPage, numberOfRows, setNumberOfRows,
}: TablePaginationProps) {
  const hanldePaginationInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9]+$/;
    const isInputNumber = numberRegex.test(event.target.value);

    if (isInputNumber && +event.target.value <= numberOfPages!) {
      setCurrPage(+event.target.value);
    }
  };

  return (
    <CenteredFlexContainer>
      <StyledIconButton
        disabled={currPage === 1}
        onClick={() => setCurrPage((prevState) => prevState - 1)}
      >
        <div style={{ fontSize: 10, height: 12 }}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </div>
      </StyledIconButton>
      <PaginationInputContainer>
        <PaginationInput value={currPage} onChange={hanldePaginationInputChange} />
        &nbsp;/&nbsp;
        {numberOfPages}
      </PaginationInputContainer>
      <StyledIconButton
        disabled={currPage === numberOfPages}
        onClick={() => setCurrPage((prevState) => prevState + 1)}
      >
        <div style={{ fontSize: 10, height: 12 }}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </div>
      </StyledIconButton>
      <NumberOfRowsSelect
        value={numberOfRows}
        onChange={(event) => setNumberOfRows(event.target.value as number)}
      >
        {[5, 10, 25, 50].map((number) => (
          <StyledOption value={number} key={number}>
            {number}
          </StyledOption>
        ))}
      </NumberOfRowsSelect>
    </CenteredFlexContainer>
  );
}

export default TablePagination;
