import styled, { css } from 'styled-components';

export const Indexes = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 0 -1px;
  padding: 0;
  position: relative;
  z-index: 1;
  overflow-x: auto;
`;

export const Action = styled.button.attrs({
  type: 'button',
})`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: .3rem;
  font-weight: 600;
  color: #3B88FD;
`;

export const HiddenInput = styled.input.attrs({
  type: 'hidden',
})`
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid #000;
  border-radius: 8px;
  height: 2.4rem;
  padding: 0 .75rem;
`;

export const TextIconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1.2rem;
  padding-left: 1.2rem;

  svg {
    margin-right: .6rem;
  }

  ${(props) => props.color && css`
    border-color: ${props.color};
    color: ${props.color};
    transition: .2s;

    svg {
      fill: ${props.color};
    }

    &:hover {
      background-color: ${props.color};
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  `};
`;

export const Index = styled.li`
  font-size: .8em;
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-bottom: none;
  cursor: pointer;

  &:hover {
    border-color: #DEE2E6;
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    border-radius: 4px;
    padding: 0 5px;
    //font-size: .9em;
  }

  ${(props) => props.selected && css`
    background-color: #fff;
    border-color: #DEE2E6;
    cursor: default;
  `};
  ${(props) => props.error && css`
    color: red;
  `};
  ${(props) => props.runningStatus === 'running' && css`
    span {
      background-color: #1bb157;
      color: #fff;
    }
  `};
  ${(props) => props.runningStatus === 'rejected' && css`
    span {
      background-color: #DC3545;
      color: #fff;
    }
  `};
  ${(props) => props.runningStatus === 'moderation' && css`
    span {
      border: 1px solid #aaaaaa;
      box-sizing: border-box;
    }
  `};
  ${(props) => props.runningStatus === 'paused' && css`
    span {
      background-color: #ebebeb;
      border: 1px solid #aaaaaa;
      box-sizing: border-box;
      color: #888888;
    }
  `};
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #DEE2E6;
  border-radius: 0px 4px 4px;
  padding: 16px;
  overflow-x: auto;
`;

export const Creativity = styled.div`
  display: flex;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 10px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  > * {
    margin: 0;
  }

  textarea {
    flex: 1;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
