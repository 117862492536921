import React, { type Dispatch, type SetStateAction } from 'react';
import { shallowEqual } from 'react-redux';
import {
  FaWallet, FaExclamationTriangle, FaArrowUp, FaArrowDown,
} from 'react-icons/fa';
import { Fade as Hamburger } from 'hamburger-react';
import { useAppSelector } from '../../../app/hooks';
import {
  StyledHeader, HamburgerAndLogoContainer, LogoLink, Logo, HeaderRightSide,
  SpendingAndBalanceWrapper, SpendingContainer, BalanceWarningIconWrapper,
  BalanceWarningIconConatiner, PercentageContainer, ArrowContainer, BalanceTooltip,
  BalanceTooltipRow, TooltipWarningWrapper, TooltipWarningIconContainer, AddFundsButton, Divider,
  UserMenuToggleButton, UserRankLogo, UserName, UserMenuArrow,
} from './styles/Header.styled';
import UserMenu from './UserMenu';
import formatNumber from '../../../common/utils/formatNumber';

type UseStateHook = () => [boolean, Dispatch<SetStateAction<boolean>>];

interface HeaderProps {
  isDashboardLoaded: boolean;
  useIsSideNavbarOpen: UseStateHook;
  useIsUserMenuOpen: UseStateHook;
}

const getNumberColor = (number: number) => !!number && (number >= 0 ? '#56e497' : '#ea7474');
const getArrowUpOrDown = (number: number) => (
  !!number && (
    number >= 0 ? <FaArrowUp color="#56e497" /> : <FaArrowDown color="#ea7474" />
  )
);

function Header({ isDashboardLoaded, useIsSideNavbarOpen, useIsUserMenuOpen }: HeaderProps) {
  const [isSideNavbarOpen, setIsSideNavbarOpen] = useIsSideNavbarOpen();
  const [isUserMenuOpen, setIsUserMenuOpen] = useIsUserMenuOpen();

  const {
    userName, userRole, userRank, currentBalance, todaySpent, weekSpent, isRunningOutOfBalance,
  } = useAppSelector(
    ({ loginSlice: { user } }) => {
      const { name: userName, role: userRole = '' } = user || {};
      const {
        current: currentBalance, todaySpent, weekSpent, isRunningOutBalance: isRunningOutOfBalance,
      } = user?.balance || {};

      return ({
        userName,
        userRole,
        userRank: user?.rank?.current || 'Bronze',
        currentBalance: formatNumber({ number: currentBalance, numberOfDecimals: 2 }),
        todaySpent: {
          value: formatNumber({ number: todaySpent?.value, numberOfDecimals: 2 }),
          variation: formatNumber({ number: todaySpent?.variation, numberOfDecimals: 2 }) as number,
        },
        weekSpent: {
          value: formatNumber({ number: weekSpent?.value, numberOfDecimals: 2 }),
          variation: formatNumber({ number: weekSpent?.variation, numberOfDecimals: 2 }) as number,
        },
        isRunningOutOfBalance,
      });
    },
    shallowEqual,
  );

  const isUserPublisherOrSuperAdmin = ['Publisher', 'Super Admin'].includes(userRole);

  const closeUserMenu = () => {
    setIsUserMenuOpen(false);
    setIsSideNavbarOpen(false);
  };

  return (
    <StyledHeader top={!isDashboardLoaded ? '-100%' : 0}>
      <HamburgerAndLogoContainer>
        <Hamburger toggled={isSideNavbarOpen} toggle={setIsSideNavbarOpen} size={21} />
        <LogoLink to={userRole === 'Publisher' ? '/campaigns/stats' : '/campaigns'}>
          <Logo src="/assets/pushground.png" alt="Pushground logo" />
        </LogoLink>
      </HamburgerAndLogoContainer>
      <HeaderRightSide>
        {!isUserPublisherOrSuperAdmin && (
          <>
            <SpendingAndBalanceWrapper data-tip data-for="balance-tooltip">
              <SpendingContainer className="data-container">
                <span>Today spent: </span>
                <span>{`$ ${todaySpent.value}`}</span>
                <PercentageContainer color={getNumberColor(todaySpent.variation) || undefined}>
                  <ArrowContainer>{getArrowUpOrDown(todaySpent.variation)}</ArrowContainer>
                  {` ${Math.abs(todaySpent.variation)}%`}
                </PercentageContainer>
              </SpendingContainer>
              <div className="data-container">
                <span>Balance: </span>
                <span>{`$ ${currentBalance}`}</span>
              </div>
              {isRunningOutOfBalance && (
                <BalanceWarningIconWrapper>
                  <BalanceWarningIconConatiner>
                    <FaExclamationTriangle />
                  </BalanceWarningIconConatiner>
                </BalanceWarningIconWrapper>
              )}
            </SpendingAndBalanceWrapper>
            <BalanceTooltip id="balance-tooltip">
              <h4>Your spending:</h4>
              <BalanceTooltipRow>
                <span>
                  Today spent:
                  <strong>{` $ ${todaySpent.value}`}</strong>
                </span>
                <PercentageContainer color={getNumberColor(todaySpent.variation) || undefined}>
                  <ArrowContainer>{getArrowUpOrDown(todaySpent.variation)}</ArrowContainer>
                  {` ${Math.abs(todaySpent.variation)}%`}
                </PercentageContainer>
              </BalanceTooltipRow>
              <BalanceTooltipRow>
                <span>
                  This week spent:
                  <strong>{` $ ${weekSpent.value}`}</strong>
                </span>
                <PercentageContainer color={getNumberColor(weekSpent.variation) || undefined}>
                  <ArrowContainer>{getArrowUpOrDown(weekSpent.variation)}</ArrowContainer>
                  {` ${Math.abs(weekSpent.variation)}%`}
                </PercentageContainer>
              </BalanceTooltipRow>
              {isRunningOutOfBalance && (
                <TooltipWarningWrapper>
                  <TooltipWarningIconContainer>
                    <FaExclamationTriangle size={16} color="#d13e3d" />
                  </TooltipWarningIconContainer>
                  <div>
                    Ups! It looks like
                    <strong> soon you will run out of budget</strong>
                    . We recommend you to add some balance.
                  </div>
                </TooltipWarningWrapper>
              )}
            </BalanceTooltip>
            <AddFundsButton to="/topup">
              <FaWallet size={14} color="#FFF" />
              <span>Add Funds</span>
            </AddFundsButton>
            <Divider />
          </>
        )}
        <UserMenuToggleButton type="button" onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
          {!isUserPublisherOrSuperAdmin && <UserRankLogo src={`/assets/user-rank-logos/${userRank}.svg`} alt="User rank logo" />}
          <UserName>{userName}</UserName>
          <UserMenuArrow size={20} color="#fff" $rotationDegrees={isUserMenuOpen ? 0 : 180} />
        </UserMenuToggleButton>
        <UserMenu
          isOpen={isUserMenuOpen}
          closeUserMenu={closeUserMenu}
          isUserPublisherOrSuperAdmin={isUserPublisherOrSuperAdmin}
        />
      </HeaderRightSide>
    </StyledHeader>
  );
}

export default Header;
