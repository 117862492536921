import styled from 'styled-components/macro';
import { Popper } from '@mui/material';

export const CustomColumnsBttn = styled.button`
    height: 35px;
    width: 35px;
    padding: 8px;
    border: 1px solid rgb(217, 221, 229);
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
`;

export const StyledPopper = styled(Popper)`
    &&& {
        z-index: 2000;
    }
`;

export const PopperContainer = styled.div`
    position: relative;
    top: 10px;
    width: 400px;
    background-color: #FFF;
    border: .5px solid #d4d4d4;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;

export const PopperTitle = styled.h3`
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
`;

export const CboxesContainer = styled.div`
    padding: 15px 30px 20px 27px;
`;

export const PopperCboxRow = styled.div`
    display: flex;
    align-items: start;
    height: 40px;
`;
