import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { resetApiState } from '../../../app/services/pushgroundApi';
import { resetAllSlicesState } from '../../../common/utils/resetAllSlicesStateAction';
import {
  StyledUserMenu, RankWrapper, CurrentRankLogo, CurrentRank, RankProgressBarContainer,
  NextMonthRankSpendingRange, RanksArticleLink, Tooltip, ProgressBarTooltipHelper,
} from './styles/UserMenu.styled';
import { StyledMenuLink, MenuIconContainer } from './styles/Dashboard.styled';
import menuItems from '../data/userMenuItems';
import formatNumber from '../../../common/utils/formatNumber';

interface UserMenuProps {
  isOpen: boolean;
  isUserPublisherOrSuperAdmin: boolean;
  closeUserMenu(): void;
}

function UserMenu({ isOpen, isUserPublisherOrSuperAdmin, closeUserMenu }: UserMenuProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(resetAllSlicesState());
    dispatch(resetApiState());
    navigate('/login');
  };

  const {
    userRank, progress, daysLeft, monthSpent,
  } = useAppSelector(
    ({ loginSlice: { user } }) => ({
      userRank: user?.rank?.current || 'Bronze',
      progress: user?.rank?.progress || {
        current: 'platinum', next: 'platinum', min: 0, value: 0, max: 0,
      },
      daysLeft: user?.rank?.daysLeft,
      monthSpent: formatNumber({ number: user?.balance?.monthSpent, numberOfDecimals: 2 }),
    }),
    shallowEqual,
  );

  const progressBarRankLogos = {
    nextMonthRank: {
      name: progress.current,
      tooltipId: 'next-month-rank-tooltip',
    },
    rankToAchive: {
      name: progress.next,
      tooltipId: 'rank-to-achieve-tooltip',
    },
  };

  const progressBarWidth = progress.value < progress.min
    ? 0 : (100 * (progress.value - progress.min)) / (progress.max - progress.min);

  const progressBarColor: {[key: string]: string} = {
    bronze: '#a47c52',
    silver: '#C7C7C7',
    gold: '#FDCC4D',
  };

  return (
    <StyledUserMenu isOpen={isOpen}>
      {!isUserPublisherOrSuperAdmin && (
        <RankWrapper>
          <div className="current-rank-container" data-tip data-for="current-rank-tooltip">
            <CurrentRankLogo src={`/assets/user-rank-logos/${userRank}.svg`} alt="Current rank logo" />
            <CurrentRank>{userRank}</CurrentRank>
            <Tooltip id="current-rank-tooltip" place="left" clickable>
              {`Your current rank is ${userRank}. Learn more about rankings `}
              <a href="http://help.pushground.com/en/articles/5190616-account-ranks" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </Tooltip>
          </div>
          {!!daysLeft && (
            <div className="days-left-container" data-tip data-for="days-left-tooltip">
              Days left:
              <strong>{` ${daysLeft} `}</strong>
              <FaInfoCircle size={13} />
              <Tooltip id="days-left-tooltip">
                Days lefts till next rank change.
                Ranks are calculated every month based on the spend of the previous month.
              </Tooltip>
            </div>
          )}
          {progress.current !== 'platinum' && (
            <RankProgressBarContainer
              progressBarWidth={progressBarWidth}
              nextMonthRankColor={progressBarColor[progress.current]}
            >
              {Object.values(progressBarRankLogos).map((rankLogo, index) => (
                <img
                  src={`/assets/user-rank-logos/${rankLogo.name}.svg`}
                  alt={`${rankLogo.name} logo`}
                  data-tip
                  data-for={rankLogo.tooltipId}
                  key={index}
                />
              ))}
              <div className="progress-bar" />
              <Tooltip id="next-month-rank-tooltip" place="left">
                {`Next month's rank: ${progress.current}, based on this month's spending.`}
              </Tooltip>
              <Tooltip id="rank-to-achieve-tooltip" place="left">
                {`Spend $${progress.max - progress.value} more this month to reach ${progress.next}.`}
              </Tooltip>
              <ProgressBarTooltipHelper data-tip data-for="progress-bar-tooltip">
                <Tooltip id="progress-bar-tooltip" place="bottom">
                  {`This month spent is $${monthSpent}.`}
                  <br />
                  To reach
                  <strong>{` ${progress.next}`}</strong>
                  {`, spend $${progress.max - progress.value} or more this month.`}
                </Tooltip>
              </ProgressBarTooltipHelper>
            </RankProgressBarContainer>
          )}
          <NextMonthRankSpendingRange>
            <span>{`$${progress.min}`}</span>
            <span>{`$${progress.max}`}</span>
          </NextMonthRankSpendingRange>
          <RanksArticleLink href="http://help.pushground.com/en/articles/5190616-account-ranks" target="_blank" rel="noreferrer">
            What is this?
          </RanksArticleLink>
        </RankWrapper>
      )}
      {menuItems.map((menuItem) => {
        const isExternalLink = !!menuItem.url;
        const isLogoutOption = menuItem.label === 'Logout';

        return (
          <StyledMenuLink
            key={menuItem.label}
            {...(isLogoutOption ? { as: 'div', onClick: logOut } : { onClick: closeUserMenu })}
            {...(!isLogoutOption && (
              isExternalLink
                ? { as: 'a', href: menuItem.url, target: '_blank' }
                : { to: menuItem.route }
            ))}
          >
            <MenuIconContainer>
              <img src={menuItem.icon} alt={`${menuItem.label} icon`} />
            </MenuIconContainer>
            <span>{menuItem.label}</span>
          </StyledMenuLink>
        );
      })}
    </StyledUserMenu>
  );
}

export default UserMenu;
