import styled from 'styled-components/macro';

interface SectionWrapperProps {
    backgroundColor?: string;
    padding?: string;
    lgPaddingLeft?: string;
}

export const SectionWrapper = styled.section<SectionWrapperProps>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    min-height: 100vh;
    padding: ${({ padding }) => padding || '89px 25px 35px'};

    ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
        padding-left: ${({ lgPaddingLeft }) => lgPaddingLeft || '79px'}; ;
    }
`;
