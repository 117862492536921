import { pushgroundApi } from '../../../app/services/pushgroundApi';

export const createCampaignApi = pushgroundApi.injectEndpoints({
  endpoints: (builder) => ({
    getFormData: builder.query<any, void>({
      query: () => 'https://api.beta.pushground.com/v2/campaign',
    }),
    getCities: builder.query({
      query: (country) => `https://api.beta.pushground.com/v2/campaign/country/${country}/cities`,
      transformResponse: ({ list }) => list,
    }),
    getRegions: builder.query({
      query: (country) => `https://api.beta.pushground.com/v2/campaign/country/${country}/regions`,
      transformResponse: ({ list }) => list,
    }),
    getTargetingReach: builder.query({
      query: (body) => ({
        url: 'utils/discarded-traffic',
        method: 'POST',
        body,
      }),
      transformResponse: ({ discardedPercentage }) => discardedPercentage,
    }),
    deleteAutomaticRulesTemplate: builder.mutation({
      query: (data) => ({
        url: 'advertisers/automatic-rules/templates',
        method: 'DELETE',
        data,
      }),
    }),
    saveAutomaticRulesTemplate: builder.mutation({
      query: (data) => ({
        url: 'advertisers/automatic-rules/templates',
        method: 'POST',
        data,
      }),
    }),
    getTemplates: builder.query({
      query: () => 'https://api.beta.pushground.com/v2/campaign/templates',
      transformResponse: (response: any) => response?.list || response,
    }),
    postCampaign: builder.mutation({
      query: (formData) => ({
        url: 'https://api.beta.pushground.com/v2/campaign',
        method: 'POST',
        body: formData,
      }),
    }),
    putCampaign: builder.mutation({
      query: ({ formdata, campaignId }) => ({
        url: `https://api.beta.pushground.com/v2/campaign/${campaignId}`,
        method: 'PUT',
        body: formdata,
      }),
    }),
    getCampaignData: builder.query({
      query: ({ campaignId }) => `https://api.beta.pushground.com/v2/campaign/${campaignId}`,
    }),
  }),
});

export const {
  useLazyGetFormDataQuery,
  useLazyGetTemplatesQuery,
  useLazyGetCitiesQuery,
  useLazyGetRegionsQuery,
  useLazyGetTargetingReachQuery,
  useDeleteAutomaticRulesTemplateMutation,
  useSaveAutomaticRulesTemplateMutation,
  usePostCampaignMutation,
  usePutCampaignMutation,
  useLazyGetCampaignDataQuery,
} = createCampaignApi;
