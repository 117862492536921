import styled from 'styled-components/macro';
import { Checkbox, FormControlLabel } from '@mui/material';
import DatePicker from 'react-datepicker';

export const LeftSideFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const LeftSideFiltersContainerBttn = styled.button.attrs({ type: 'button' })`
    font-size: 15px;
    height: 40px;
    padding: 0px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
`;

export const CreateCampaignBttn = styled(LeftSideFiltersContainerBttn)`
    color: #FFF;
    background-color: #f00;
    text-decoration: none;

    &:hover {
        background-color: #db0000;
    }
`;

export const FiltersBttn = styled(LeftSideFiltersContainerBttn)`
    gap: 8px;
    background-color: #f1f3f8;

    &:hover {
        background-color: #dedfe3;
    }
`;

export const FiltersBttnPopper = styled.div`
    position: relative;
    top: 10px;
    width: 400px;
    background-color: #FFF;
    border: .5px solid #d4d4d4;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;

export const FiltersPopperTitle = styled.h3`
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
`;

export const FiltersFormControlLabel = styled(FormControlLabel)`
    && {
        text-transform: capitalize;
        width: fit-content;
    }
    
    > .MuiFormControlLabel-label {
        font-family: Poppins, sans-serif;
        font-size: 14px;
    }
`;

export const SearchByNameOrIdInput = styled.input`
    font-size: 15px;
    color: #495057;
    width: 200px;
    height: 40px;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 10px;

    &:focus {
        border-color: #80bdff;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    .css-i4bv87-MuiSvgIcon-root {
        width: .8em;
    }
`;

export const FiltersPopperButton = styled.button`
    font-size: 14px;
    border-radius: 8px;
    padding: 6px 30px;
`;

export const ResetButton = styled(FiltersPopperButton)`
    background-color: rgb(241, 243, 248);
`;

export const ApplyButton = styled(FiltersPopperButton)`
    background-color: rgb(74, 118, 255);
    color: #fff;
    margin-left: 15px;
`;
