/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
import _ from 'lodash';

export const ConditionalWrapper = ({
  condition, wrapperTrue, wrapperFalse, children,
}) => (condition ? wrapperTrue(children) : wrapperFalse(children));

export const humanizeNumber = (number) => {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
    'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty',
    'ninety'];

  const numString = number.toString();

  if (number < 0) throw new Error('Negative numbers are not supported.');

  if (number === 0) return 'zero';

  // the case of 1 - 20
  if (number < 20) {
    return ones[number];
  }

  if (numString.length === 2) {
    return `${tens[numString[0]]} ${ones[numString[1]]}`;
  }

  // 100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0') {
      return `${ones[numString[0]]} hundred`;
    }
    return `${ones[numString[0]]} hundred and ${humanizeNumber(+(numString[1] + numString[2]))}`;
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return `${ones[numString[0]]} thousand`;
    if (end < 100) return `${ones[numString[0]]} thousand and ${humanizeNumber(end)}`;
    return `${ones[numString[0]]} thousand ${humanizeNumber(end)}`;
  }
};

export const numberWithCommas = (number, decimals = 0) => parseFloat(number).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parseDecimals = (bid, decimals = 2) => parseFloat(parseFloat(bid).toFixed(decimals));

export const currencyString = (number, symbol) => {
  if (symbol === '€') {
    return `${numberWithCommas(number, 2)} ${symbol}`;
  } if (symbol === '$') {
    return `${symbol}${numberWithCommas(number, 2)}`;
  }
};

export const countDecimals = (number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
  return number.toString().split('.')[1].length || 0;
};

export const validateUrl = (url) => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);

export const mapOptions = (optionsObject) => Object.keys(optionsObject).map((k) => ({
  value: k,
  label: optionsObject[k],
}));

export const capitalizeString = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}

export function order(items, sortValue, sortOrder) {
  return _.orderBy(items, (item) => _.get(item, sortValue) || 0, [sortOrder]);
}

export function search(items, searchParameter, searchedValue) {
  if (!searchParameter) {
    return _.filter(items, (item) => item.id.toString().toLowerCase().includes(
      searchedValue.toString().toLowerCase(),
    )
      || item.name.toString().toLowerCase().includes(searchedValue.toString().toLowerCase()));
  }
  return _.filter(items, (item) => item[searchParameter].toString().toLowerCase().includes(
    searchedValue.toString().toLowerCase(),
  ));
}

export function filter(items, filters) {
  return filters
    ? items.filter((item) => Object.keys(filters)
      .every((filterBy) => {
        if (filters[filterBy] === null) {
          return true;
        } if (Array.isArray(_.get(item, filterBy))) {
          return filters[filterBy].some((i) => !!_.find(_.get(item, filterBy), i));
        } if (filterBy == 'targeting' && filters[filterBy].length) {
          return item.targeting.formats.some((r) => filters[filterBy].includes(r));
        }
        return _.get(item, filterBy) === filters[filterBy];

        // return filters[filterBy] === null || _.get(item, filterBy)?.includes(filters[filterBy])
      }))
    : items;
}

export const sanitizeStringAccent = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const getBidByFormatsCountries = (
  formatsCountryRates,
  formats,
  countries,
  isBrandsafe = false,
) => {
  const bids = countries.reduce((prevValues, country) => {
    const { minBid, recBid, maxBid } = formats.reduce((prevValues, format) => {
      const theBids = isBrandsafe ? formatsCountryRates.brandsafe : formatsCountryRates.default;

      const formatCountryMinBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.min_bid || 0;
      const formatCountryRecBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.custom_bid || theBids?.[isBrandsafe ? 'push' : format]?.[country]?.calculated_bid || 0;
      const formatCountryMaxBid = theBids?.[isBrandsafe ? 'push' : format]?.[country]?.max_bid || 0;

      return {
        minBid: Math.max(prevValues.minBid, formatCountryMinBid),
        recBid: Math.max(prevValues.recBid, formatCountryRecBid),
        maxBid: Math.max(prevValues.maxBid, formatCountryMaxBid),
      };
    }, { minBid: 0, recBid: 0, maxBid: 0 });

    return {
      minBid: Math.max(prevValues.minBid, minBid),
      recBid: Math.max(prevValues.recBid, recBid),
      maxBid: Math.max(prevValues.maxBid, maxBid),
    };
  }, { minBid: 0, recBid: 0, maxBid: 0 });

  return bids;
};
