import { createSlice } from '@reduxjs/toolkit';
import { omitBy } from 'lodash';
import { resetAllSlicesState } from '../../../common/utils/resetAllSlicesStateAction';
import { transformTempalteToLaravel } from '../components/ruleTemplates.js';
import { createCampaignApi } from './createCampaignAPI';

interface InitialState {
  formData: any;
  automaticRules: any;
  creativities: any;
}

const initialState = {
  formData: {},
  creativities: {
    list: [{
      description: undefined,
      icon: undefined,
      id:
      1,
      image:
      undefined,
      title:
      undefined,
      __isNew__:
      true,
    }],
    creativities: [],
    status: '',
  },
  validationErrors: {},
  createConfig: {},
  automaticRules: {
    rules: '',
    ruleoptions: {
      metrics: {
        'Content View': 'viewContent', roi: 'ROI', cpa: 'CPA', clicks: 'Clicks', deliveries: 'Deliveries', conversions: 'Conversions', winRate: 'Win Rate', clickRate: 'CTR', conversionRate: 'Conversion Rate', revenue: 'Spent',
      },
      dimensions: {
        offer: 'Campaign', supply: 'Supply', source: 'Supply + Source', userAge: 'Freshness',
      },
      clauses: { less: 'Lower', greater: 'Higher' },
      within: {
        last30minutes: '30m', lastHour: '1h', last2hours: '2h', today: 'Today', last24hours: '24h', last48hours: '48h', last72hours: '72h', last7days: '7d', last15days: '15d', last30days: '30d',
      },
      freq: {
        30: '30m', 120: '2h', 240: '4h', 360: '6h', 720: '12h', 1440: '24h',
      },
      actions: {
        pause: 'Pause', resume: 'Resume', increaseBid: 'Increase Bid', decreaseBid: 'Decrease Bid', increaseBudget: 'Increase Budget',
      },
    },
    rulesconditions: '',
    ruleactions: '',
    ruletemplates: [],
    currentbudget: '',
    hasrules: '',
    templatesUpdatedError: null,
    templatesUpdatedMessage: null,
    loading: false,
  },
} as InitialState;

export const createCampaignSlice = createSlice({
  name: 'createCampaignSlice',
  initialState,
  reducers: {
    updateAutomaticRule: (state, action) => {
      const rule = action.payload;
      let { rules } = state.automaticRules;
      if (rules === '') rules = [];
      const index = rules.findIndex((r: any) => r.id === rule.id);

      if (index > -1) rules[index] = rule;
      else rules.push(rule);

      state.automaticRules.rules = rules;
    },
    updateAutomaticRules: (state, action) => {
      state.automaticRules.rules = action.payload;
    },
    updateAutomaticActions: (state, action) => {
      state.automaticRules.ruleactions = action.payload;
    },
    updateRuleTemplate: (state, action) => {
      state.automaticRules.templatesUpdatedError = null;
      state.automaticRules.templatesUpdatedMessage = null;
      state.automaticRules.loading = true;
    },
    deleteRuleTemplate: (state, action) => {
      state.automaticRules.templatesUpdatedError = null;
      state.automaticRules.templatesUpdatedMessage = null;
      state.automaticRules.loading = true;
    },
    resetRuleTemplateMessages: (state) => {
      state.automaticRules.templatesUpdatedError = null;
      state.automaticRules.templatesUpdatedMessage = null;
      state.automaticRules.loading = false;
    },
    updateCreativitiesList: (state, action) => {
      state.creativities.list = action.payload;
    },
    updateCreativitiesStatus: (state, action) => {
      state.creativities.status = action.payload;
    },
    updateCreativity: (state, action) => {
      const { id, inputName, inputValue } = action.payload;
      const index = state.creativities.list.findIndex((c: any) => c.id === id);
      state.creativities.list[index][inputName] = inputValue;
    },
    setValidationError: (state: any, action) => ({
      ...state,
      [action.payload.section]: {
        ...state[action.payload.section],
        [action.payload.field]: action.payload.error,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllSlicesState, () => initialState);
    builder.addMatcher(createCampaignApi.endpoints.getFormData.matchFulfilled, (state, action) => {
      state.formData = action.payload;
    });
    builder.addMatcher(
      createCampaignApi.endpoints.deleteAutomaticRulesTemplate.matchFulfilled,
      (state, action) => {
        const response = action.payload.data;

        if (!response.errors?.length) {
          const templates = state.automaticRules.ruletemplates;
          const newTemplates = omitBy(
            templates,
            (t: any) => t[0]?.template_id === action.meta.arg.originalArgs.ruleIds[0],
          );

          state.automaticRules.ruletemplates = newTemplates;
          state.automaticRules.templatesUpdatedMessage = 'Template deleted correctly.';
          state.automaticRules.loading = false;
        } else {
          state.automaticRules.templatesUpdatedError = 'There was a problem deleting your template, please try later or contact your AM.';
        }

        setTimeout(() => {
          state.automaticRules.loading = false;
        }, 800);
      },
    );
    builder.addMatcher(
      createCampaignApi.endpoints.deleteAutomaticRulesTemplate.matchFulfilled,
      (state, action) => {
        const response = action.payload.data;
        if (!response.errors?.length) {
          const templates = state.automaticRules.ruletemplates;
          const newTemplates = {
            ...templates,
            [response.name]: response.ruleTemplates.map(
              (r: any) => ({ template_id: response.id, ...r }),
            ).map(transformTempalteToLaravel),
          };
          state.automaticRules.ruletemplates = newTemplates;
          state.automaticRules.templatesUpdatedMessage = 'Template saved correctly.';
        } else {
          state.automaticRules.templatesUpdatedError = 'There was a problem saving your template, please try later or contact your AM.';
        }

        setTimeout(() => {
          state.automaticRules.loading = false;
        }, 800);

        if (!response.errors?.length) {
          const templates = state.automaticRules.ruletemplates;
          const newTemplates = omitBy(
            templates,
            (t: any) => t[0]?.template_id === action.meta.arg.originalArgs.ruleIds[0],
          );

          state.automaticRules.ruletemplates = newTemplates;
          state.automaticRules.templatesUpdatedMessage = 'Template deleted correctly.';
        } else {
          state.automaticRules.templatesUpdatedError = 'There was a problem deleting your template, please try later or contact your AM.';
        }

        setTimeout(() => {
          state.automaticRules.loading = false;
        }, 800);
      },
    );
    builder.addMatcher(
      createCampaignApi.endpoints.deleteAutomaticRulesTemplate.matchRejected,
      (state, action) => {
        state.formData = action.error;
      },
    );
    builder.addMatcher(
      createCampaignApi.endpoints.getCampaignData.matchFulfilled,
      (state, action) => {
        state.campaignData = action.payload;
      },
    );
  },
});

export const {
  updateAutomaticRule,
  updateAutomaticRules,
  updateAutomaticActions,
  updateRuleTemplate,
  deleteRuleTemplate,
  resetRuleTemplateMessages,
  updateCreativitiesList,
  setValidationError,
  updateCreativitiesStatus,
  updateCreativity,
} = createCampaignSlice.actions;

export default createCampaignSlice.reducer;
