/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as CloseIcon } from '@fortawesome/free-solid-svg-icons';
import { StyledError } from './styles/AutomaticRules';

function Error({ error, onClose = null, ...rest }) {
  return (error && (
  <StyledError>
    <div dangerouslySetInnerHTML={{ __html: error }} />
    {onClose && <FontAwesomeIcon onClick={onClose} icon={CloseIcon} />}
  </StyledError>
  )
  );
}

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.bool]),
  onClose: PropTypes.func,
};

export default Error;
