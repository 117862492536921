import React, {
  useState, useEffect,
  type MouseEvent, type Dispatch, type SetStateAction, useDeferredValue, useLayoutEffect,
} from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { BiFilterAlt } from 'react-icons/bi';
import {
  Fade, Popper, Divider, ClickAwayListener,
} from '@mui/material';
import moment from 'moment';
import {
  LeftSideFiltersContainer, CreateCampaignBttn, FiltersBttn, FiltersFormControlLabel,
  SearchByNameOrIdInput, FiltersBttnPopper, FiltersPopperTitle, StyledCheckbox,
  ResetButton, ApplyButton,
} from './styles/Filters';
import { CampaignsList } from '../redux/campaignsSlice';
import { useSendCampaignToModerationMutation } from '../redux/campaignsAPI';
import Calendar from './Calendar';

interface FiltersProps {
  campaignsList: CampaignsList | undefined;
  currentCampaignsList: CampaignsList | undefined;
  setCurrentCampaignsList: Dispatch<SetStateAction<CampaignsList | undefined>>;
  setIsFiltering: Dispatch<SetStateAction<boolean>>;
  getCampaignsStats: any;
}

function Filters({
  campaignsList, setCurrentCampaignsList, setIsFiltering, getCampaignsStats,
}: FiltersProps) {
  const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());
  const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());

  const [openFiltersBttnPopper, setOpenFiltersBttnPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFiltersBttnClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenFiltersBttnPopper((prevState) => !prevState);
  };

  const [statusCboxState, setStatusCboxState] = useState<{[key: string]: boolean}>({
    active: true, inactive: true,
  });

  const [formats, setFormats] = useState<Array<string>>([]);
  const [formatsCboxState, setFormatsCboxState] = useState<{[key: string]: boolean}>({});

  const getFormats = (campaigns: CampaignsList | undefined) => {
    if (formats.length) return formats;

    const fmts: Array<string> = [];

    if (campaigns?.length) {
      campaigns.forEach((campaign) => {
        if (campaign?.targeting?.formats?.length) {
          campaign.targeting.formats.map((format) => fmts.push(format));
        }
      });
    }

    fmts.sort();

    setFormats(Array.from(new Set(fmts)));
    setFormatsCboxState(
      Array.from(new Set(fmts)).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    );
    return '';
  };

  useEffect(() => {
    getFormats(campaignsList);
  }, [campaignsList?.length]);

  const [nameOrIdInputValue, setNameOrIdInputValue] = useState('');

  const deferredNameOrIdFilter = useDeferredValue(nameOrIdInputValue);

  const filterCampaigns = () => {
    const filteredCampaignsList = campaignsList?.filter((campaign) => (
      campaign.name.toLowerCase().includes(deferredNameOrIdFilter.toLowerCase())
      || campaign.id.toString().includes(deferredNameOrIdFilter)
    ));

    const selectedStatus = Object.keys(statusCboxState).filter((key) => statusCboxState[key]);
    const selectedFormats = Object.keys(formatsCboxState).filter((key) => formatsCboxState[key]);

    const filteredList = filteredCampaignsList?.filter((campaign) => (
      selectedStatus.some((status) => (status === 'active' && campaign.active) || (status === 'inactive' && !campaign.active))
      && (selectedFormats.some((format) => {
        if (campaign?.targeting?.formats?.length) {
          return campaign.targeting.formats.some((campaignFormat) => campaignFormat === format);
        }

        return false;
      }) || !formats.length)));

    setCurrentCampaignsList(filteredList);
  };

  useEffect(() => {
    setIsFiltering(true);

    const timeoutId = setTimeout(() => {
      filterCampaigns();
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [deferredNameOrIdFilter]);

  const reset = () => {
    const filteredCampaignsList = campaignsList?.filter((campaign) => (
      campaign.name.toLowerCase().includes(deferredNameOrIdFilter.toLowerCase())
      || campaign.id.toString().includes(deferredNameOrIdFilter)
    ));

    setCurrentCampaignsList(filteredCampaignsList);
    setStatusCboxState({ inactive: true, active: true });
    setFormatsCboxState(
      formats.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    );
  };

  useEffect(() => {
    filterCampaigns();
  }, [JSON.stringify(campaignsList || [])]);

  const [campaignIdToModeration, setCampaignIdToModeration] = useState('');

  const [sendCampaignToModeration] = useSendCampaignToModerationMutation();

  useLayoutEffect(() => {
    if (campaignsList?.length) {
      setIsFiltering(true);
      const requestBody = {
        dateStart: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dimensions: ['offer'],
        filters: {
          offer: campaignsList.map(({ id }) => id),
        },
      };

      (async () => {
        const { status } = await getCampaignsStats({ body: requestBody, addResponseToStore: true });

        if (status === 'fulfilled') {
          filterCampaigns();
        }
      })();
    }
  }, [campaignsList?.length, startDate?.toDateString(), endDate?.toDateString()]);

  return (
    <div style={{
      display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: 20,
    }}
    >
      <LeftSideFiltersContainer>
        <CreateCampaignBttn as={Link} to="/campaigns/create">+ Create Campaign</CreateCampaignBttn>
        <ClickAwayListener onClickAway={() => setOpenFiltersBttnPopper(false)}>
          <div>
            <FiltersBttn onClick={handleFiltersBttnClick}>
              <BiFilterAlt />
              Filters
            </FiltersBttn>
            <Popper
              open={openFiltersBttnPopper}
              anchorEl={anchorEl}
              transition
              style={{ zIndex: 2 }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <FiltersBttnPopper>
                    <FiltersPopperTitle>Filters</FiltersPopperTitle>
                    <Divider />
                    <div style={{ display: 'flex', fontSize: 14, padding: '10px 0 15px 25px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <FiltersFormControlLabel
                          label="All status"
                          control={(
                            <StyledCheckbox
                              checked={statusCboxState.active && statusCboxState.inactive}
                              onChange={() => {
                                if (Object.values(statusCboxState).some((status) => !status)) {
                                  setStatusCboxState({ inactive: true, active: true });
                                } else {
                                  setStatusCboxState({ inactive: false, active: false });
                                }
                              }}
                            />
                      )}
                        />
                        <FiltersFormControlLabel
                          label="Active"
                          control={(
                            <StyledCheckbox
                              checked={statusCboxState.active}
                              onChange={() => setStatusCboxState(
                                (prevState) => ({ ...prevState, active: !prevState.active }),
                              )}
                            />
                      )}
                        />
                        <FiltersFormControlLabel
                          label="Inactive"
                          control={(
                            <StyledCheckbox
                              checked={statusCboxState.inactive}
                              onChange={() => setStatusCboxState(
                                (prevState) => ({ ...prevState, inactive: !prevState.inactive }),
                              )}
                            />
                          )}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <FiltersFormControlLabel
                          label="All formats"
                          control={(
                            <StyledCheckbox
                              checked={Object.values(formatsCboxState).every((format) => format)}
                              onChange={() => {
                                if (Object.values(formatsCboxState).some((status) => !status)) {
                                  setFormatsCboxState(
                                    formats.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
                                  );
                                } else {
                                  setFormatsCboxState(
                                    formats.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
                                  );
                                }
                              }}
                            />
                            )}
                        />
                        {!!formats.length && formats.map((format) => (
                          <FiltersFormControlLabel
                            key={format}
                            label={format.split('_').join(' ')}
                            control={(
                              <StyledCheckbox
                                checked={formatsCboxState[format]}
                                onChange={() => {
                                  setFormatsCboxState(
                                    (prevState) => ({ ...prevState, [format]: !prevState[format] }),
                                  );
                                }}
                              />
                            )}
                          />
                        ))}
                      </div>
                    </div>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
                      <ResetButton
                        type="button"
                        onClick={() => {
                          setOpenFiltersBttnPopper(false);
                          reset();
                        }}
                      >
                        Reset
                      </ResetButton>
                      <ApplyButton
                        type="button"
                        onClick={() => {
                          setOpenFiltersBttnPopper(false);
                          filterCampaigns();
                        }}
                      >
                        Apply
                      </ApplyButton>
                    </div>
                  </FiltersBttnPopper>
                </Fade>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
        <SearchByNameOrIdInput
          type="text"
          value={nameOrIdInputValue}
          onChange={(event) => setNameOrIdInputValue(event.target.value)}
          placeholder="Search by Name or ID"
        />
        <div>
          <SearchByNameOrIdInput
            type="text"
            value={campaignIdToModeration}
            onChange={(event) => setCampaignIdToModeration(event.target.value)}
            placeholder="Campaign ID to Moder."
          />
          <button
            type="button"
            onClick={() => {
              sendCampaignToModeration({ campaignId: campaignIdToModeration });
              setCampaignIdToModeration('');
            }}
            style={{
              backgroundColor: '#f1f3f8', height: 35, marginLeft: 5, borderRadius: 5,
            }}
          >
            Send
          </button>
        </div>
      </LeftSideFiltersContainer>
      <Calendar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
  );
}

export default Filters;
