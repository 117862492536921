import React, {
  useState, forwardRef, type Dispatch, type SetStateAction, type MouseEvent,
} from 'react';
import DatePicker from 'react-datepicker';
import { IconButton, MenuItem } from '@mui/material';
import { FaRegCalendarAlt } from 'react-icons/fa';
import moment from 'moment';
import { StyledCustomInput, CalendarButton, StyledMenu } from './styles/Calendar';

type HandleMenuItemClickParams = { startDate: Date | null; endDate: Date | null};

const menuItems = [
  { item: 'Today', startDate: moment().startOf('day').toDate(), endDate: moment().endOf('day').toDate() },
  { item: 'Yesterday', startDate: moment().subtract(1, 'day').startOf('day').toDate(), endDate: moment().subtract(1, 'day').endOf('day').toDate() },
  { item: 'Last 7 days', startDate: moment().subtract(7, 'day').startOf('day').toDate(), endDate: moment().endOf('day').toDate() },
  { item: 'Last 30 days', startDate: moment().subtract(30, 'day').startOf('day').toDate(), endDate: moment().endOf('day').toDate() },
  { item: 'This month', startDate: moment().startOf('month').toDate(), endDate: moment().endOf('day').toDate() },
  { item: 'Last month', startDate: moment().subtract(1, 'month').startOf('month').toDate(), endDate: moment().subtract(1, 'month').endOf('month').toDate() },
];

const CustomInput = forwardRef(({
  value, onClick, setStartDate, setEndDate,
}: any, ref) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handleIconButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = ({ startDate, endDate }: HandleMenuItemClickParams) => {
    handleMenuClose();
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <StyledCustomInput>
      <IconButton size="medium" onClick={handleIconButtonClick}>
        <FaRegCalendarAlt size={14} color="#4a76ff" />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {menuItems.map(({ item, startDate, endDate }) => (
          <MenuItem onClick={() => handleMenuItemClick({ startDate, endDate })} key={item}>
            {item}
          </MenuItem>
        ))}
      </StyledMenu>
      <CalendarButton type="button" onClick={onClick} ref={ref as any}>
        {value}
      </CalendarButton>
    </StyledCustomInput>
  );
});

interface CalendarProps {
    startDate: Date | null;
    setStartDate: Dispatch<SetStateAction<Date | null>>;
    endDate: Date | null;
    setEndDate: Dispatch<SetStateAction<Date | null>>;
}

function Calendar({
  startDate, setStartDate, endDate, setEndDate,
}: CalendarProps) {
  const onChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        onChange={onChange}
        selectsRange
        customInput={<CustomInput setStartDate={setStartDate} setEndDate={setEndDate} />}
        dateFormat="MMM dd, yyyy"
        calendarStartDay={1}
        showPopperArrow={false}
      />
    </div>
  );
}

export default Calendar;
