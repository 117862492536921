const getIconRelativePath = (iconName: string) => `/assets/side-navbar-icons/${iconName}-icon.svg`;

const campaigns = {
  icon: getIconRelativePath('campaigns'),
  label: 'Campaigns',
  route: '/campaigns',
};

const createCampaign = {
  icon: getIconRelativePath('create-campaign'),
  label: 'Create Campaign',
  route: '/campaigns/create',
};

const campaignStats = {
  icon: getIconRelativePath('campaign-stats'),
  label: 'Campaign Stats',
  route: '/campaigns/stats',
};

const cpcRates = {
  icon: getIconRelativePath('cpc-rates'),
  label: 'CPC Rates',
  route: '/utils/cpcrates',
};

const tracking = {
  icon: getIconRelativePath('tracking'),
  label: 'Tracking',
  route: '/postback',
};

const audiences = {
  icon: getIconRelativePath('audiences'),
  label: 'Audiences',
  route: '/audiences',
};

const moderation = {
  icon: getIconRelativePath('moderation'),
  label: 'Moderation',
  route: '/admin/moderation',
};

const users = {
  icon: getIconRelativePath('users'),
  label: 'Users',
  route: '/admin/users',
};

const flags = {
  icon: getIconRelativePath('flags'),
  label: 'Flags',
  route: '/admin/users/flags',
};

const supplyControl = {
  icon: getIconRelativePath('supply-control'),
  label: 'Supply Control',
  route: '/admin/supply',
};

const feedStats = {
  icon: getIconRelativePath('feed-stats'),
  label: 'Feed stats',
  route: '/stats/feed',
};

const globalBlacklist = {
  icon: getIconRelativePath('global-blacklist'),
  label: 'Global Blacklist',
  route: '/admin/feeds/globalBlacklists',
};

const recommendedBids = {
  icon: getIconRelativePath('recommended-bids'),
  label: 'Recommended Bids',
  route: '/admin/feeds/bids',
};

const supplyFeeds = {
  icon: getIconRelativePath('supply-feeds'),
  label: 'Supply Feeds',
  route: '/admin/feeds/supply',
};

const demandFeeds = {
  icon: getIconRelativePath('demand-feeds'),
  label: 'Demand Feeds',
  route: '/admin/feeds/demand',
};

const sourcesTranslator = {
  icon: getIconRelativePath('sources-translator'),
  label: 'Sources Translator',
  route: '/admin/feeds/sources',
};

const billypushDiscrepancies = {
  icon: getIconRelativePath('billypush-discrepancies'),
  label: 'BillyPush Discrepancies',
  route: '/admin/feeds/demand/discrepancies',
};

const priorityLevels = {
  icon: getIconRelativePath('priority-levels'),
  label: 'Priority Levels',
  url: 'http://help.pushground.com/en/articles/5190616-account-ranks',
};

const promotions = {
  icon: getIconRelativePath('promotions'),
  label: 'Promotions',
  route: '/user/promotions',
};

const faqs = {
  icon: getIconRelativePath('faqs'),
  label: 'FAQs & Docs',
  url: 'http://help.pushground.com/en/',
};

interface MenuItem {
    icon: string;
    label: string;
    route?: string;
    url?: string;
}

const superAdminMenuItems: Array<MenuItem> = [
  campaigns, createCampaign, campaignStats, cpcRates, tracking, audiences, moderation, users, flags,
  supplyControl, feedStats, globalBlacklist, recommendedBids, supplyFeeds, demandFeeds,
  sourcesTranslator, billypushDiscrepancies, priorityLevels, promotions, faqs,
];

const accountManagerMenuItems: Array<MenuItem> = [
  campaigns, createCampaign, campaignStats, cpcRates, tracking, audiences, moderation, users, flags,
  priorityLevels, promotions, faqs,
];

const advertiserMenuItems: Array<MenuItem> = [
  campaigns, createCampaign, campaignStats, cpcRates,
  tracking, audiences, priorityLevels, promotions, faqs,
];

const publisherMenuItems: Array<MenuItem> = [
  campaignStats, priorityLevels, promotions, faqs,
];

const menuItems: {[key: string]: Array<MenuItem>} = {
  'Super Admin': superAdminMenuItems,
  'Account Manager': accountManagerMenuItems,
  Advertiser: advertiserMenuItems,
  Publisher: publisherMenuItems,
};

export default menuItems;
