import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export const pushgroundApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.pushground.com/',
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { apiKey } = (getState() as RootState).loginSlice;

      if (endpoint !== 'logInUser' && !!apiKey) {
        headers.set('Authorization', apiKey);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['ModerationList', 'SupplyFeedBlacklist', 'DataDrawerCreativitiesList'],
});

export const { util: { resetApiState } } = pushgroundApi;
