import styled from 'styled-components/macro';

export const Banner = styled.div`
    font-size: 15px;
    background-color: #f8f9fa;
    padding: 16px;
    margin: 20px 0 40px;

    .top-up-link {
        color: #007bff;
        text-decoration: none;
        
        :hover {
            text-decoration: underline;
        }
    }
`;

export const NoPaymentsYet = styled.h4`
    font-size: 24px;
    font-weight: 500;
    color: #17a2b8;
    text-align: center;
`;

export const StyledTable = styled.table`
    border-collapse: collapse;
    
    thead {
        text-align: left;
        background-color: lightgrey;
        
        th {
            padding: 5px 10px;
            cursor: default;
        }
    }
    
    tbody {
        tr {
            :nth-of-type(odd) {
                background-color: #f4f6f9;
            }

            :hover {
                background-color: #e6e8eb;
            }

            td {
                padding: 10px;
            }
        }

    }
`;
