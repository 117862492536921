import { useState, type MouseEvent } from 'react';

type PopoverData = { anchorEl: HTMLButtonElement | HTMLDivElement | null; currPopover: string; };

const usePopover = () => {
  const [{ anchorEl, currPopover }, setPopoverData] = useState<PopoverData>({ anchorEl: null, currPopover: 'name' });

  const handleAnchorElClick = (
    event: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    currentPopover: string,
  ) => {
    event.stopPropagation();
    setPopoverData({ anchorEl: event.currentTarget, currPopover: currentPopover });
  };

  const setAnchorEl = (anchorElement: HTMLButtonElement | null) => {
    setPopoverData((prevState) => ({ ...prevState, anchorEl: anchorElement }));
  };

  return {
    anchorEl, currPopover, handleAnchorElClick, setAnchorEl,
  };
};

export default usePopover;
