import React, { useState, type Dispatch, type SetStateAction } from 'react';
import {
  Dialog, Alert, Collapse, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledDialogTitle, StyledIconButton, StyledDialogContent, StyledDialogTable,
} from './styles/CPCRatesDialog';
import { StyledCloseIcon } from '../../moderation/components/styles/RejectDialog.styled';

interface CPCRatesDialogProps {
    openDialog: boolean;
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
    title: string;
    tableContent: Array<{country: string; recBid: string;}>;
}

function CPCRatesDialog({
  openDialog, setOpenDialog, title, tableContent,
}: CPCRatesDialogProps) {
  const [openAlert, setOpenAlert] = useState(true);

  const handleClose = () => {
    setOpenDialog(false);
    setOpenAlert(true);
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <StyledDialogTitle>
        {title}
        <StyledIconButton onClick={handleClose}>
          <StyledCloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            severity="warning"
            action={(
              <IconButton
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
          )}
          >
            {'If you don\'t find the country here, ask your AM.'}
          </Alert>
        </Collapse>
        <StyledDialogTable>
          <thead>
            <tr>
              <td>Country</td>
              <td>Recommended Bid</td>
            </tr>
          </thead>
          <tbody>
            {tableContent.map(({ country, recBid }) => (
              <tr key={country}>
                <td>{country}</td>
                <td>{recBid}</td>
              </tr>
            ))}
          </tbody>
        </StyledDialogTable>
      </StyledDialogContent>
    </Dialog>
  );
}

export default CPCRatesDialog;
