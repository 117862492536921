import { Row, Col } from 'react-bootstrap';
import {
  Form, Fieldset, FieldsetTitle, SmallMsg, SubmitButton, Message,
} from './styles/common.styled.js';
import Input from './Input.jsx';
import Checkbox from './Checkbox.jsx';
import { updateSettings } from '../redux/userSettingsSlice';
import { useAppSelector } from '../../../app/hooks';

function Security() {
  const { password, passwordConfirmation, skipTwoFactor } = useAppSelector(({
    userSettingsSlice: { security },
  }) => security);

  return (
    <Fieldset noMargin>
      <Form>
        <Row>
          <Col>
            <div style={{ margin: '0 15px' }}>
              <FieldsetTitle fontSize={22}>Change Password</FieldsetTitle>
              <Input
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={({ target }) => updateSettings(
                  { security: { password: target.value } },
                )}
              >
                <SmallMsg style={{ marginBottom: 5 }}>Min length 8 characters</SmallMsg>
                <Input
                  label="Confirm password"
                  type="password"
                  name="password_confirmation"
                  value={passwordConfirmation}
                  onChange={({ target }) => updateSettings(
                    { security: { passwordConfirmation: target.value } },
                  )}
                />
              </Input>
            </div>
          </Col>
          <Col>
            <div style={{ margin: '0 15px' }}>
              <FieldsetTitle fontSize={22}>Security</FieldsetTitle>
              <Message style={{ marginBottom: 15 }}>
                The two-step verification method strengthens the security of your account,
                by disabling it you may be putting your account at risk.
              </Message>
              <Checkbox
                options={{ twoFactorAuth: 'Enable Two Factor Authentication' }}
                selectedOptions={!skipTwoFactor ? ['twoFactorAuth'] : []}
                onCheck={(values) => updateSettings({ security: { skip_2fa: !values.includes('skipTwoFactor') ? 1 : 0 } })}
              />
            </div>
          </Col>
        </Row>
        <SubmitButton style={{ marginTop: 15 }}>Save Security</SubmitButton>
      </Form>
    </Fieldset>
  );
}

export default Security;
