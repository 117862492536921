import { createSlice } from '@reduxjs/toolkit';
import { campaignsApi, type Campaign } from './campaignsAPI';
import { resetAllSlicesState } from '../../../common/utils/resetAllSlicesStateAction';

interface Metrics {
  [key: string]: number;
  cost: number;
  bids: number;
  deliveries: number;
  winRate: number;
  viewContent: number;
  clicks: number;
  clickRate: number;
  conversions: number;
  cpa: number;
}

export type CampaignsList = Array<Campaign> | null;

export type Leaf = {
  dimensions: {[key: string]: string; offer: string; supplyFeed: string;};
  metrics: Metrics
};

export type CampaignsStats = {
  leafs: Array<Leaf>;
  total: { metrics: Metrics };
};

interface InitialState {
  campaignsList: CampaignsList;
  campaignsStats: CampaignsStats | null;
}

const initialState = {
  campaignsList: null,
  campaignsStats: null,
} as InitialState;

export const campaignsSlice = createSlice({
  name: 'campaignsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetAllSlicesState, () => initialState);
    builder.addMatcher(campaignsApi.endpoints.getCampaignsList.matchFulfilled, (state, action) => {
      state.campaignsList = action.payload;
    });
    builder.addMatcher(campaignsApi.endpoints.getCampaignsStats.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.addResponseToStore) {
        state.campaignsStats = action.payload || {};
      }
    });
    builder.addMatcher(campaignsApi.endpoints.updateCampaign.matchFulfilled, (state, action) => {
      const storedCampaign = state.campaignsList?.find(
        ({ id: campaignId }) => campaignId === action.payload.id,
      );

      const valueToChange = Object.keys(action.meta.arg.originalArgs.body)[0];

      if (storedCampaign) {
        storedCampaign[valueToChange] = action.payload[valueToChange];
      }
    });
  },
});

export default campaignsSlice.reducer;
