import { ReactElement } from 'react';
import { FiCheck } from 'react-icons/fi';
import {
  FaTimes, FaCheckCircle, FaExclamationCircle, FaTimesCircle,
} from 'react-icons/fa';

const urlApproveOrRejectBttnIcons = {
  approve: <FiCheck color="#4176EE" size={22} />,
  reject: <FaTimes color="#E02020" size={16} />,
};

const domainStatusIcons: {[key: string]: ReactElement<string | number, any>} = {
  good: <FaCheckCircle color="#56E497" size={17} />,
  warning: <FaExclamationCircle color="#989898" size={17} />,
  dangerous: <FaTimesCircle color="#E45656" size={17} />,
};

export { urlApproveOrRejectBttnIcons, domainStatusIcons };
