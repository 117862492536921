import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { FaAngleUp } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

interface StyledHeaderProps {
    top: number | string;
}

export const StyledHeader = styled.header<StyledHeaderProps>`
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: ${({ top }) => top};
    transition: top .6s;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #2C2C2C;
    color: #FFF;
    padding: 15px 15px 15px 0px;
    box-shadow: 0 0 5px #000;
    z-index: 11;
`;

export const HamburgerAndLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    
    ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
        gap: 19px;
    }
`;

const logoStyles = css`
    height: 17px;

    ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
        height: 24px;
    }
`;

export const LogoLink = styled(Link)`
    ${logoStyles}
`;

export const Logo = styled.img`
    ${logoStyles}
`;

export const HeaderRightSide = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const SpendingAndBalanceWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14.4px;
    color: white;
    line-height: 1.3;
    border: 1px #878787 solid;
    border-radius: 5px;
    margin-left: 15px;
    cursor: default;
    
    .data-container {
        padding: 7px 20px;
        max-height: 62px;

        span {
            display: inline-block;
            white-space: pre;
    
            :not(:first-child) {
                font-weight: 600;
            }
        }
    }

`;

export const SpendingContainer = styled.div`
    display: none;

    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
        display: block;
        border-right: 1px solid #878787 ;
    }
`;

export const BalanceWarningIconWrapper = styled.div`
    margin-left: 20px;
`;

export const BalanceWarningIconConatiner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #535353;
    width: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
`;

interface PercentageContainerProps {
    color: string | undefined;
}

export const PercentageContainer = styled.span<PercentageContainerProps>`
    &&& {
        display: inline;
    }

    color: ${({ color }) => color};
    margin-left: 15px;
`;

export const ArrowContainer = styled.span`
    position: relative;
    top: 2px;
`;

export const BalanceTooltip = styled(ReactTooltip).attrs({
  effect: 'solid',
  place: 'bottom',
  backgroundColor: '#414141',
  delayHide: 300,
  delayShow: 300,
})`
    && {
        width: 350px;
        padding: 20px 10px;
    }

    h4 {
        margin-bottom: 15px;
    }
`;

export const BalanceTooltipRow = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #535353;
    padding: 10px;
    margin-top: 10px;
`;

export const TooltipWarningWrapper = styled.div`
    position: relative;
    text-align: center;
    line-height: 1.2;
    background-color: #3e3737;
    padding: 40px 20px 30px;
    margin: 30px -10px -20px;
`;

export const TooltipWarningIconContainer = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    background-color: #FFF;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
`;

export const AddFundsButton = styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14.4px;
    color: #FFF;
    line-height: 1;
    text-decoration: none;
    white-space: nowrap;
    height: 100%;
    background-color: #4A76FF;
    padding: 5px 22px;
    border: 1px #4A76FF solid;
    border-radius: 5px;
`;

const hideOnSmAndMdScreen = css`
    display: none;

    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
        display: block;
    }
`;

export const Divider = styled.div`
    ${hideOnSmAndMdScreen}

    width: 1px;
    height: 24px;
    background-color: #fff;
`;

export const UserMenuToggleButton = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
`;

export const UserRankLogo = styled.img`
    ${hideOnSmAndMdScreen}
    width: 25px;
`;

export const UserName = styled.span`
    ${hideOnSmAndMdScreen}
    font-size: 14.4px;
    color: #fff;
    max-width: 180px;
    padding: 0 10px;
`;

interface UserMenuArrowProps {
    $rotationDegrees: number;
}

export const UserMenuArrow = styled(FaAngleUp)<UserMenuArrowProps>`
    transform: rotate(${({ $rotationDegrees }) => $rotationDegrees}deg);
    transition: .4s;
`;
