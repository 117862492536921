/* eslint-disable object-property-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setValidationError } from '../redux/createCampaignSlice';
import { creativitiesByFormatRules, rules } from './validation.js';

const useCreateValidation = () => {
  const dispatch = useDispatch();
  const {
    active, minBid, multicountryAllowed, decimals, userHasLimitation,
    userBalance, activeDailyBudget, values,
  } = useSelector(({
    createCampaignSlice: {
      creativities,
      validationErrors,
      createConfig,
      campaign,
      bidAndBudget, targeting,
    },
  }) => {
    const { active } = campaign || {};
    const {
      minBid, userLimitBudget: userHasLimitation, userBalance,
      userHasFourDecimalBid, activeDailyBudget, addFundsUrl,
    } = bidAndBudget || {};
    const { multicountryAllowed } = targeting || {};

    const decimals = (userHasFourDecimalBid || createConfig?.bidAndBudget?.bid?.decimals === 4)
      ? 4 : 3;

    const values = {
      basicInfo: {
        name: campaign?.name,
        url: campaign?.url,
        category_id: campaign?.category_id,
      },
      formats: {
        formats: campaign?.targeting?.formats,
      },
      creativities: {
        creativities: creativities?.list !== '' ? creativities?.list?.map((c) => ({ ...c, status: creativities.status[c.id] || null })) : [],

      },
      targeting: {
        countries: campaign?.targeting?.countries,
      },
      bidAndBudget: {
        bid: campaign?.bid,
        daily_budget: campaign?.daily_budget,
      },
    };

    return {
      active, minBid, multicountryAllowed, decimals, userHasLimitation,
      userBalance, activeDailyBudget, values, validationErrors, addFundsUrl,
    };
  });

  const validateField = (section, field, value = values[section][field]) => {
    let rule = rules[field];
    const availableBudget = userBalance - activeDailyBudget;

    const { creativities } = values.creativities;

    let runningCreativities = creativities
      .filter((c) => c.__isNew__ || c.status.includes('run'));

    if (runningCreativities.length === 0) {
      runningCreativities = creativities
        .filter((c) => c.status.includes('moderation') || c.status.includes('rejected'));
    }

    const isPop = values.formats?.formats?.some((f) => f.includes('pop'));

    switch (field) {
      case 'countries':
        if (active && !multicountryAllowed) {
          rule = rule.max(1);
        }
        break;
      case 'bid':
        rule = rule.min(minBid);
        rule = rule.precision(decimals);
        break;
      case 'daily_budget':
        rule = rule.min(20);
        if (userHasLimitation && active) {
          rule = rule.max(parseFloat(availableBudget))
            .messages({
              'number.max': `With the available funds of ($${availableBudget.toFixed(2)}) you do not have enough to set the daily budget to $${value}.`,
            });
        }
        break;
      case 'creativities':
        if (!isPop) {
          rule = creativitiesByFormatRules.push;
        } else {
          return;
        }

        if (!runningCreativities?.length) {
          value = runningCreativities;
        }
        break;
      default:
    }

    let { error } = rule.validate(value, { abortEarly: false, convert: false });

    if (error && field === 'creativities') {
      // console.log(error)
      // console.log('details', error.details)
      // console.log(error._original)
      error = {
        message: error.details.map((d) => {
          if (d.type.includes('array')) {
            return { id: 'all', error: d.message };
          } if (d.context?.key) {
            return {
              id: error._original?.[d.path[0]]?.id,
              [d.context.key]: d.message,
            };
          }
          return d.message;
        }),
      };
      // console.log(error.message)

      error.message = error.message.reduce((old, e) => {
        if (old[e.id]) {
          return { ...old, [e.id]: { ...old[e.id], ...e } };
        }
        return { ...old, [e.id]: e };
      }, {});
    }
    // console.log(error)
    dispatch(setValidationError({ section, field, error: error?.message || null }));

    return error?.message;
  };

  const validateFields = () => {
    toast.dismiss();
    const sections = Object.keys(values);
    const validated = sections
      .reduce((hasError, section) => {
        const fields = Object.keys(values[section]);
        const errors = fields
          .reduce((fieldHasErrors, field) => {
            const fieldError = validateField(section, field, values[section][field]);
            if (fieldError) {
              if (field !== 'creativities') {
                toast.error(<div dangerouslySetInnerHTML={{ __html: fieldError.toString() }} />);
              } else {
                toast.error('"Creativities" error');
              }
            }
            return fieldHasErrors && !fieldError;
          }, true);

        return hasError && errors;
      }, true);

    return validated;
  };
  /*
    useEffect(() => {
      if (values.basicInfo.name !== undefined) {
        validateField('basicInfo', 'name')
      }
    }, [values.basicInfo.name])

    useEffect(() => {
      if (values.basicInfo.url !== undefined) {
        validateField('basicInfo', 'url')
      }
    }, [values.basicInfo.url])

    useEffect(() => {
      if (values.basicInfo.category_id !== undefined) {
        validateField('basicInfo', 'category_id')
      }
    }, [values.basicInfo.category_id]) */

  useEffect(() => {
    if (values.formats.formats !== undefined) {
      validateField('formats', 'formats');
    }
  }, [values.formats.formats]);

  /*
  useEffect(() => {
    if (values.targeting.countries !== undefined) {
      validateField('targeting', 'countries')
    }
  }, [values.targeting.countries])

  useEffect(() => {
    if (values.bidAndBudget.bid !== undefined) {
      validateField('bidAndBudget', 'bid')
    }
  }, [values.bidAndBudget.bid])

  useEffect(() => {
    if (values.bidAndBudget.daily_budget !== undefined) {
      validateField('bidAndBudget', 'daily_budget')
    }
  }, [values.bidAndBudget.daily_budget]) */

  return { validateFields, validateField };
};

export default useCreateValidation;
