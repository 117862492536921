import styled from 'styled-components/macro';

export const FlexContainer = styled.div`
    display: flex;
`;

export const CenteredFlexContainer = styled(FlexContainer)`
    justify-content: center;
    align-items: center;
`;

export const WrappedFlexContainer = styled(FlexContainer)`
    flex-wrap: wrap;
`;
