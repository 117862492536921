import styled from 'styled-components/macro';

export const StyledAside = styled.aside`
    display: none;
    
    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(45deg, rgb(231, 229, 230) 0%, rgb(255, 255, 255) 100%);
        width: 50vw;
        height: 100vh;
    }
`;

export const TipImage = styled.img`
    max-width: 80%;
    max-height: 70%;
`;

export const TipTitle = styled.h4`
    font-size: 28px;
    font-weight: 500;
    margin-top: -30px;
    margin-bottom: 5px;
`;

export const TipText = styled.span`
    font-size: 20px;
    line-height: 1.2;
    max-width: 75%;
    text-align: center;
`;
